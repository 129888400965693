import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import {
    Button,
    Container,
    Divider,
    Typography,
    TextField,
} from "@mui/material";
import {
    ArrowBack as ArrowBackIcon
} from "@mui/icons-material";

import CustomTableHead from "../../Shared/CustomTableHead";
import LoadingTableSkeleton from "../../Shared/LoadingTableSkeleton";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import { useUserContext } from "../../../context/user/UserContext";
import { getComparator } from "../../../Utils/TableUtils";

import "./OnboardingCenter.scss";
import NotesDialog from "./NotesDialog";
import Snack from "../../Shared/Snack";

const onboardingTableHeaders = [
    {
        field: "date",
        name: "Date Signed",
        fieldType: "date",
        cellClassName: "date-signed-onboarding-center-header"
    },
    {
        field: "company",
        name: "Company",
        fieldType: "string",
        cellClassName: "company-onboarding-center-header"
    },
    {
        field: "name",
        name: "Name",
        fieldType: "string",
    },
    {
        field: "email",
        name: "Email",
        fieldType: "string",
    },
    {
        field: "phone",
        name: "Phone",
        fieldType: "string",
    },
    {
        field: "organisationName",
        name: "Organisation",
        fieldType: "string",
    },
    {
        field: "",
        name: "Actions",
        fieldType: "",
    }
];

const OnboardingCenter = () => {
    const history = useHistory();
    const { id } = useParams();
    const {  email: user } = useUserContext();

    const [clients, setClients] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState("desc");
    const [showHidden, setShowHidden] = useState(false);
    const [orderBy, setOrderBy] = useState({
        field: "date",
        name: "Date Signed",
        fieldType: "date",
    });
    const [openCompletedConfirmation, setOpenCompletedConfirmation] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [showNotesDialog, setShowNotesDialog] = useState(false);

    const [openSnack, setopenSnack] = useState(false);
    const [snackSeverity, setsnackSeverity] = useState("info");
    const [snackText, setsnackText] = useState(false);

    const handleOnRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const getOnboardingPeopleData = (showHidden) => {
        setIsLoading(true);
        axios.get(`Onboarding/GetOnboardingPeopleData/${showHidden}`)
            .then(res => {
                setClients(res.data);
            }).catch(e => {

            }).finally(() => setIsLoading(false))
    }

    const handleMarkAsSubmitted = () => {
        setIsLoading(true);
        axios.post(`Onboarding/MarkPersonAsOnboarded/${selectedPerson.id}`)
            .then(res => {
                if (res) {
                    var copyOfClients = [...clients];
                    for (var i = 0; i < copyOfClients.length; i++) {
                        if (copyOfClients[i].id == selectedPerson.id) {
                            copyOfClients[i].isOnboardingRequired = false;
                        }
                    }
                    setClients(copyOfClients);
                    setsnackText("Marked as completed");
                    setsnackSeverity("success");
                    setopenSnack(true);
                    setShowNotesDialog(false);
                }
            }).catch(e => {
                setsnackText("Could not mark person as onbaorded");
                setsnackSeverity("error");
                setopenSnack(true);
                setSelectedPerson(null);
                setShowNotesDialog(false);
            }).finally(() => {
                setSelectedPerson(null)
                setIsLoading(false)
            })
    }

    const toggleHidden = () => {
        getOnboardingPeopleData(!showHidden);
        setShowHidden(!showHidden);
    };

    const handleSaveNote = (note) => {
        axios.post(`Onboarding/SaveNote`, {
            note: note,
            personId: selectedPerson.id,
            user: user
        }).then(res => {
            setsnackText("Note Saved");
            setsnackSeverity("success");
            setopenSnack(true);
            setShowNotesDialog(false);
        }).catch(e => {
            setsnackText("Could not save Note");
            setsnackSeverity("error");
            setopenSnack(true);
            setSelectedPerson(null);
            setShowNotesDialog(false);
        })
    }

    useEffect(() => {
        getOnboardingPeopleData(false);
    }, [])

    useEffect(() => {
        if (dayjs) {
            dayjs.extend(dayjsPluginUTC);
        }
    }, []);

    useEffect(() => {
        if (id) {
            axios.post(`Onboarding/MarkPersonAsOnboarded/${id}`)
                .then(res => {
                    if (res) {
                        var copyOfClients = [...clients];
                        for (var i = 0; i < copyOfClients.length; i++) {
                            if (copyOfClients[i].id == selectedPerson.id) {
                                copyOfClients[i].isOnboardingRequired = false;
                            }
                        }
                        setClients(copyOfClients);
                        setsnackText("Marked as completed");
                        setsnackSeverity("success");
                        setopenSnack(true);
                        setShowNotesDialog(false);
                    }
                }).catch(e => {
                    setsnackText("Could not mark person as onbaorded");
                    setsnackSeverity("error");
                    setopenSnack(true);
                    setSelectedPerson(null);
                    setShowNotesDialog(false);
                }).finally(() => {
                    setSelectedPerson(null)
                    setIsLoading(false)
                })
        }
    }, []);

    return (
        <Container maxWidth="xl">
            <Snack
                open={openSnack}
                setOpen={setopenSnack}
                severity={snackSeverity}
                text={snackText}
            />
            {showNotesDialog && (
                <NotesDialog
                    open={showNotesDialog}
                    handleClose={() => {
                        setSelectedPerson(null);
                        setShowNotesDialog(false);
                    }}
                    selectedPerson={selectedPerson}
                    user={user}
                    handleSave={handleSaveNote}
                />
            )}
            {openCompletedConfirmation && (
                <ConfirmationDialog
                    open={openCompletedConfirmation}
                    handleClose={() => {
                        setOpenCompletedConfirmation(false)
                        setSelectedPerson(null)
                    }}
                    submitLabel={"Confirm"}
                    title={"Please confirm to proceed"}
                    description={
                        `This will mark the onboarding process for ${selectedPerson.name} as completed. Are you sure?`
                    }
                    handleSubmit={() => {
                        handleMarkAsSubmitted();
                        setOpenCompletedConfirmation(false)
                    }}
                />
            )}
            <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: "15px" }}>
                <div
                    style={{ display: "grid", gridTemplateColumns: "1fr", gap: "5px" }}
                >
                    <Typography variant="h4" align="left">
                        Onboarding Center
                    </Typography>
                    <Divider />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        className="default-button-outlined"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => history.push({ pathname: "/home" })}
                    >
                        Go Back
                    </Button>
                    <Button
                        className="default-button-outlined"
                        onClick={toggleHidden}
                    >
                        {showHidden ? "Hide Onboarded" : "Show Onboarded"}
                    </Button>
                </div>
                <div className="table-responsive" style={{ maxHeight: "65vh" }}>
                    <table className="table table-striped table-hover mb-0">
                        <CustomTableHead
                            headers={onboardingTableHeaders}
                            orderBy={orderBy}
                            order={order}
                            onRequestSort={handleOnRequestSort}
                        />
                        <tbody>
                            {isLoading ? (
                                <LoadingTableSkeleton
                                    loadingMessage="Loading contacts..."
                                    colspan="6"
                                />
                            ) : (
                                [...clients].sort(getComparator(order, orderBy)).map((p) => (
                                    <tr
                                        key={p.id}
                                        className={"padding-4px"}
                                    >
                                        <td className="text-center padding-4px">
                                            {p.dateSigned
                                                ? dayjs(p.dateSigned).format("DD/MM/YYYY")
                                                : " - "}
                                        </td>
                                        <td className="text-center padding-4px">{p.company}</td>
                                        <td className="text-center padding-4px">{p.name}</td>
                                        <td className="text-center padding-4px">{p.email}</td>
                                        <td className="text-center padding-4px">{p.phone}</td>
                                        <td className="text-center padding-4px">{p.organisationName}</td>
                                        <td className="text-center padding-4px">
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <Button size="small" className="default-button action-button"
                                                    onClick={() => {
                                                        setSelectedPerson(p);
                                                        setShowNotesDialog(true);
                                                    }}>
                                                    Notes
                                                </Button>
                                                {p.isOnboardingRequired && (
                                                    <div className="onboarding-center-action-button-container">
                                                        <Button size="small" className="default-button action-button"
                                                            onClick={() => {
                                                                setSelectedPerson(p);
                                                                setOpenCompletedConfirmation(true);
                                                            }}>
                                                            Mark as onboarded
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {})(OnboardingCenter);

import { createAction } from "@reduxjs/toolkit";
import axios from "axios";

export const getExpenseCodes = (organisationId) => async (dispatch) => {
    try {
      const res = await axios.get(`ExpenseCode/GetCodes/${organisationId}`);
      dispatch(getExpenseCodesSuccess(res.data));
    } catch (error) {
      console.log(error);
    }
  };

export const getExpenseCodesSuccess = createAction("GET_EXPENSE_CODES");
import { Typography } from "@mui/material";
import React from "react";
import { currencySymbolFormat } from "../../Utils/GeneralUtils";

const InternalQuoteNote = ({ crossOrganisationProject, currency }) => {
  return (
    <div>
      {!!currency &&
        !!crossOrganisationProject &&
        !!crossOrganisationProject.totalToSpend && (
          <>
            <Typography style={{ fontSize: 16 }}>
              <strong>
                NOTE: This is an internal statement of work. Allocate the entire
                <span style={{ color: "red" }}>{` ${currencySymbolFormat(
                  crossOrganisationProject.totalToSpend,
                  currency
                )} `}</span>
                and manually approve at the bottom. This action will generate the job in ProdCore/Hub.
              </strong>
            </Typography>
            <Typography style={{ fontSize: 16 }}>
              <strong>
                Ensure to triple-check your budget allocations as they{" "}
                cannot be modified after approval. Once approved, this job will automatically
                generate invoices.
              </strong>
            </Typography>
          </>
        )}
    </div>
  );
};

export default InternalQuoteNote;

import * as React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./admin.scss";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useAuth } from "../../context/auth/AuthContext";

const options = [
  { label: "iPad", page: "ipad" },
  { label: "Defaults", page: "defaults" },
  { label: "Products", page: "products" },
  { label: "Emails", page: "emails" },
  { label: "Company", page: "company" },
  //{ label: "Upload Videos", page: "upload" }
];

const Admin = (props) => {
  const history = useHistory();
  const { user } = useAuth();
  const card = (option) => (
    <Card
      key={`${option.label}-card`}
      className="admin-cards"
      component={Link}
      to={`admin/${option.page}`}
      raised={true}
      style={{ margin: 10 }}
    >
      <CardContent>
        <Typography className="admin-cards-text">{option.label}</Typography>
      </CardContent>
    </Card>
  );

  if (user.role !== "Admin") {
    history.push("/");
  }

  return (
    <>
      <div style={{ display: "flex" }}>{options.map((o) => card(o))}</div>
      <Typography style={{ fontSize: 8 }}>
        {process.env.REACT_APP_ADMIN_PANEL}
      </Typography>
    </>
  );
};

//export default Admin;

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Admin);

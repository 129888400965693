import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment } from "react";
import { currencySymbolFormat, truncateDecimals } from "../../Utils/GeneralUtils";

const SplitInvoicePreview = ({ title, invoice, handleOnChange, loading, currency }) => {
  const buildInputTotal = () => {
    return (
      <TextField
        disabled={loading}
        label="Amount"
        type="number"
        value={truncateDecimals(invoice?.exchangedTotal, 2)}
        size="small"
        onChange={(e) => {
          handleOnChange(
            "exchangedTotal",
            e.target.valueAsNumber ? e.target.valueAsNumber : 0
          );
        }}
      />
    );
  };

  const checkIfInvalidDates = (value) => {
    if (!value) {
      return true;
    } else if (
      invoice?.date &&
      invoice?.dueDate &&
      dayjs(invoice?.dueDate).isBefore(invoice?.date)
    ) {
      return true;
    }
    return false;
  };
  return (
    <Fragment>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <div>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2">{invoice?.company?.name}</Typography>
            <Typography variant="body2">{`${invoice?.contact?.firstName} ${invoice.contact.lastName}`}</Typography>
            <Typography variant="body2">{`${invoice?.contact?.emailAddress}`}</Typography>
          </div>
        </Grid>
        <Grid item>
          <div>
            <div className="split-invoice-input-div">
              <TextField
                disabled={loading}
                label="Invoice Date"
                type="date"
                size="small"
                variant="filled"
                onChange={(e) => {
                  handleOnChange("date", e.target.value);
                }}
                value={dayjs(invoice?.date).format("YYYY-MM-DD")}
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                error={checkIfInvalidDates(invoice?.date)}
              />
            </div>
            <div className="split-invoice-input-div">
              <TextField
                disabled={loading}
                label="Due Date"
                type="date"
                value={dayjs(invoice.dueDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  handleOnChange("dueDate", e.target.value);
                }}
                variant="filled"
                fullWidth={true}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                error={checkIfInvalidDates(invoice?.dueDate)}
              />
            </div>
            <div className="split-invoice-input-div">
              <TextField
                disabled={loading}
                label="PO Number"
                variant="filled"
                onChange={(e) => {
                  handleOnChange("poNumber", e.target.value);
                }}
                fullWidth={true}
                value={invoice?.poNumber}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ marginTop: 15 }}
      >
        <Grid item xs={12}>
          <div className="table-responsive">
            <table
              className="table table-striped table-hover mb-0"
              style={{ tableLayout: "fixed", width: "100%" }}
            >
              <thead className="thead-magnesium">
                <tr>
                  <th className="text-center" style={{ width: "49%" }}>
                    Description
                  </th>
                  <th className="text-center">Quantity</th>
                  <th className="text-center">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className="text-center"
                    style={{ wordWrap: "break-word" }}
                  >{`Production of ${invoice?.jobName}`}</td>
                  <td className="text-center">1</td>
                  <td className="text-center">{buildInputTotal()}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <Typography variant="button">Total</Typography>
                  </td>
                  <td>
                    {currencySymbolFormat(invoice?.exchangedTotal, currency)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SplitInvoicePreview;

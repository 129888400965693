import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    CircularProgress,
    Select,
    MenuItem
} from "@mui/material";
import ProcessingSpinnerV2 from "./Spinner/ProcessingSpinnerV2";

const ConfirmationDialogWithSelect = ({
    open,
    handleClose,
    title,
    description,
    submitLabel,
    handleSubmit,
    defaultValue,
    menuItems,
    loading = false,
    duration,
    unitTime = "seconds"
}) => {
    const [selectValue, setSelectValue] = useState(defaultValue);

    return (
        <div>
            <Dialog
                open={open}
                onClose={(e, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose()
                    }
                }}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                    <Select
                        labelId="select-label"
                        id="select"
                        value={selectValue}
                        onChange={(e) => setSelectValue(e.target.value)}
                        fullWidth
                        disabled={loading}
                    >
                        {menuItems.map(menuItem => menuItem)}
                    </Select>
                </DialogContent>
                {loading ? (
                    <DialogActions style={{ padding: 10 }}>
                        <ProcessingSpinnerV2 duration={duration} unitTime={unitTime} />
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button disabled={loading} className="default-button" variant="contained" onClick={handleClose}>Cancel</Button>
                        <Button
                            className="default-button"
                            variant="contained"
                            onClick={() => handleSubmit(selectValue)}
                            autoFocus>
                            {submitLabel}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
};

export default ConfirmationDialogWithSelect;

import { Button, IconButton } from "@mui/material";
import React, { Fragment, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ViewHtmlModal from "../../Shared/ViewHtmlModal";
import EditEmailDialog from "./EditEmailDialog";
import axios from "axios";

const EmailsTable = ({ emails, handleEditedEmail, organisationId }) => {
  const [openViewHtmlModal, setOpenViewHtmlModal] = useState(false);
  const [openEditHtmlModal, setOpenEditHtmlModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);

  const handleEditEmail = () => {
    if (selectedEmail) {
      axios
        .put(`HtmlTemplate/UpdateHtmlTemplate/${organisationId}`, selectedEmail)
        .then(({ data }) => {
            data.htmlTemplateAudit = null;
            setOpenEditHtmlModal(false)
            handleEditedEmail(data);
        })
        .catch((e) => {
          console.log(e);
          handleEditedEmail();
        });
    }
  };
  return (
    <Fragment>
      {openViewHtmlModal && (
        <ViewHtmlModal
          isOpen={openViewHtmlModal}
          handleClose={() => {
            setOpenViewHtmlModal(false);
            setSelectedEmail(null);
          }}
          html={selectedEmail.template}
        />
      )}
      {openEditHtmlModal && (
        <EditEmailDialog
          open={openEditHtmlModal}
          handleClose={() => {
            setOpenEditHtmlModal(false);
            setSelectedEmail(null);
          }}
          selectedEmail={selectedEmail}
          setSelectedEmail={setSelectedEmail}
          handleEditEmail={handleEditEmail}
        />
      )}
      <div className="table-responsive">
        <table className="table table-striped table-hover mb-0">
          <thead className="thead-magnesium">
            <tr>
              <th className="text-center" style={{ width: "50%" }}>
                Name
              </th>
              <th className="text-center">View Email</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {emails.map((email) => (
              <tr key={email.id}>
                <td className="text-center no-padding">{email.name}</td>
                <td className="text-center padding-4px">
                  <Button
                    className="default-button"
                    variant="contained"
                    onClick={(e) => {
                      setSelectedEmail(email);
                      setOpenViewHtmlModal(true);
                    }}
                  >
                    View Email
                  </Button>
                </td>
                <td className="text-center no-padding">
                  <IconButton
                    className="default-icon"
                    onClick={(e) => {
                      setSelectedEmail(email);
                      setOpenEditHtmlModal(true);
                    }}
                  >
                    <EditIcon style={{ color: "black" }} />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default EmailsTable;

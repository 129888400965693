import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";

const SplitInvoiceWizardStep2 = ({
  setIsStep2Completed,
  setFinalInvoiceDate,
  handleCompleteWizard,
}) => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  return (
    <div style={{ textAlign: "center" }}>
      <h3 className="invoice-step-wizard">
        <span className="question-animation">Select the month </span>
        <span className="question-animation">for the final </span>
        <span className="question-animation">payment? </span>
      </h3>
      <div className="flex justify-content-center question-animation">
        <div>
          <FormControl fullWidth style={{ width: 133 }}>
            <InputLabel shrink={true}>Final Invoice Month</InputLabel>
            <Select
              size="small"
              value={selectedMonth}
              label="Final Invoice Month"
              onChange={(e) => {
                setFinalInvoiceDate(
                  dayjs()
                    .month(e.target.value)
                    .endOf("month")
                    .format("YYYY-MM-DD")
                );
                setSelectedMonth(e.target.value);
              }}
              input={<OutlinedInput notched label="Final Invoice Month" />}
            >
              <MenuItem value={0}>January</MenuItem>
              <MenuItem value={1}>February</MenuItem>
              <MenuItem value={2}>March</MenuItem>
              <MenuItem value={3}>April</MenuItem>
              <MenuItem value={4}>May</MenuItem>
              <MenuItem value={5}>June</MenuItem>
              <MenuItem value={6}>July</MenuItem>
              <MenuItem value={7}>August</MenuItem>
              <MenuItem value={8}>September</MenuItem>
              <MenuItem value={9}>October</MenuItem>
              <MenuItem value={10}>November</MenuItem>
              <MenuItem value={11}>December</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="question-animation" style={{ marginTop: 15 }}>
        <Button
          variant="contained"
          className={selectedMonth == null ? "" : "default-button"}
          disabled={selectedMonth == null}
          onClick={async (e) => {
            setIsStep2Completed(true);
            handleCompleteWizard();
          }}
        >
          Check and Review
        </Button>
      </div>
    </div>
  );
};

export default SplitInvoiceWizardStep2;

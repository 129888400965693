import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";

const ViewHtmlModal = (props) => {
    const {
        isOpen,
        handleClose,
        html
    } = props;

    const [componentLoaded, setComponentLoaded] = useState(false);

    useEffect(() => {
        if (componentLoaded) {
            document.getElementById("view-html").innerHTML = html;
        }
    }, [componentLoaded]);

    useEffect(() => {
        setComponentLoaded(true);
    }, []);

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={() => handleClose()}
                className={"view-html-modal"}
            >
                <div className={`view-html-modal-paper`}>
                    <div id="view-html" className="paragraph-default"></div>
                </div>
            </Modal>
        </div>
    );
}

export default ViewHtmlModal;

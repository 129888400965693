import { Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const JobStatusDisplay = ({ job }) => {
  useEffect(() => {
    if (dayjs) {
      dayjs.extend(dayjsPluginUTC);
    }
  }, []);
  return (
    <Fragment>
      <HtmlTooltip
        title={
          <React.Fragment>
            <div><b>{"Sent On: "}</b>{" "}{job && job.sent ? dayjs.utc(job.sent).local().format("DD/MM/YYYY h:mma") : ""}</div>
            <div><b>{"Viewed On: "}</b>{" "}{job && job.viewed ? dayjs.utc(job.viewed).local().format("DD/MM/YYYY h:mma") : ""}</div>
            <div><b>{"Approved On: "}</b>{" "}{job && job.approved ? dayjs.utc(job.approved).local().format("DD/MM/YYYY h:mma") : ""}</div>
            {/* {job && !job.approved && (  
              <React.Fragment>
                {job.reminderHistory.map(r => {
                  return (
                    <div><b>{"Reminder Sent On: "}</b>{dayjs.utc(r.time).local().format("DD/MM/YYYY h:mma")}</div>
                  )
                })}
              </React.Fragment>
            )} */}
          </React.Fragment>
        }
      >
        <span>
          {job.status?.toUpperCase()}
        </span>
      </HtmlTooltip>
    </Fragment>
  )
}

export default JobStatusDisplay
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Close from "@mui/icons-material/Close";
import {
  getJobs,
  updateAllJobLinesStore,
  updateJobStore,
  updateAllJobCategoriesStore,
} from "../../reduxActions/job";
import { v1 as uuidv1 } from "uuid";
import _ from "lodash";
import JobAutocomplete from "../Shared/JobAutocomplete";

const DuplicateJobLinesDialog = ({
  open,
  handleClose,
  job,
  jobs,
  jobsFilter,
  getJobs,
  updateAllJobLinesStore,
  updateAllJobCategoriesStore,
  updateJobStore,
  refreshJobDescriptionEditor,
  jobOrganisation,
  setHasChanges,
}) => {
  const [selectedJob, setSelectedJob] = useState({});

  useEffect(() => {
    if (!jobs || jobs.length === 0) {
      getJobs(jobsFilter);
    }
  }, [jobs]);

  /*
    Copies jobLines and jobCategories. 
    Changes id for both joblines and jobCategory. 
    New jobCategory id is applied to job lines
  */
  const copyJobLines = async () => {
    if (selectedJob) {
      //&& selectedJob.shootDays <= job.shootDays
      if (selectedJob.jobLines?.length > 0) {
        let jobClone = _.cloneDeep(selectedJob);
        jobClone.jobLines.forEach((line) => {
          const newGuid = uuidv1();
          line.id = 0;
          line.guid = newGuid;
          line.jobId = job.id ? job.id : 0;
          // Remove margin and sell price calculation logic for Media Spend
          if(line.name?.toLowerCase().includes("media spend")){
            line.minSellPrice = 0;
            line.costPrice = 0;
            line.margin = 0;
            line.sellPrice = line.total;
          }
        });
        var jobCategoryGuidList = [];
        for (var i = 0; i < jobClone.jobLines.length; i++) {
          jobCategoryGuidList.push(jobClone.jobLines[i].jobCategoryGuid);
        }
        const uniqueJobCategoryGuidList = [...new Set(jobCategoryGuidList)];
        var guidConverter = {};
        uniqueJobCategoryGuidList.forEach((guid) => {
          guidConverter[`${guid}`] = uuidv1();
        });
        jobClone.jobCategories.forEach((line) => {
          line.id = 0;
          line.guid = guidConverter[`${line.guid}`];
          line.jobId = job.id ? job.id : 0;
        });
        jobClone.jobLines.forEach((line) => {
          line.jobCategoryGuid = guidConverter[`${line.jobCategoryGuid}`];
        });

        var finalJobCategories = [
          ...job.jobCategories,
          ...jobClone.jobCategories,
        ];
        var finalJobLines = [...job.jobLines, ...jobClone.jobLines];
        //Look for a jobCategory with Name = "Media Implemenation"
        //  Grab the guid
        //Find all jobLines with that guid && has name "Media Spend"
        //  If found create new block "Media Spend" with the found jobLines using the new guid
        var mediaImplementationJobCategories = [];
        finalJobCategories.forEach((cat) => {
          if (cat.type == "Media Implementation") {
            mediaImplementationJobCategories.push(cat);
          }
        });
        var mediaSpendJobLines = [];
        finalJobLines.forEach((line) => {
          var res = mediaImplementationJobCategories.filter(
            (e) => e.guid === line.jobCategoryGuid
          );
          if (line.name == "Media Spend" && res.length > 0) {
            mediaSpendJobLines.push(line);
          }
        });

        if (mediaSpendJobLines.length > 0) {
          let lastOrder = [...finalJobCategories]
            .sort((a, b) => a.order - b.order)
            .map((j) => j.order);
          if (lastOrder && lastOrder.length > 0) {
            lastOrder = Number(lastOrder[lastOrder.length - 1]) + 2;
          } else {
            lastOrder = 1;
          }
          var newjobCatGuid = uuidv1();
          finalJobCategories.push({
            order: lastOrder,
            name: "Media Spend",
            guid: newjobCatGuid,
            type: "Media Spend",
          });

          mediaSpendJobLines.forEach((jl) => {
            jl.jobCategoryGuid = newjobCatGuid;
          });
        }

        await updateJobStore("jobDescription", selectedJob.jobDescription);
        refreshJobDescriptionEditor(selectedJob.jobDescription);
        await updateAllJobLinesStore(finalJobLines);
        await updateAllJobCategoriesStore(finalJobCategories);
        setHasChanges(true);
        handleClose();
      } else {
        alert("This Quote has no lines!");
      }
    }
    // else {
    //   alert("Number of shoot days is different from this job!");
    // }
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "#ededed",
          },
        }}
      >
        <DialogTitle id="quote-preview-dialog-title">
          {`Select the job to proceed`}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ padding: 15 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <JobAutocomplete
                  handleOnChange={setSelectedJob}
                  organisationId={jobOrganisation}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex" }}>
            <div style={{ paddingRight: 15 }}>
              <Button
                variant="contained"
                className="default-button"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
            <div style={{ paddingRight: 15 }}>
              <Button
                variant="contained"
                className={!selectedJob ? "" : "default-button"}
                onClick={copyJobLines}
                disabled={!selectedJob}
              >
                Copy From this Job
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  jobs: state.job.jobs,
  jobsFilter: state.job.jobsFilter,
});

export default connect(mapStateToProps, {
  getJobs,
  updateAllJobLinesStore,
  updateAllJobCategoriesStore,
  updateJobStore,
})(DuplicateJobLinesDialog);

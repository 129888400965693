import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import Close from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { updateJobStore } from "../../reduxActions/job";
import { useEffect } from "react";
const RetainerBillingDialog = ({
  open,
  handleClose,
  handleSubmit,
  updateJobStore,
  setHasChanges,
  job,
  type
}) => {
  const [retainerType, setRetainerType] = useState(job.retainerType ? job.retainerType : null);
  const [shouldSubmitChanges, setShouldSubmitChanges] = useState(false);

  useEffect(() => {
    if(shouldSubmitChanges){
      handleClose();
      if(type === "create"){
        handleSubmit();
      }
    }
  }, [shouldSubmitChanges])
  

  const handleChangeJobRetainerType = async () => {
    await setHasChanges(true)
    await updateJobStore("retainerType", retainerType);
    setShouldSubmitChanges(true)
  };
  return (
    <div>
      <Dialog open={open} fullWidth>
        <DialogTitle
          id="form-dialog-title-company"
          className="sales-dialog-title"
        >
          Please select the preferred method for the client to sign off.
        </DialogTitle>
        <DialogContent dividers>
          <FormControl sx={{ m: 3 }} variant="standard">
            <FormLabel>Display as:</FormLabel>
            <RadioGroup
              value={retainerType}
              onChange={(e) => setRetainerType(e.target.value)}
            >
              <FormControlLabel
                value="MONTHLY"
                control={<Radio />}
                label="Per Month"
              />
              <FormControlLabel
                value="QUARTERLY"
                control={<Radio />}
                label="Per Quarter"
              />
              <FormControlLabel
                value="YEARLY"
                control={<Radio />}
                label="Per Year"
              />
              <FormControlLabel
                value="TOTAL"
                control={<Radio />}
                label="Total Amount"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            className="default-button"
          >
            Cancel
          </Button>
          <Button
            onClick={handleChangeJobRetainerType}
            variant="contained"
            className="default-button"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  updateJobStore,
})(RetainerBillingDialog);

import { Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";

const ForeignCurrencyNote = ({ currency }) => {
  return (
    <div>
      {(currency && currency !== "NZD") && (
        <>
          <Typography style={{ fontSize: 16 }}>
            <strong>
              NOTE: This client is configured to be quoted and invoiced in{" "}
              <span style={{color: "red"}}>{currency}</span>.
            </strong>
          </Typography>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ForeignCurrencyNote);

import React, { useState, useEffect } from "react";
import MarginExemptionTable from "./MarginExemptionTable";
import {
    Button,
    CircularProgress,
    Grid,
    Typography
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import MarginExemptionNewPopup from "./MarginExemptionNewPopup";
import InfoDialog from "../../../Shared/InfoDialog";

const MarginExemptionHomePage = (props) => {
    const { id, type } = useParams();

    const [showNewExemptionPopup, setShowNewExemptionPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [infoDialogProps, setInfoDialogProps] = useState({
        title: "",
        context: ""
    })
    const [showInfoDialog, setShowInfoDialog] = useState(false);

    useEffect(() => {
        if (id && type) {
            axios.patch(`MarginExemption/ApproveExemption/${id}/${type}`)
                .then(res => {
                    setInfoDialogProps({
                        title: "Success!",
                        context: "The exemption has been approved."
                    })
                    setShowInfoDialog(true);
                })
                .catch(e => {
                    setInfoDialogProps({
                        title: "Error!",
                        context: "Something went wrong. Please use the table to manually approve the exemption"
                    })
                    setShowInfoDialog(true);
                })
                .finally(() => {

                })
        }
    }, []);

    useState(() => {
        if (loading) {
            setLoading(false);
        }
    }, [loading])

    return (
        <div style={{ margin: "0px 20%" }}>
            {showInfoDialog && (
                <InfoDialog
                    open={showInfoDialog}
                    handleClose={() => {
                        setShowInfoDialog(false)
                        setInfoDialogProps({
                            title: "",
                            context: ""
                        })
                    }}
                    title={infoDialogProps.title}
                    context={infoDialogProps.context}
                />
            )}
            {showNewExemptionPopup && (
                <MarginExemptionNewPopup
                    open={showNewExemptionPopup}
                    handleClose={() => setShowNewExemptionPopup(false)}
                    handleCloseAndRefresh={() => {
                        setLoading(true);
                        setShowNewExemptionPopup(false);
                    }}
                    setLoading={setLoading}
                />
            )}
            <Grid container style={{ padding: "15px 0px" }}>
                <Grid item xs={6} md={6} lg={6}>
                    <Typography
                        variant="h5"
                        gutterBottom
                        component="div"
                        alignSelf={"center"}
                    >
                        Exemptions
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            className="default-button"
                            variant="contained"
                            onClick={() => setShowNewExemptionPopup(true)}
                        >
                            Add Exemption
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {loading ? <CircularProgress /> : <MarginExemptionTable />}
                </Grid>
            </Grid>
        </div>
    )
}

export default MarginExemptionHomePage;
import React, { useEffect, useState } from "react";
import { getCurrentStep } from "../../Utils/StepsUtils";
import "./steps.scss";

const Steps = (props) => {
  const { status } = props;
  const [steps, setSteps] = useState();

  useEffect(() => {
    if (status) {
      setSteps(getCurrentStep(status));
    }
  }, [status]);

  return steps ? (
    <div id="steps-container">
      {steps.map((step, index) => (
        <div key={step.name} id="steps-div">
          {index > 0 && (
            <div
              className={
                step.completed ? "step-left-completed" : "step-left-pending"
              }
            ></div>
          )}
          {index === 0 && (
            <div
              className={
                step.completed
                  ? "step-center-first step-completed"
                  : "step-center-first step-pending"
              }
            >{`${index + 1} - ${step.name}`}</div>
          )}
          {index > 0 && (
            <div
              className={
                step.completed
                  ? "step-center step-completed"
                  : "step-center step-pending"
              }
            >{`${index + 1} - ${step.name}`}</div>
          )}
          <div
            className={
              step.completed ? "step-right-completed" : "step-right-pending"
            }
          ></div>
        </div>
      ))}
    </div>
  ) : (
    <div></div>
  );
};

export default Steps;

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CustomTableHead from "../../../../Shared/CustomTableHead";
import { getComparator } from "../../../../../Utils/TableUtils";
import { calculateJobTotal, calculateMarginPercentage, calculateTotalMargin } from "../../../../../Utils/JobHelpers";
import { useUserContext } from "../../../../../context/user/UserContext";
import { useHistory } from "react-router-dom";


dayjs.extend(dayjsPluginUTC);


const jobTableHeaders = [
  {
    field: "user",
    name: "User",
    fieldType: "string",
  },
  {
    field: "created",
    name: "Created",
    fieldType: "date",
  },
  {
    field: "companyName",
    name: "Company",
    fieldType: "string",
  },
  {
    field: "jobName",
    name: "Job Name",
    fieldType: "string",
  },
  {
    field: "revenueTotal",
    name: "Total",
    fieldType: "numeric",
  },
  {
    field: "marginTotal",
    name: "Margin",
    fieldType: "numeric",
  },
  {
    field: "marginPercentage",
    name: "Margin Percentage",
    fieldType: "numeric",
  },
];

const SalesMarginDataTable = ({
  salesMarginData,
}) => {
  const { allUsers } = useUserContext();
  
  const history = useHistory();
  const [orderBy, setOrderBy] = useState({
    field: "created",
    name: "Created",
    fieldType: "date",
  });
  const [order, setOrder] = useState("desc");

  useEffect(() => {
    if (dayjs) {
      dayjs.extend(dayjsPluginUTC);
    }
  }, []);

  const handleOnRequestSort = (event, property) => {
    console.log("handleOnRequestSort", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  console.log(salesMarginData.data);

  const tableData = salesMarginData.data.map((row) => ({
    id: row.id,
    user: row.user,
    created: row.created,
    companyName: row.companyName,
    jobName: row.jobName,
    revenueTotal: row.total ?? 0,
    marginTotal: row.marginTotal ?? 0,
    marginPercentage: (row.marginTotal ?? 0) / (row.total ?? 0)
  }))
  .sort(getComparator(order, orderBy));

  return (
    <div>
      <div className="table-responsive" style={{ maxHeight: "78vh" }}>
        <table className="table table-striped table-hover mb-0">
          <CustomTableHead
            headers={jobTableHeaders}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleOnRequestSort}
          />
          <tbody>
            {tableData
              .map((job) => (
                <tr key={job.id} className={"padding-4px"}>
                  <td className="text-center padding-4px">
                    {job.user}
                  </td>
                  <td className="text-center padding-4px">
                    {job.created
                      ? dayjs.utc(job.created).local().format("DD/MM/YYYY")
                      : " - "}
                  </td>
                  <td className="text-center padding-4px">{job.companyName}</td>
                  <td className="text-center padding-4px" style={{ maxWidth: 200 }}>
                    <span className="hyperlink" onClick={(e) => history.push(`/job/${job.id}`)}>
                      {`[${job.id}] `}{job.projectCode && `${job.projectCode} - `}{job.jobName}
                    </span>
                  </td>
                  <td className="text-center padding-4px">
                    {job.revenueTotal.toLocaleString("en-NZ", {
                      style: "currency",
                      currency: "NZD",
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-center padding-4px">
                    {job.marginTotal.toLocaleString("en-NZ", {
                      style: "currency",
                      currency: "NZD",
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-center padding-4px">
                    {job.marginPercentage.toLocaleString("en-NZ", {
                      style: "percent",
                      minimumFractionDigits: 1,
                    })}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {})(SalesMarginDataTable);

import { Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Snack from "../../Shared/Snack";
import EmailsTable from "./EmailsTable";
import { connect } from "react-redux";

const Emails = (props) => {
  const {
    selectedOrganisation
  } = props
  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("info");
  const [snackText, setSnackText] = useState(false);
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    if(selectedOrganisation){
      axios
        .get(`HtmlTemplate/GetEmailTemplates/${selectedOrganisation.id}`)
        .then(({ data }) => {
          setEmails(data);
        })
        .catch((e) => {
          console.log(e);
          setSnackText("Error fetching the emails");
          setSnackSeverity("error");
          setOpenSnack(true);
        });
    }
  }, [selectedOrganisation]);

  const handleEditedEmail = (editedEmail) => {
    if (editedEmail) {
      setEmails(emails.map((e) => (e.id === editedEmail.id ? editedEmail : e)));
      setSnackText("Email updated!");
      setSnackSeverity("info");
      setOpenSnack(true);
    } else {
      setSnackText("Something went wrong");
      setSnackSeverity("error");
      setOpenSnack(true);
    }
  };

  return (
    <div>
      <Snack
        open={openSnack}
        setOpen={setOpenSnack}
        severity={snackSeverity}
        text={snackText}
      />
      {emails && emails.length > 0 ? (
        <EmailsTable emails={emails} handleEditedEmail={handleEditedEmail} organisationId={selectedOrganisation?.id}/>
      ) : (
        <div className="text-center">
          <Typography variant="h4">No emails found</Typography>
        </div>
      )}
    </div>
  );
};

//export default Emails;
const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {})(Emails);

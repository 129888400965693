export const getCurrentStep = (status) => {
  const stepsFromDb = ["draft", "checked", "sent", "approved"];
  const steps = ["Draft", "Check", "Send", "Confirm"];

  const statusIndex = stepsFromDb.indexOf(status);

  const result = steps.map((s, index) => {
    return { name: s, completed: index <= statusIndex };
  });

  return result;
};

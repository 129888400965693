import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  invoices: [],
  invoiceFilter: {
    includeSent: false,
    awaitingPoNumber: false,
    poNumber: "",
    userEmail: "",
    companyId: 0,
  },
};
const invoice = createReducer(initialState, {
  SEARCH_INVOICES_SUCCESS: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      invoices: payload,
    };
  },
  UPDATE_INVOICE_STORE: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      invoices: state.invoices.map((i) => (i.id === payload.id ? payload : i)),
    };
  },
  REMOVE_INVOICE_FROM_STORE: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      invoices: state.invoices.filter((i) => i.id !== payload.id),
    };
  },
  UPDATE_INVOICE_FILTER: (state, action) => {
    const { field, value } = action.payload;
    return {
      ...state,
      invoiceFilter: { ...state.invoiceFilter, [field]: value },
    };
  },
});

export default invoice;

import { Typography } from "@mui/material";
import React from "react";
import Spinner from "./Spinner";

const ProcessingSpinner = (props) => {
  return (
    <div>
      <Spinner />
      <div style={{marginTop: 15,textAlign: "center"}}>
        <Typography variant="h4">
          Processing your request. 
        </Typography>
        <Typography variant="h5">
          This action can take up to {props.duration} {props.unitTime}. 
        </Typography>
      </div>
    </div>
  );
};

export default ProcessingSpinner;

import React, { useState, useEffect } from 'react';
import {
    Menu,
    MenuItem,
    IconButton,
    MenuList
} from '@mui/material';
import {
    MoreVert as MoreVertIcon
} from '@mui/icons-material';
import ConfirmationDialog from '../Shared/ConfirmationDialog';
import InvoicePreviewDialog from './InvoicePreview';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import ConfirmationDialogWithInput from "../Shared/ConfirmationDialogWithInput";
import { useAuth } from '../../context/auth/AuthContext';

const SalesMenu = (props) => {
    const {
        buttonId,
        menuId,
        job,
        setopenSnack,
        setsnackSeverity,
        setsnackText,
        getJobs
    } = props;

    const history = useHistory();
    const { user } = useAuth();

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [showInvoicePreviewDialog, setShowInvoicePreviewDialog] = useState(false);
    const [showManualApproveConfirmationDialog, setShowManualApproveConfirmationDialog] = useState(false);
    const [showSetToDraftConfirmationDialog, setShowSetToDraftConfirmationDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openTerminateRetainerDialog, setOpenTerminateRetainerDialog] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        if (!loading) {
            setAnchorEl(null);
            setShowDeleteConfirmationDialog(false);
            setShowInvoicePreviewDialog(false);
            setShowManualApproveConfirmationDialog(false);
            setShowSetToDraftConfirmationDialog(false);
            setOpenTerminateRetainerDialog(false);
            setopenSnack(false);
            setsnackSeverity("info");
            setsnackText(false)
        }
    };
    const handleDelete = () => {
        setDeleting(true);
        axios.delete(`Job/DeleteJob/${job.id}`)
            .then(() => {
                setopenSnack(true);
                setsnackSeverity("success");
                setsnackText(`Job deleted`);
                getJobs();
                handleClose();
            }).catch(e => {
                console.log(e)
                setopenSnack(true);
                setsnackSeverity("error");
                setsnackText(`${e.message}`);
            }).finally(() => {
                setDeleting(false);
            })
    }

    const handleToggleMuteNotifications = (muteNotification) => {
        setLoading(true);
        axios.post(`Job/ToggleMuteNotification/${job.id}/${muteNotification}`)
            .then(() => {
                setopenSnack(true);
                setsnackSeverity("success");
                setsnackText(`Notifications muted`);
                getJobs();
                handleClose();
            }).catch(e => {
                console.log(e)
                setopenSnack(true);
                setsnackSeverity("error");
                setsnackText(`${e.message}`);
            }).finally(() => {
                setLoading(false);
            })
    }

    const handleSetToDraft = () => {
        setLoading(true);
        axios.post(`Job/SetJobToDraft/${job.id}`)
            .then(() => {
                setopenSnack(true);
                setsnackSeverity("success");
                setsnackText(`Job set to draft`);
                getJobs();
                handleClose();
            }).catch(e => {
                console.log(e)
                setopenSnack(true);
                setsnackSeverity("error");
                setsnackText(`${e.message}`);
            }).finally(() => {
                setLoading(false);
            })
    }

    const handleManualApproval = (reason) => {
        setLoading(true);
        axios.post(`Job/ManuallyApproveJob`, {
            jobId: job.id,
            reason: reason,
            user: user.email
        }).then(() => {
            setopenSnack(true);
            setsnackSeverity("success");
            setsnackText(`Job has been manually approved`);
            getJobs();
            handleClose();
        }).catch(e => {
            console.log(e)
            setopenSnack(true);
            setsnackSeverity("error");
            setsnackText(`${e.message}`);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleTerminateRetainer = (reason) => {
        setLoading(true);
        axios.post(`Job/TerminateRetainer`, {
            jobId: job.id,
            reason: reason,
            user: user.email
        }).then(() => {
            setopenSnack(true);
            setsnackSeverity("success");
            setsnackText(`Retainer has been terminated. No invoices will be sent.`);
            getJobs();
            handleClose();
        }).catch(e => {
            console.log(e)
            setopenSnack(true);
            setsnackSeverity("error");
            setsnackText(`${e.message}`);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div>
            {showDeleteConfirmationDialog && (
                <ConfirmationDialog
                    open={showDeleteConfirmationDialog}
                    title={`Delete: [${job.id}] ${job.jobName}?`}
                    description="This will delete this job forever. Are you sure you want to delete this job?"
                    handleClose={() => { handleClose() }}
                    handleSubmit={() => { handleDelete() }}
                    submitLabel="Delete"
                    loading={deleting}
                />
            )}
            
            {showInvoicePreviewDialog && (
                <InvoicePreviewDialog
                    open={showInvoicePreviewDialog}
                    job={job}
                    handleClose={() => {
                        handleClose();
                    }}
                />
            )}
            {showSetToDraftConfirmationDialog && (
                <ConfirmationDialog
                    open={showSetToDraftConfirmationDialog}
                    title={`Set [${job.id}] ${job.jobName} back to draft?`}
                    description={`This will set [${job.id}] ${job.jobName} back to draft status and will need to be resent to the client for approval`}
                    handleClose={() => {
                        handleClose();
                    }}
                    handleSubmit={() => { handleSetToDraft() }}
                    submitLabel="Proceed"
                    loading={loading}
                />
            )}
            {showManualApproveConfirmationDialog && (
                <ConfirmationDialogWithInput
                    open={showManualApproveConfirmationDialog}
                    handleClose={() => handleClose()}
                    submitLabel={"Proceed"}
                    textFieldLabel="Reason for manual approval"
                    title={"Warning!"}
                    description={`Are you sure you want to manually approve ${job.jobName}`}
                    handleSubmit={(reason) => {
                        handleManualApproval(reason);
                    }}
                    loading={loading}
                />
            )}

            {openTerminateRetainerDialog && (
                <ConfirmationDialogWithInput
                    open={openTerminateRetainerDialog}
                    handleClose={() => handleClose()}
                    submitLabel={"Proceed"}
                    textFieldLabel="Reason for ending retainer"
                    title={"Warning!"}
                    description={`Your action will terminate the retainer and no invoices will be sent.`}
                    handleSubmit={(reason) => {
                        handleTerminateRetainer(reason);
                    }}
                    loading={loading}
                />
            )}
            <IconButton
                size="small"
                id={buttonId}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon size="small" />
            </IconButton>
            <Menu
                id={menuId}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `${buttonId}`,
                }}
            >
                {job.status === "sent" && (
                    <MenuItem disabled={loading} onClick={() => setShowSetToDraftConfirmationDialog(true)}>Set to Draft</MenuItem>
                )}
                {(job.status === "checked" || job.status === "sent") && user && (user.role === "Super User" || user.role === "Admin") && (
                    <MenuItem disabled={loading} onClick={() => setShowManualApproveConfirmationDialog(true)}>Manually Approve</MenuItem>
                )}
                {job.muteNotifications ? (
                    <MenuItem disabled={loading} onClick={() => handleToggleMuteNotifications(false)}>Unmute Reminders</MenuItem>
                ) : (
                    <MenuItem disabled={loading} onClick={() => handleToggleMuteNotifications(true)}>Mute Reminders</MenuItem>
                )}
                <MenuItem disabled={loading} onClick={() => setShowInvoicePreviewDialog(true)}>View Invoices</MenuItem>
                {(job.status === "draft" || job.status === "checked" || job.status === "sent") && (
                    <MenuItem disabled={loading} onClick={() => setShowDeleteConfirmationDialog(true)}>Delete</MenuItem>
                )}
                {job.status === "approved" && user && (user.role === "Super User" || user.role === "Admin") && (
                    <MenuItem disabled={loading} onClick={() => {
                        history.push({
                            pathname: `/job/${job.id}`,
                            state: { from: "fromManage" }
                        })
                    }}>Manage</MenuItem>
                )}
                {job.status === "approved" && job.isRetainer && user && (user.role === "Super User" || user.role === "Admin") && (
                    <MenuItem disabled={loading} onClick={() => {
                       setOpenTerminateRetainerDialog(true)
                    }}>Terminate Retainer</MenuItem>
                )}
            </Menu>
        </div>
    );
}

export default SalesMenu;
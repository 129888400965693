import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProductsTable from "./ProductsTable";
const ProductCategoryAccordion = ({
  products,
  productCategories,
  handleUpdateProductChangeList,
  handleArchiveProduct
}) => {
  const [expandedCategory, setExpandedCategory] = useState(null);

  const handleChange = (categoryId) => {
    if (expandedCategory === categoryId) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(categoryId);
    }
  };

  return (
    <div className="product-category-accordion-root">
      {productCategories?.map((c) => (
        <div key={c.id} className="product-category-accordion-container">
          <div
            className="product-category-accordion-title"
            onClick={(e) => handleChange(c.id)}
          >
            <Typography variant="body1">{c.name}</Typography>
            <ExpandMoreIcon />
          </div>
          {expandedCategory === c.id && (
            <div className="product-category-accordion-body">
              <ProductsTable
                products={products.filter((p) => p.productCategoryId === c.id)}
                handleUpdateProductChangeList={handleUpdateProductChangeList}
                handleArchiveProduct={handleArchiveProduct}
                setExpandedCategory={setExpandedCategory}
              />
            </div>
          )}
        </div>
      ))}

      {/*  */}
    </div>
  );
};

export default ProductCategoryAccordion;

import { Typography, Paper, IconButton, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "./sales.scss";
import { connect } from "react-redux";
import { getJobs, updateJobsFilter } from "../../reduxActions/job";
import { useHistory } from "react-router-dom";
import LoadingTableSkeleton from "../Shared/LoadingTableSkeleton";
import dayjsPluginUTC from "dayjs-plugin-utc";
import CustomTableHead from "../Shared/CustomTableHead";
import { getComparator } from "../../Utils/TableUtils";
import JobStatusDisplay from "../Shared/JobStatusDisplay";
import JobContactDisplay from "../Shared/JobContactDisplay";
import {
  calculateJobTotal,
  calculateTotalMargin,
} from "../../Utils/JobHelpers";
import JobFilters from "../Jobs/JobFilters";
import SalesMenu from "./SalesMenu";
import Snack from "../Shared/Snack";
import { useUserContext } from "../../context/user/UserContext";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { isEmpty, currencySymbolFormat } from "../../Utils/GeneralUtils";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const jobTableHeaders = [
  {
    field: "user",
    name: "User",
    fieldType: "string",
  },
  {
    field: "created",
    name: "Created",
    fieldType: "date",
  },
  {
    field: "companyName",
    name: "Company",
    fieldType: "string",
  },
  {
    field: "jobName",
    name: "Job Name",
    fieldType: "string",
  },
  {
    field: "poNumber",
    name: "PO",
    fieldType: "string",
  },
  {
    field: "additional",
    name: "",
    fieldType: "boolean",
  },
  {
    field: "contactFirstName",
    name: "Contact",
    fieldType: "string",
  },
  {
    field: "total",
    name: "Total",
    fieldType: "numeric",
  },
  {
    field: "marginTotal",
    name: "Margin",
    fieldType: "numeric",
  },
  {
    field: "status",
    name: "Status",
    fieldType: "string",
  },
  {
    field: "",
    name: "",
    fieldType: "string",
  },
];

const SalesTable = (props) => {
  const { allUsers, email: user, } = useUserContext();
  const {
    jobs,
    jobsFilter,
    getJobs,
    updateJobsFilter,
    totalJobs,
    previousFilter,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState({
    field: "created",
    name: "Created",
    fieldType: "date",
  });

  const [openSnack, setopenSnack] = useState(false);
  const [snackSeverity, setsnackSeverity] = useState("info");
  const [snackText, setsnackText] = useState("");
  const [searchJobsQueue, setSearchJobsQueue] = useState([]);
  const [lastSearchJobsFn, setLastSearchJobsFn] = useState(null);
  const [pagination, setPagination] = useState({
    highestPage: 0,
    rowsPerPage: 25,
    page: 0,
  });
  const [paginationNavClicked, setPaginationNavClicked] = useState(false);

  const history = useHistory();

  // Function to add an asynchronous function to the searchJobsQueue
  const addToSearchJobsQueue = (asyncFn) => {
    setSearchJobsQueue((prevQueue) => [...prevQueue, asyncFn]);
  };

  const searchJobs = async () => {
    setIsLoading(true);
    await getJobs(
      jobsFilter,
      pagination.page,
      pagination.rowsPerPage,
      pagination.highestPage
    );
    setIsLoading(false);
    setPaginationNavClicked(false);
  };

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getUser = (job) => {
    if (!isEmpty(job.firstName) && !isEmpty(job.lastName)) {
      var initial = job.lastName.split("")[0];
      return `${job.firstName} ${initial}.`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (pagination.page > pagination.highestPage) {
      (async () => {
        await addToSearchJobsQueue(searchJobs);
      })();
      setPagination({
        ...pagination,
        highestPage: pagination.page,
      });
    }
  }, [pagination.page, pagination.highestPage]);

  useEffect(() => {
    if (searchJobsQueue.length > 0) {
      // Clear any existing timer
      clearTimeout(lastSearchJobsFn);

      // Create a new timer to execute the last function in the searchJobsQueue after a delay
      const timeoutId = setTimeout(() => {
        setLastSearchJobsFn(null); // Clear the last function after it is executed
        const lastFunc = searchJobsQueue[searchJobsQueue.length - 1];
        lastFunc().then(() => {
          setSearchJobsQueue([]);
        });
      }, 1000);

      // Set the new timer to the state
      setLastSearchJobsFn(timeoutId);
    }
  }, [searchJobsQueue]);

  useEffect(() => {
    if (dayjs) {
      dayjs.extend(dayjsPluginUTC);
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(jobsFilter) !== JSON.stringify(previousFilter)) {
      console.log("jobsFilter", jobsFilter);
      console.log("previousFilter", previousFilter);
      addToSearchJobsQueue(searchJobs);
      setPagination({
        ...pagination,
        highestPage: -1,
        page: 0,
      });
    }
  }, [JSON.stringify(jobsFilter)]);

  return (
    <div>
      <Snack
        open={openSnack}
        setOpen={setopenSnack}
        severity={snackSeverity}
        text={snackText}
      />
      <JobFilters />
      <div className="table-responsive" style={{ maxHeight: "65vh" }}>
        <table className="table table-striped table-hover mb-0">
          <CustomTableHead
            headers={jobTableHeaders}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleOnRequestSort}
          />
          <tbody>
            {isLoading || !allUsers || paginationNavClicked ? (
              <LoadingTableSkeleton
                loadingMessage="Loading jobs..."
                colspan="13"
              />
            ) : (
              (pagination.rowsPerPage > 0
                ? [...jobs].slice(
                    pagination.page * pagination.rowsPerPage,
                    pagination.page * pagination.rowsPerPage +
                      pagination.rowsPerPage
                  )
                : [...jobs]
              )
                .sort(getComparator(order, orderBy))
                .map((j) => (
                  <tr
                    key={j.id}
                    onClick={(e) => {
                      if (paginationNavClicked) {
                        return;
                      }
                      history.push(`/job/${j.id}`);
                    }}
                    className={
                      j.status === "approved"
                        ? "approved-job-row padding-4px"
                        : "padding-4px"
                    }
                  >
                    <td className="text-center padding-4px">
                      {/* {allUsers?.find((u) => u.email === j.user)?.firstName} */}
                      {getUser(j)}
                    </td>
                    <td className="text-center padding-4px">
                      {j.created
                        ? dayjs?.utc(j.created)?.local().format("DD/MM/YYYY")
                        : " - "}
                    </td>
                    <td className="text-center padding-4px">{j.companyName}</td>
                    <td className="text-center padding-4px">
                      {`[${j.id}] `}
                      {j.projectCode && `${j.projectCode} - `}
                      {j.jobName}
                    </td>
                    <td className="text-center padding-4px">{j.poNumber}</td>
                    <td className="text-center padding-4px">
                      {j.additional && (
                        <div className="additional-badge-wrapper-home">
                          <div className="badge additional-black-badge h-auto">
                            Additional
                          </div>
                        </div>
                      )}
                      {j.crossOrganisationProjectId && (
                        <div className="additional-badge-wrapper-home">
                          <div className="badge additional-black-badge h-auto">
                            Internal
                          </div>
                        </div>
                      )}
                      {j.isRetainer && (
                        <div className="additional-badge-wrapper-home">
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <div>
                                  <b>{"First Invoice Month: "}</b>{" "}
                                  {j && j.completionDate
                                    ? dayjs
                                        .utc(j.completionDate)
                                        .local()
                                        .format("DD/MM/YYYY")
                                    : ""}
                                </div>
                                <div>
                                  <b>{"Number of Invoices: "}</b>{" "}
                                  {j && j.retainerDuration
                                    ? j.retainerDuration
                                    : ""}
                                </div>
                                {!!j.retainerManualEndDate && (
                                  <div>
                                    <b>{"Termination Date: "}</b>{" "}
                                    {dayjs(j.retainerManualEndDate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </div>
                                )}
                              </React.Fragment>
                            }
                          >
                            <div className="badge additional-green-badge h-auto">
                              Retainer{" "}
                              {!!j.retainerManualEndDate && "(terminated)"}
                            </div>
                          </HtmlTooltip>
                        </div>
                      )}
                    </td>
                    <td className="text-center padding-4px">
                      <JobContactDisplay job={j} />
                    </td>
                    <td className="text-center padding-4px">
                      {j.total?.toLocaleString("en-NZ", {
                        style: "currency",
                        currency: j.currency,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td className="text-center padding-4px">
                      {j.marginTotal?.toLocaleString("en-NZ", {
                        style: "currency",
                        currency: j.currency,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td className="text-center padding-4px">
                      <JobStatusDisplay job={j} />
                    </td>
                    <td
                      className="text-center padding-4px"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <SalesMenu
                        buttonId={`${j.id}-options-button`}
                        menuId={`${j.id}-options-menu`}
                        job={j}
                        setopenSnack={setopenSnack}
                        setsnackSeverity={setsnackSeverity}
                        setsnackText={setsnackText}
                        getJobs={() => addToSearchJobsQueue(searchJobs)}
                      />
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={totalJobs}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        onPageChange={(event, newPage) => {
          if (newPage > pagination.highestPage) {
            setPaginationNavClicked(true);
          }
          setPagination({
            ...pagination,
            page: newPage,
          });
        }}
        onRowsPerPageChange={(event) => {
          setPagination({
            rowsPerPage: parseInt(event.target.value, 10),
            highestPage: -1,
            page: 0,
          });
        }}
        SelectProps={{
          disabled: paginationNavClicked,
        }}
        backIconButtonProps={
          paginationNavClicked
            ? {
                disabled: paginationNavClicked,
              }
            : undefined
        }
        nextIconButtonProps={
          paginationNavClicked
            ? {
                disabled: paginationNavClicked,
              }
            : undefined
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  jobs: state.job.jobs,
  jobsFilter: state.job.jobsFilter,
  totalJobs: state.job.totalJobs,
  previousFilter: state.job.previousFilter,
});

export default connect(mapStateToProps, {
  getJobs,
  updateJobsFilter,
})(SalesTable);

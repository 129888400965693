import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField
} from "@mui/material";
import AccountingCard from "./AccountingCard";
import "./company.scss";
import { Close } from "@mui/icons-material";
import axios from "axios";
import SpellCheckTextField from "../Shared/SpellCheckTextField";
import { validateEmail, get12MonthsAheadArr } from "../../Utils/JobHelpers";
import { hasElements, isEmpty } from "../../Utils/GeneralUtils";
import { addCompany, updateCompanyStore } from "../../reduxActions/company";
import { connect } from "react-redux";
import Snack from "../Shared/Snack";
import CompanyOnboardingCentre from "./CompanyOnboardingCentre";

const errorState = {
  name: "",
  // companySize: "",
  registeredName: "",
  address: "",
  primaryAccountsEmail: "",
  showOnTop: false,
  contactFirstName: "",
  contactLastName: "",
  contactEmailAddress: ""
};

const errorStateTranslation = (state) => {
  switch (state) {
    case "registeredName":
      return "Registered Name";
    case "address":
      return "Company Address";
    case "name":
      return "Company Name";
    case "accountsEmail":
      return "Accounts Email";
    // case "companySize":
    //   return "Company Size";
    case "contactFirstName":
      return "Contact First Name";
    case "contactLastName":
      return "Contact Last Name";
    case "contactEmailAddress":
      return "Contact Email Address";
    default:
      return "";
  }
}

const CompanyForm = ({
  selectedCompany,
  setselectedCompany,
  handleCloseNewCompanyDialog,
  handleCloseNewCompanyDialogAfterCreated,
  handleNewCompanyCreated,
  handleNewContactCreated,
  addCompany,
  updateCompanyStore,
  selectedOrganisation,
  updateMultiPropertiesCompany,
  handleCloseNewCompanyDialogAfterUpdated
}) => {
  const [contact, setcontact] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    personCategory: "Client",
    isOnboardingRequired: true,
  });
  const [openSnack, setopenSnack] = useState(false);
  const [snackSeverity, setsnackSeverity] = useState("info");
  const [snackText, setsnackText] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [invalidEmailMessage, setInvalidEmailMessage] = useState(null);
  const [errorForm, setErrorForm] = useState(errorState);
  const [isSaving, setIsSaving] = useState(false);
  const [isNzme, setIsNzme] = useState(false);

  const handleOnChangeCompany = (field, value) => {
    if (field === "companyLocation") {
      const _value = value || "NEW_ZEALAND"
      setselectedCompany({
        ...selectedCompany,
        [field]: _value,
        isNzbusiness: _value === "NEW_ZEALAND" ? true : false,
        gstregistered: _value === "INTERNATIONAL" ? false : true,
        currency: "NZD",
        registeredName: ""
      });
    }
    else if (field === "xeroTrackingOption") {
      setselectedCompany({
        ...selectedCompany,
        "xeroTrackingOption": value.trackingOption,
        "xeroTrackingCategory": value.trackingCategory,
      });
    }
    else {
      setselectedCompany({
        ...selectedCompany,
        [field]: value,
      });
    }
  };

  const handleOnChangeContact = (field, value) => {
    setcontact({
      ...contact,
      [field]: value,
    });
  };

  const handleOnChangeOnboarding = (field, value) => {
    setselectedCompany({
      ...selectedCompany,
      onboardingCentreFkNavigation: {
        ...selectedCompany.onboardingCentreFkNavigation,
        [field]: value,
      },
    });
  };


  const handleSaveCompany = () => {
    if (validateForm()) {
      if (selectedCompany.id) {
        updateCompany();
      } else {
        if (contact.firstName && contact.lastName && contact.emailAddress) {
          createCompany();
        } else {
          setsnackText("Please enter all fields with *");
          setsnackSeverity("error");
          setopenSnack(true);
        }
      }
    }
  };

  const createCompany = () => {
    let contactClone = { ...contact };
    contactClone.fullName = contact.firstName + " " + contact.lastName;
    contactClone.organisationFk = selectedOrganisation.id;
    setIsSaving(true);
    axios
      .post("Company/CreateCompany", {
        ...selectedCompany,
        person: [contactClone],
        organisationFk: selectedOrganisation.id,
      })
      .then(({ data }) => {
        handleNewCompanyCreated(data);
        handleNewContactCreated(data.person.length > 0 && data.person[0]);
        addCompany(data);
        handleCloseNewCompanyDialogAfterCreated();
        setsnackText("Company Created");
        setsnackSeverity("info");
        setopenSnack(true);
      })
      .catch((err) => {
        setsnackText(err.response.data);
        setsnackSeverity("error");
        setopenSnack(true);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const updateCompany = () => {
    let _company = { ...selectedCompany };

    if (
      !hasElements(selectedCompany.person) &&
      !isEmpty(contact.firstName) &&
      !isEmpty(contact.lastName) &&
      !isEmpty(contact.emailAddress)
    ) {
      let contactClone = { ...contact };
      contactClone.fullName = contact.firstName + " " + contact.lastName;
      contactClone.organisationFk = selectedOrganisation.id;
      _company = { ...selectedCompany, person: [contactClone] };
    }
    setIsSaving(true);
    axios
      .post("Company/UpdateCompany", _company)
      .then((res) => {
        updateCompanyStore(selectedCompany);
        if(handleCloseNewCompanyDialogAfterUpdated){
          handleCloseNewCompanyDialogAfterUpdated(_company)
        }
        setsnackText("Company Updated");
        setsnackSeverity("info");
        setopenSnack(true);
      })
      .catch((err) => {
        // setsnackText(err.response.data);
        debugger;
        setsnackText(err);
        setsnackSeverity("error");
        setopenSnack(true);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    console.log(errorForm)
  }, [errorForm])

  useEffect(() => {
    if (selectedCompany.companySize === "NZME" && !isNzme) {
      setIsNzme(true);
    }
  }, [isNzme])

  const handleEmailChange = (emailAddress) => {
    handleOnChangeContact("emailAddress", emailAddress);
    if (validateEmail(emailAddress)) {
      setInvalidEmailMessage(null);
      setIsInvalidEmail(false);
    } else {
      setInvalidEmailMessage("Invalid Email Address");
      setIsInvalidEmail(true);
    }
  };

  const validateForm = () => {
    const tempError = { ...errorForm };
    let validForm = true;
    if (isEmpty(selectedCompany.name) === true) {
      tempError.name = "Required field";
      validForm = false;
    } else {
      tempError.name = "";
    }
    // if (selectedOrganisation?.id !== 1) {
    //   if (isEmpty(selectedCompany.companySize) === true) {
    //     tempError.companySize = "Required field";
    //     validForm = false;
    //   } else {
    //     tempError.companySize = "";
    //   }

    //   if (isEmpty(selectedCompany.teamColour) === true) {
    //     tempError.teamColour = "Required field";
    //     validForm = false;
    //   } else {
    //     tempError.teamColour = "";
    //   }
    // }

    if (!selectedCompany.id || !hasElements(selectedCompany.person)) {
      if (isEmpty(contact.firstName) === true) {
        tempError.contactFirstName = "Required Field";
        validForm = false;
      } else {
        tempError.contactFirstName = "";
      }

      if (isEmpty(contact.lastName) === true) {
        tempError.contactLastName = "Required Field";
        validForm = false;
      } else {
        tempError.contactLastName = "";
      }

      if (isEmpty(contact.emailAddress) !== true && !validateEmail(contact.emailAddress)) {
        tempError.contactEmailAddress = "Please enter a valid email";
        validForm = false;
      } else {
        tempError.contactEmailAddress = "";
      }
    }
    if (isEmpty(selectedCompany.registeredName) === true && selectedCompany.companyLocation !== "INTERNATIONAL") {
      tempError.registeredName = "Required field";
      validForm = false;
    } else {
      tempError.registeredName = "";
    }
    if (isEmpty(selectedCompany.address) === true && selectedCompany.companyLocation !== "INTERNATIONAL") {
      tempError.address = "Required field";
      validForm = false;
    } else {
      tempError.address = "";
    }

    if (isEmpty(selectedCompany.primaryAccountsEmail) !== true && !validateEmail(selectedCompany.primaryAccountsEmail)) {
      tempError.primaryAccountsEmail = "Please enter a valid email";
      validForm = false;
    } else {
      tempError.primaryAccountsEmail = "";
    }

    if (!validForm) {
      tempError.showOnTop = true;
      setErrorForm(tempError);
      document.getElementById("add-company-dialog-company-root").scrollIntoView();
    } else {
      tempError.showOnTop = false;
      return validForm;
    }
  };

  const errorMessage = () => {
    var propertyArr = [];
    for (const property in errorForm) {
      propertyArr.push(property);
    }
    return (
      <div style={{ display: "flex", flexDirection: "column", color: "#d32f2f" }}>
        <Typography variant="caption">Missing:</Typography>
        {propertyArr.map(p => {
          if (errorForm[p] !== "" && p !== "showOnTop") {
            return (
              <Typography variant="caption">- {errorStateTranslation(p)}</Typography>
            )
          } else {
            return ""
          }
        })}
      </div>
    );
  }

  return (
    <>
      <Snack
        open={openSnack}
        setOpen={setopenSnack}
        severity={snackSeverity}
        text={snackText}
      />
      <div id="add-company-dialog-company-root" className="add-company-dialog-company-root add-company-dialog-height">
        <div className="add-company-dialog-company-container">
          {errorForm.showOnTop && <div className="newcompany-textfield flex-row-components">
            {errorMessage()}
          </div>}
          <div className="add-company-dialog-section-title">
            <Typography variant="button">Company</Typography>
            <Typography variant="caption">
              (What we will call in our systems)
            </Typography>
          </div>
          <div className="">
            <div className="newcompany-textfield flex-row-components">
              <SpellCheckTextField
                id="company-name"
                label="Company Name *"
                error={errorForm.name}
                errorMessage={errorForm.name}
                size="small"
                fullWidth={true}
                value={selectedCompany?.name}
                handleOnChange={(newValue) =>
                  handleOnChangeCompany("name", newValue)
                }
              />
            </div>
          </div>
          <div className="">
            {/* {selectedOrganisation?.id !== 1 && (
              <div className="newcompany-textfield flex-row-components">
                <Autocomplete
                  value={selectedCompany?.companySize}
                  options={["SMB", "Enterprise", "NZME - SMB"]}
                  onChange={(event, newValue) => {
                    if (newValue === "NZME - SMB") {
                      updateMultiPropertiesCompany({
                        ...selectedCompany,
                        "companySize": "NZME",
                        "companyLocation": "NEW_ZEALAND",
                        "currency": "NZD",
                        "registeredName": "NZME LIMITED",
                        "address": "2 Graham Street, Auckland CBD, Auckland, New Zealand",
                        "primaryAccountsEmail": "accounts.payable@nzme.co.nz"
                      })
                      setIsNzme(true);
                    } else {
                      if (isNzme) {
                        updateMultiPropertiesCompany({
                          ...selectedCompany,
                          "companySize": newValue,
                          "companyLocation": "NEW_ZEALAND",
                          "currency": "NZD",
                          "registeredName": "",
                          "address": "",
                          "primaryAccountsEmail": ""
                        })
                        setIsNzme(false);
                      } else {
                        handleOnChangeCompany("companySize", newValue);
                      }
                    }
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Division *"
                      size="small"
                      fullWidth={true}
                      variant="filled"
                      error={errorForm.companySize ? errorForm.companySize : false}
                      helperText={errorForm.companySize ? errorForm.companySize : null}
                    />
                  )}
                  disableClearable

                />
                <FormControl fullWidth>
                  <InputLabel>Comapny Size *</InputLabel>
                  <Select
                    variant="filled"
                    value={selectedCompany?.companySize}
                    label="Company Size *"
                    onChange={(e) => {
                      handleOnChangeCompany("companySize", e.target.value);
                    }}
                    size="small"
                  >
                    <MenuItem value={"SMB"}>{"SMB (Small to Medium Size Business)"}</MenuItem>
                    <MenuItem value={"Enterprise"}>Enterprise</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )} */}
          </div>
          {/* <div className="">
            <div className="newcompany-textfield flex-row-components">
              <Autocomplete
                value={selectedCompany?.teamColour}
                options={getTeamColours()}
                onChange={(event, newValue) => {
                  handleOnChangeCompany("teamColour", newValue);
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Team Colour *"
                    size="small"
                    fullWidth={true}
                    variant="filled"
                    error={errorForm.teamColour ? errorForm.teamColour : false}
                    helperText={errorForm.teamColour ? errorForm.teamColour : null}
                  />
                )}
                disableClearable

              />
            </div>
          </div> */}
        </div>

        {(!selectedCompany.id || !hasElements(selectedCompany.person)) && (
          <div className="add-company-dialog-contact-container">
            <div className="add-company-dialog-section-title">
              <Typography variant="button">Contact</Typography>
              <Typography variant="caption">
                (The person who will approve the quote)
              </Typography>
            </div>
            <div className="add-company-dialog-contact-form">
              <SpellCheckTextField
                id="label-name"
                label="First Name *"
                size="small"
                fullWidth={true}
                value={contact.firstName}
                handleOnChange={(newValue) =>
                  handleOnChangeContact("firstName", newValue)
                }
                error={errorForm.contactFirstName}
                errorMessage={errorForm.contactFirstName}
              />
              <SpellCheckTextField
                id="last-name"
                label="Last Name *"
                size="small"
                fullWidth={true}
                value={contact.lastName}
                handleOnChange={(newValue) =>
                  handleOnChangeContact("lastName", newValue)
                }
                error={errorForm.contactLastName}
                errorMessage={errorForm.contactLastName}
              />
              {/* There was no value in the orginal textfield so I added it hopefully it doesn't break the code- Jethro */}
              <SpellCheckTextField
                id="email-name"
                label="Email Address *"
                size="small"
                fullWidth={true}
                value={contact.emailAddress}
                handleOnChange={(newValue) => handleEmailChange(newValue)}
                error={isInvalidEmail}
                errorMessage={invalidEmailMessage}
              />
              {/* There was no value in the orginal textfield so I added it hopefully it doesn't break the code- Jethro */}
              <SpellCheckTextField
                id="phone-number"
                label="Phone Number"
                size="small"
                fullWidth={true}
                value={contact.phoneNumber}
                handleOnChange={(newValue) =>
                  handleOnChangeContact("phoneNumber", newValue)
                }
              />
            </div>
          </div>
        )}
        <div className="add-company-dialog-accounting-container">
          <div className="add-company-dialog-section-title">
            <Typography variant="button">Accounting</Typography>
          </div>
          <AccountingCard
            handleOnChangeCompany={handleOnChangeCompany}
            company={selectedCompany}
            errorForm={errorForm}
            isNzme={isNzme}
          />
        </div>
        {/* {selectedOrganisation?.id !== 1 && !selectedCompany.hasSignedMsa &&
          selectedCompany.onboardingCentreFkNavigation && (
            <div className="add-company-dialog-accounting-container">
              <div className="add-company-dialog-section-title">
                <Typography variant="button">Onboarding Centre</Typography>
                <Typography variant="caption">
                  (On signing off of first quote the system will set up and
                  configure:)
                </Typography>
              </div>
              <CompanyOnboardingCentre
                company={selectedCompany}
                handleOnChangeOnboarding={handleOnChangeOnboarding}
              />
            </div>
          )} */}
        <div className="add-company-dialog-action-container">
          <Button
            className="sales-button"
            onClick={handleCloseNewCompanyDialog}
            variant="contained"
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            className="sales-button"
            variant="contained"
            onClick={handleSaveCompany}
            disabled={isSaving}
          >
            {isSaving ? (
              <CircularProgress />
            ) : selectedCompany.id ? (
              "Update"
            ) : (
              "Create"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {
  addCompany,
  updateCompanyStore,
})(CompanyForm);

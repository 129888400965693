import React, { Fragment } from 'react'

const SpinnerV2 = () => {

    const getRandomLoader = () => {
        var loaderClasses = ["dot-revolution", "dot-bricks", "dot-floating"];
        return loaderClasses[(Math.floor(Math.random() * loaderClasses.length))]
    }

    return (
        <Fragment>
            <div style={{
                display: "flex",
                justifyContent: "center",
                padding: "30px 0px",
                overflow: "hidden"
            }}>
                <div className={getRandomLoader()}></div>
            </div>
        </Fragment>
    )
}


export default SpinnerV2;
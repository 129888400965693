import { Autocomplete, Avatar, Checkbox, FormControlLabel, TextField, IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useUserContext } from "../../../context/user/UserContext";
import "./dashboardFilters.scss";
import { getTeamColours } from "../../../Utils/GeneralUtils";
import FilterListIcon from "@mui/icons-material/FilterList";
import DashboardFilterPopover from "./DashboardFilterPopover";

const xeroAccounts = [
  { id: 1, name: "Magnesium" },
  { id: 2, name: "Thompson Spencer" },
];

const DashboardFilters = ({ filters, handleChangeFilter, organisations }) => {
  const { allUsers } = useUserContext();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [selectedXeroAccount, setSelectedXeroAccount] = useState(null);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [allProductTypes, setAllProductTypes] = useState([]);
  const [openFilterPopover, setOpenFilterPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (allProductTypes.length === 0) {
      axios.get(`Product/GetAllProductTypes`).then(({ data }) => {
        setAllProductTypes(data)
      })
    }
  }, [allProductTypes])


  useEffect(() => {
    const xeroAccount = xeroAccounts.find((x) => x.id === filters.xeroAccount);
    setSelectedXeroAccount(xeroAccount);
    if (filters.xeroAccount === 1) {
      setSelectedOrganisation(
        organisations?.find((c) => c.id === filters.xeroAccount)
      );
    } else {
      setSelectedOrganisation(null)
    }
  }, [filters.xeroAccount]);

  useEffect(() => {
    setSelectedOrganisation(
      organisations?.find((c) => c.id === filters.organisationId)
    );
    findOrganisationCompanies();
  }, [filters.organisationId]);

  useEffect(() => {
    setSelectedCompany(companies?.find((c) => c.id === filters.companyId));
  }, [filters.companyId]);

  const findOrganisationCompanies = () => {
    axios
      .get(`company/GetCompanies/${filters.organisationId}`)
      .then(({ data }) => {
        setCompanies(data);
      });
  };

  const filterOrganisations = () => {
    if (filters.xeroAccount === 1) {
      return organisations.filter((o) => o.id === filters.xeroAccount);
    } else if (filters.xeroAccount && filters.xeroAccount !== 1) {
      return organisations.filter((o) => o.id >= filters.xeroAccount);
    }
    return organisations;
  };

  const handleFilterPopover = (event) => {
    setOpenFilterPopover(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setOpenFilterPopover(false);
    setAnchorEl(null);
  };

  return (
    <div className="dashboard-filters-root">
      <Autocomplete
        className="dashboard-filters-input"
        options={xeroAccounts}
        getOptionLabel={(option) => option?.name}
        value={selectedXeroAccount}
        onChange={(e, newValue) => {
          e.preventDefault();
          handleChangeFilter("xeroAccount", newValue?.id || 0);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option?.id}>
              <div>{option?.name}</div>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Xero Account"
            size="small"
            fullWidth={true}
            variant="filled"
          />
        )}
      />
      <Autocomplete
        className="dashboard-filters-input"
        options={filterOrganisations()}
        getOptionLabel={(option) => option.name}
        value={selectedOrganisation}
        onChange={(e, newValue) => {
          e.preventDefault();
          handleChangeFilter("organisationId", newValue?.id || 0);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Organisation"
            size="small"
            fullWidth={true}
            variant="filled"
          />
        )}
      />
      <Autocomplete
        className="dashboard-filters-input"
        options={companies}
        getOptionLabel={(option) => option.name}
        value={selectedCompany}
        onChange={(e, newValue) => {
          handleChangeFilter("companyId", newValue?.id || 0);
        }}
        selectOnFocus
        clearOnBlur
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Company"
            size="small"
            fullWidth={true}
            variant="filled"
          />
        )}
      />
      <Autocomplete
        className="dashboard-filters-input"
        options={allProductTypes}
        getOptionLabel={(option) => option}
        value={selectedProductType}
        onChange={(e, newValue) => {
          e.preventDefault();
          setSelectedProductType(newValue)
          handleChangeFilter("productType", newValue || null);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option}>
              <div>{option}</div>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Product Type"
            size="small"
            fullWidth={true}
            variant="filled"
          />
        )}
      />
      <Autocomplete
        className="dashboard-filters-input"
        options={["Enterprise", "SMB", "NZME - SMB"]}
        getOptionLabel={(option) => option}
        value={filters.companySize === "NZME" ? "NZME - SMB" : filters.companySize}
        onChange={(e, newValue) => {
          if (newValue === "NZME - SMB") {
            handleChangeFilter("companySize", "NZME");
          } else {
            handleChangeFilter("companySize", newValue || "");
          }
        }}
        selectOnFocus
        clearOnBlur
        renderOption={(props, option) => {
          return (
            <li {...props} key={option}>
              {option}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Company Size"
            size="small"
            fullWidth={true}
            variant="filled"
          />
        )}
      />

      <Autocomplete
        className="dashboard-filters-input"
        options={getTeamColours()}
        getOptionLabel={(option) => option}
        value={filters.teamColour}
        onChange={(e, newValue) => {
          handleChangeFilter("teamColour", newValue || "");
        }}
        selectOnFocus
        clearOnBlur
        renderOption={(props, option) => {
          return (
            <li {...props} key={option}>
              {option}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Team Colour"
            size="small"
            fullWidth={true}
            variant="filled"
          />
        )}
      />

      <Autocomplete
        className="dashboard-filters-input"
        options={allUsers.filter((u) => u.hasAppAccess)}
        getOptionLabel={(option) => option.firstName}
        value={allUsers.find((u) => u.email === filters.email)}
        onChange={(e, newValue) => {
          handleChangeFilter("userEmail", newValue?.email || "");
        }}
        selectOnFocus
        clearOnBlur
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Avatar
                alt="avatar"
                src={option.photoUrl}
                style={{ marginRight: 10 }}
              />{" "}
              {option.firstName + " " + option.lastName}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Created By"
            size="small"
            fullWidth={true}
            variant="filled"
          />
        )}
      />
      <div className="dashboard-filters-input">
        {/* <IconButton
          style={{ color: "#000" }}
          aria-label="filter"
          onClick={handleFilterPopover}
        >
          <FilterListIcon />
        </IconButton>
        <DashboardFilterPopover
          open={openFilterPopover}
          handleClose={handleCloseFilterPopover}
          anchorEl={anchorEl}
          handleChangeFilter={handleChangeFilter}
          filters={filters}
        /> */}
      </div>
      <TextField
        label="From"
        type="date"
        id="dateFrom"
        name="dateFrom"
        value={filters.dateFrom}
        variant="filled"
        size="small"
        fullWidth={true}
        onChange={(e) => handleChangeFilter("dateFrom", e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label="To"
        type="date"
        id="dateTo"
        name="dateTo"
        fullWidth={true}
        value={filters.dateTo}
        onChange={(e) => handleChangeFilter("dateTo", e.target.value)}
        variant="filled"
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div>
        <FormControlLabel
          label="Show Jobs Without Sign Off"
          control={
            <Checkbox
              checked={!filters.pullApprovedOnly}
              onChange={(e) => handleChangeFilter("pullApprovedOnly", !e.target.checked)}
            />
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  organisations: state.organisation.organisations,
});

export default connect(mapStateToProps, {})(DashboardFilters);

import React, { useState, useEffect } from "react";
import "./clientsProfitabilityDashboard.scss";
import ProfitabilityBar from "./ProfitabilityBar";
import { Skeleton, Typography } from "@mui/material";
import axios from "axios";
const ClientsProfitabilityDashboard = ({
  filters,
  setFilters,
  handleChangeFilter
}) => {

  const [loading, setLoading] = useState(true);

  const [profitabilityData, setProfitabilityData] = useState({
    chart: [],
    data: [],
  });

  useEffect (() => {
    setLoading(true);
    var status = filters.status && filters.status.length > 0 ? filters.status.join(",") : ""
    axios
      .post(
        `Dashboard/GetProfitabilityChartData`, 
        {
          dateFrom: filters.dateFrom,
          dateTo: filters.dateTo,
          organisationId: filters.organisationId || 0,
          companyId: filters.companyId || 0,
          userEmail: filters.userEmail || "",
          companySize: filters.companySize || "",
          xeroAccount: filters.xeroAccount || 0,
          pullApprovedOnly: filters.pullApprovedOnly, 
          productType: filters.productType || null,
          status: status
        }
      )
      .then(({ data }) => {
        setProfitabilityData(data);
      })
      .finally(() => setLoading(false));
  }, [filters]);

  return loading ? (
    <>
      <Skeleton height={500} />
    </>
  ) : (
    <div className="profitability-dashboard-root">
      <div className="profitability-dashboard-double-chart">
        <div>
          <Typography variant="h5">Most Profitable</Typography>
          <ProfitabilityBar profitabilityData={profitabilityData?.mostProfitable}/>
        </div>
        <div>
          <Typography variant="h5">Least Profitable</Typography>
          <ProfitabilityBar profitabilityData={profitabilityData?.leastProfitable}/>
        </div>
      </div>
    </div>
  );
};

export default ClientsProfitabilityDashboard;

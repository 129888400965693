import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Close from "@mui/icons-material/Close";

const ConfirmSetAsSentDialog = ({
  open,
  handleClose,
  handleSubmit,
  invoice,
}) => {
  const [status, setStatus] = useState("AUTHORISED");
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title-contact"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className="sales-dialog-title">
        Please Confirm the Details{" "}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" justifyContent="center" gap={"30px"}>
          <Grid item>
            <Typography variant="h6">{invoice.jobName}</Typography>
          </Grid>
          <Grid item>
            <Autocomplete
              value={status}
              options={["DRAFT", "AUTHORISED"]}
              onChange={(event, newValue) => {
                setStatus(newValue);
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Invoice Status"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                />
              )}
              disableClearable
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          className="default-button"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className={"default-button"}
          onClick={(e) => handleSubmit(status)}
        >
          Set as Sent
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSetAsSentDialog;

import {
  Autocomplete,
  Avatar,
  Chip,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterPopover from "./InvoiceFilterPopover";
import { useUserContext } from "../../context/user/UserContext";
import { getCompanies } from "../../reduxActions/company";

const InvoiceFilters = (props) => {
  const { allUsers } = useUserContext();
  let { invoiceFilter, companies, handleUpdateInvoiceFilter } = props;
  const [openFilterPopover, setOpenFilterPopover] = useState(false);
  const [keyword, setKeyword] = useState(invoiceFilter.keyword !== undefined ? invoiceFilter.keyword : "");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterPopover = (event) => {
    setOpenFilterPopover(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setOpenFilterPopover(false);
    setAnchorEl(null);
  };

  const handleUpdateKeyword = () => {
    handleUpdateInvoiceFilter("keyword", keyword?.trim());
  };

  return (
    <>
      <div className="invoice-filters-root">
        <TextField
          id="keyword"
          label="Global Search (press Enter to search)"
          variant="filled"
          fullWidth={true}
          value={keyword}
          size="small"
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 13 || e.key === "Enter") {
              handleUpdateKeyword();
            }
          }}
        />
        <Autocomplete
          className="input-100-width"
          options={companies}
          getOptionLabel={(option) => option.name}
          value={companies?.find((c) => c.id === invoiceFilter.companyId)}
          onChange={(e, newValue) => {
            let id = 0;
            if (newValue) {
              id = newValue.id;
            }
            handleUpdateInvoiceFilter("companyId", id);
          }}
          selectOnFocus
          clearOnBlur
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Company"
              size="small"
              fullWidth={true}
              variant="filled"
            />
          )}
        />

        <Autocomplete
          className="input-100-width"
          options={allUsers}
          getOptionLabel={(option) => option.firstName}
          value={allUsers.find((u) => u.email === invoiceFilter.userEmail)}
          onChange={(e, newValue) => {
            handleUpdateInvoiceFilter("userEmail", newValue?.email || "");
          }}
          selectOnFocus
          clearOnBlur
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Avatar
                  alt="avatar"
                  src={option.photoUrl}
                  style={{ marginRight: 10 }}
                />{" "}
                {option.firstName + " " + option.lastName}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Created By"
              size="small"
              fullWidth={true}
              variant="filled"
            />
          )}
        />

        <div>
          <IconButton
            style={{ color: "#000" }}
            aria-label="filter"
            onClick={handleFilterPopover}
          >
            <FilterListIcon />
          </IconButton>
          <FilterPopover
            open={openFilterPopover}
            handleClose={handleCloseFilterPopover}
            anchorEl={anchorEl}
            updateInvoiceFilter={handleUpdateInvoiceFilter}
            invoiceFilter={invoiceFilter}
          />
        </div>
      </div>
      <div className="invoice-filters-chip-container">
        {invoiceFilter?.includeSent && (
          <Chip
            label="Displaying Sent Invoice"
            variant="outlined"
            onDelete={() => {
              handleUpdateInvoiceFilter("includeSent", false);
            }}
          />
        )}
        {invoiceFilter?.awaitingPoNumber && (
          <Chip
            label="Displaying Missing Po Numbers"
            variant="outlined"
            onDelete={() => {
              handleUpdateInvoiceFilter("awaitingPoNumber", false);
            }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  companies: state.company.companies,
  selectedOrganisation: state.organisation.selectedOrganisation
});

export default connect(mapStateToProps, {
  getCompanies
})(InvoiceFilters);

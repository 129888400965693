import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Switch,
  FormGroup,
  Checkbox,
  CardHeader,
  CardActions,
  Paper,
  Stack,
  Chip,
  Tooltip,
  Autocomplete,
  FormControlLabel,
  createFilterOptions,
} from "@mui/material";
import { Help } from "@mui/icons-material";
import axios from "axios";
import GoogleMapsPlaces from "../Shared/GoogleMapsPlaces";
import "./accountingCard.scss";
import SpellCheckTextField from "../Shared/SpellCheckTextField";
import { connect } from "react-redux";
import { removeLeadingZeros } from "../../Utils/JobHelpers";
import { useAuth } from "../../context/auth/AuthContext";

const filter = createFilterOptions();
const locations = [
  { id: "NEW_ZEALAND", name: "New Zealand" },
  { id: "AUSTRALIA", name: "Australia" },
  { id: "INTERNATIONAL", name: "International" },
];
const currencies = [
  { id: "NZD", name: "NZ Dollar" },
  { id: "AUD", name: "AUS Dollar" },
  { id: "USD", name: "US Dollar" },
  { id: "EUR", name: "Euro" },
  { id: "JPY", name: "Japanese Yen" },
  { id: "CNY", name: "Chinese Yuan" },
  { id: "GBP", name: "British Pound" },
];
const AccountingCard = (props) => {
  const {
    handleOnChangeCompany,
    company,
    errorForm,
    selectedOrganisation,
    isNzme
  } = props;
  const { user } = useAuth();
  const [nzbnEntities, setNzbnEntities] = useState([]);
  const [trackingCategories, setTrackingCategories] = useState(null);
  const [trackingOption, setTrackingOption] = useState(null);
  const timeout = useRef(); // debounce

  useEffect(() => {
    if (company?.registeredName) {
      setNzbnEntities([...nzbnEntities, company?.registeredName]);
    }
    getAllTrackingOptions();
  }, []);

  useEffect(() => {
    if (trackingCategories) {
      setTrackingOption(
        trackingCategories?.Options?.find(
          (t) => t.TrackingOptionID === company?.xeroTrackingOption
        )
      );
    }
  }, [trackingCategories]);

  const getAllTrackingOptions = () => {
    axios
      .get(
        `TrackingCategory/GetAllTrackingCategories/${selectedOrganisation?.id}`
      )
      .then(({ data }) => {
        setTrackingCategories(data);
      })
      .catch((err) => {
        console.log("Error fetching tracking options in Xero", err);
        alert("Error fetching tracking options in Xero");
      });
  };

  const renderAustraliaCompanies = (data) => {
    const regex = /\[(.*?)\]/gi; // get result between []
    const resultMatchGroup = data.match(regex);
    const parsedData = JSON.parse(resultMatchGroup);
    console.log("parsed", parsedData);
    if (parsedData && parsedData.length > 0) {
      setNzbnEntities(
        parsedData.map((business) => ({ entityName: business.Name }))
      );
    }
  };

  const getNzbnDropdownList = (searchTerm) => {
    //Clear the previous timeout.
    clearTimeout(timeout.current);
    if (!searchTerm.trim()) {
      setNzbnEntities([]);
      return;
    }
    timeout.current = setTimeout(() => {
      if (company.companyLocation === "AUSTRALIA" && searchTerm.length > 5) {
        fetch(
          `https://abr.business.gov.au/json/MatchingNames.aspx?name=${searchTerm}&maxResults=10&guid=14558d22-36fe-46b0-a9cf-fdf484120915&callback=callback`
        )
          .then((res) => res.text())
          .then((data) => {
            renderAustraliaCompanies(data);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else if (company.companyLocation !== "AUSTRALIA") {
        axios
          .get(`Company/SearchRegisteredCompanies/${searchTerm}`)
          .then(({ data }) => {
            // const res = [...new Set(items.map((item) => item.entityName))].sort();
            setNzbnEntities(data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }, 600);
  };

  const convertEmailsCcToArray = (emailsString) => {
    if (emailsString) {
      const emailsArray = emailsString.split(";");
      if (emailsArray && emailsArray.length > 0) {
        return emailsArray;
      }
    }
    return [];
  };

  return (
    <div>
      <div className="accounting-card-location-container">
        <Autocomplete
          disabled={isNzme}
          value={
            locations.find((l) => l.id === company?.companyLocation) || {
              id: "NEW_ZEALAND",
              name: "New Zealand",
            }
          }
          options={locations}
          onChange={(event, newValue) => {
            handleOnChangeCompany("companyLocation", newValue?.id);
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Company Location"
              size="small"
              fullWidth={true}
              variant="filled"
            />
          )}
        />
        {company.companyLocation && company.companyLocation !== "NEW_ZEALAND" && (
          <>
            <div>
              <Autocomplete
                disabled={isNzme}
                value={
                  currencies.find((l) => l.id === company?.currency) || {
                    id: "NZD",
                    name: "",
                  }
                }
                options={currencies}
                onChange={(event, newValue) => {
                  handleOnChangeCompany("currency", newValue?.id);
                }}
                getOptionLabel={(option) => `${option.id} ${option.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Currency (The currency used for invoices)"
                    size="small"
                    fullWidth={true}
                    variant="filled"
                  />
                )}
              />
            </div>
            {company.companyLocation !== "INTERNATIONAL" && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Exclude GST</Typography>
                <Switch
                  color="primary"
                  onChange={(e) =>
                    handleOnChangeCompany("gstregistered", e.target.checked)
                  }
                  checked={company.gstregistered}
                />
                <Typography>Include GST</Typography>
              </Stack>
            )}
          </>
        )}
      </div>
      <div className="accounting-card-register-container">
        <div className="accounting-card-register-lookup-container">
          {company?.companyLocation !== "INTERNATIONAL" ?
            <Autocomplete
              disabled={isNzme}
              className="accounting-card-register-lookup-input"
              id="nzbn-dropdown-company"
              //disableClearable
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.entityName
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue: inputValue?.toUpperCase(),
                    addLabel: `Can't find it? - Add "${inputValue?.toUpperCase()}"`,
                  });
                }

                return filtered;
              }}
              //filterOptions={(x) => x} //https://mui.com/components/autocomplete/#search-as-you-type
              fullWidth
              options={nzbnEntities}
              getOptionLabel={(entity) =>
                entity?.entityName
                  ? entity.entityName
                  : company?.registeredName
                    ? company.registeredName
                    : entity?.addLabel
                      ? entity.addLabel
                      : ""
              }
              variant="outlined"
              size="small"
              value={company?.registeredName}
              onChange={(event, newValue) => {
                if (newValue && newValue.inputValue) {
                  handleOnChangeCompany("registeredName", newValue?.inputValue);
                } else {
                  setNzbnEntities(
                    newValue ? [newValue, ...nzbnEntities] : nzbnEntities
                  );
                  handleOnChangeCompany("registeredName", newValue?.entityName);
                }
              }}
              onInputChange={(event, newInputValue) => {
                getNzbnDropdownList(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Search on Company Register"}
                  variant="filled"
                  fullWidth
                  error={errorForm.registeredName ? true : false}
                  helperText={
                    errorForm.registeredName ? errorForm.registeredName : null
                  }
                />
              )}
            /> : <TextField
              label={"Search on Company Register"}
              variant="filled"
              fullWidth
              error={errorForm.registeredName ? true : false}
              helperText={
                errorForm.registeredName ? errorForm.registeredName : null
              }
              value={company?.registeredName}
              onChange={(e) => {
                handleOnChangeCompany("registeredName", e.target.value);
              }}
            />}
          <div className="help-tooltip-icon">
            <Tooltip
              className="tooltip-css"
              title={company?.companyLocation !== "INTERNATIONAL" ?
                "Please search and select from NZ/AU company register" :
                "Please type in the companies registered name"}
              placement="top"
              arrow
            >
              <Help />
            </Tooltip>
          </div>
        </div>

        {company?.companyLocation !== "INTERNATIONAL" ? (
          <GoogleMapsPlaces
            isNzme={isNzme}
            parent="AccountingCard"
            address={company?.address}
            updateAddress={handleOnChangeCompany}
            error={errorForm.address}
          />
        ) : (
          <TextField
            disabled={isNzme}
            label="What is their street address?"
            fullWidth
            variant="filled"
            error={errorForm.address ? true : false}
            helperText={errorForm.address ? errorForm.address : null}
            value={company?.address}
            onChange={(e) => {
              handleOnChangeCompany("address", e.target.value);
            }}
          />
        )}

        <SpellCheckTextField
          id="accounts-email"
          label="Primary Accounts Email (Optional)"
          size="small"
          fullWidth={true}
          value={company?.primaryAccountsEmail}
          error={errorForm.primaryAccountsEmail}
          errorMessage={errorForm.primaryAccountsEmail}
          handleOnChange={(newValue) =>
            handleOnChangeCompany("primaryAccountsEmail", newValue)
          }
          disabled={isNzme}
        />

        <Autocomplete
          multiple
          freeSolo
          size="small"
          id="tags-cc"
          options={[]}
          value={convertEmailsCcToArray(company?.accountsEmailsCc)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Enter Cc for emails"
              placeholder="Type and press enter to insert."
            />
          )}
          onChange={(e, value) => {
            handleOnChangeCompany("accountsEmailsCc", value.join(";"));
          }}
        />

        <TextField
          type="number"
          label="Media Booking Fee %"
          variant="filled"
          value={company?.mediaAdminFee ? removeLeadingZeros(company?.mediaAdminFee) : 15}
          size="small"
          placeholder="Commission for media spend."
          onChange={(e) =>
            handleOnChangeCompany("mediaAdminFee", Number(e.target.value || 15))
          }
        />


        {selectedOrganisation?.id !== 1 && company?.id && (
          <Autocomplete
            value={trackingOption}
            options={trackingCategories?.Options}
            onChange={async (event, newValue) => {
              setTrackingOption(
                trackingCategories?.Options?.find(
                  (t) => t.TrackingOptionID === newValue?.TrackingOptionID
                )
              );

              await handleOnChangeCompany("xeroTrackingOption", {
                trackingOption: newValue?.TrackingOptionID || null,
                trackingCategory:
                  trackingCategories?.TrackingCategoryID || null,
              });
            }}
            getOptionLabel={(option) => option.Name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Business Unit (Xero Tracking Option)"
                size="small"
                autoComplete="off"
                fullWidth={true}
                variant="filled"
              />
            )}
          />
        )}

        <div className="accounting-card-location-payment-terms-div">
          <div className="accounting-card-location-payment-terms-title">
            <Typography>Payment Terms:</Typography>
            <div>
              <Tooltip
                className="tooltip-css"
                title="This will be set for the invoice"
                placement="top"
                arrow
                aria-label="add"
              >
                <Help />
              </Tooltip>
            </div>
          </div>
          <div id="checkboxes-payment-day">
            <FormGroup aria-label="position">
              <FormControlLabel
                value="20th month following"
                control={
                  <Checkbox
                    color="primary"
                    checked={company.paymentTerms == "20"}
                    onChange={(e) =>
                      handleOnChangeCompany("paymentTerms", "20")
                    }
                  />
                }
                label="20th of month following"
                labelPlacement="left"
              />
              <FormControlLabel
                value="30th"
                control={
                  <Checkbox
                    color="primary"
                    checked={company.paymentTerms == "30"}
                    onChange={(e) =>
                      handleOnChangeCompany("paymentTerms", "30")
                    }
                  />
                }
                label="30th of month following"
                labelPlacement="left"
              />
              {/* {user?.role !== "Editor" && (
                <>
                  <FormControlLabel
                    value="60 days"
                    control={
                      <Checkbox
                        color="primary"
                        checked={company.paymentTerms == "60_DAYS"}
                        onChange={(e) =>
                          handleOnChangeCompany("paymentTerms", "60_DAYS")
                        }
                      />
                    }
                    label="60 days post invoice"
                    labelPlacement="left"
                  />
                  <FormControlLabel
                    value="60 days"
                    control={
                      <Checkbox
                        color="primary"
                        checked={company.paymentTerms == "90_DAYS"}
                        onChange={(e) =>
                          handleOnChangeCompany("paymentTerms", "90_DAYS")
                        }
                      />
                    }
                    label="90 days post invoice"
                    labelPlacement="left"
                  />
                </>
              )} */}
            </FormGroup>
          </div>
        </div>
        <Stack direction="row" spacing={1} alignItems="center">
          <Switch
            color="primary"
            onChange={(e) =>
              handleOnChangeCompany("forcedPo", e.target.checked)
            }
            checked={company?.forcedPo}
          />
          <Typography>
            Does this company need purchase orders to pay us?
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <Switch
            color="primary"
            onChange={(e) =>
              handleOnChangeCompany("includeContactsOnEmails", e.target.checked)
            }
            checked={company?.includeContactsOnEmails}
          />
          <Typography>
            Include contacts on invoice emails.
          </Typography>
        </Stack>
        {(user.role === "Admin" || user.role === "Super User") && company?.id && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Switch
              color="primary"
              onChange={(e) =>
                handleOnChangeCompany("isInternal", e.target.checked)
              }
              checked={company?.isInternal}
            />
            <Typography>
              Is this an internal company?
            </Typography>
          </Stack>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {})(AccountingCard);

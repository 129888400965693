import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@mui/material";

const ConfirmationDialog = (props) => {
  const {
    loading = false
  } = props;
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            props.handleClose()
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        {loading ? (
          <DialogActions style={{padding: 10}}>
            <CircularProgress />
          </DialogActions>
        ) : (
          <DialogActions>
            <Button className="default-button" variant="contained" onClick={props.handleClose}>Cancel</Button>
            <Button className="default-button" variant="contained" onClick={props.handleSubmit} autoFocus>
              {props.submitLabel}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;

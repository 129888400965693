import Close from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import ProcessingSpinner from "../Shared/ProcessingSpinner";
import EditSingleFieldDialog from "../Shared/EditSingleFieldDialog";
import EditIcon from "@mui/icons-material/Edit";
import { getCurrencyObject } from "../../Utils/ExchangeRateUtils";

const SendInvoiceDialog = (props) => {
  let { xeroInvoice, invoice } = props;
  const [loading, setLoading] = useState(false);
  const [openEditSingleFieldDialog, setOpenEditSingleFieldDialog] =
    useState(false);
  const [fieldProperties, setFieldProperties] = useState({
    label: "Invoice Date",
    value: null,
    inputType: "date",
    xeroField: "Date",
    invoiceField: "date",
  });
  const [currentLocale, setCurrentLocale] = useState({
    value: "NZD",
    code: "NZ",
    symbol: "$",
    locale: "en-NZ",
  });

  const sendInvoice = () => {
    if (validateInvoiceDates()) {
      setLoading(true);
      props.handleConfirmSendInvoice();
    }
  };

  const validateInvoiceDates = () => {
    if (!xeroInvoice.Date || !xeroInvoice.DueDate) {
      alert("Empty Dates! Please check invoice date and due date.");
      return false;
    } else if (dayjs(xeroInvoice.DueDate).isBefore(dayjs(xeroInvoice.Date))) {
      alert("Invalid Due Date! Due Date cannot be before Invoice Date.");
      return false;
    }
    return true;
  };

  const handleCloseEditSingleFieldDialog = () => {
    setOpenEditSingleFieldDialog(false);
  };

  const handleSaveEditSingleField = async (inputValue) => {
    let xeroInvoiceClone = { ...xeroInvoice };
    let invoiceClone = { ...invoice };
    xeroInvoiceClone[fieldProperties.xeroField] = inputValue;
    invoiceClone[fieldProperties.invoiceField] = inputValue;
    await props.handleInvoiceChanges(xeroInvoiceClone, invoiceClone);
    handleCloseEditSingleFieldDialog();
  };

  const handleOpenEditSingleFieldDialog = (properties) => {
    setFieldProperties(properties);
    setOpenEditSingleFieldDialog(true);
  };

  useEffect(() => {
    if (invoice) {
      var currency = getCurrencyObject(invoice.currency);
      setCurrentLocale(currency);
    }
  }, [invoice]);

  return (
    <div>
      {openEditSingleFieldDialog && (
        <EditSingleFieldDialog
          open={openEditSingleFieldDialog}
          handleClose={handleCloseEditSingleFieldDialog}
          handleSaveEdit={handleSaveEditSingleField}
          fieldProperties={fieldProperties}
        />
      )}
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title-contact"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className="sales-dialog-title">
          Send Invoice
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <ProcessingSpinner duration={60} unitTime={"seconds"} />
          ) : (
            <Fragment>
              <Grid container direction="row" justifyContent="space-evenly">
                <Grid item>
                  <div>
                    <Typography>{`${xeroInvoice?.Contact?.Name}`}</Typography>
                    <Typography>{`${invoice?.contact?.firstName} ${invoice?.contact?.lastName}`}</Typography>
                    <Typography>{`${invoice?.contact?.emailAddress}`}</Typography>
                  </div>
                </Grid>
                <Grid item>
                  <div>
                    <div style={{ display: "flex" }}>
                      <Typography>{`Invoice Date: ${dayjs(
                        xeroInvoice?.Date
                      ).format("DD/MM/YYYY")} `}</Typography>
                      <IconButton
                        size="small"
                        style={{ padding: "0 5px", marginBottom: 3 }}
                        onClick={(e) => {
                          handleOpenEditSingleFieldDialog({
                            label: "Invoice Date",
                            value: xeroInvoice?.Date,
                            inputType: "date",
                            xeroField: "Date",
                            invoiceField: "date",
                          });
                        }}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Typography>{`Due Date: ${dayjs(
                        xeroInvoice?.DueDate
                      ).format("DD/MM/YYYY")}`}</Typography>

                      <IconButton
                        size="small"
                        style={{ padding: "0 5px", marginBottom: 3 }}
                        onClick={(e) => {
                          handleOpenEditSingleFieldDialog({
                            label: "Due Date",
                            value: xeroInvoice?.DueDate,
                            inputType: "date",
                            xeroField: "DueDate",
                            invoiceField: "dueDate",
                          });
                        }}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Typography>{`PO Number: ${xeroInvoice?.Reference}`}</Typography>
                      <IconButton
                        size="small"
                        style={{ padding: "0 5px", marginBottom: 3 }}
                        onClick={(e) => {
                          handleOpenEditSingleFieldDialog({
                            label: "PO Number",
                            value: xeroInvoice?.Reference,
                            inputType: "text",
                            xeroField: "Reference",
                            invoiceField: "poNumber",
                          });
                        }}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                style={{ marginTop: 15 }}
              >
                <Grid item>
                  <div className="table-responsive">
                    <table
                      className="table table-striped table-hover mb-0"
                      style={{ tableLayout: "fixed", width: "100%" }}
                    >
                      <thead className="thead-magnesium">
                        <tr>
                          <th className="text-center" style={{ width: "50%" }}>
                            Description
                          </th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center">Unit Amount</th>
                          <th className="text-center">Account Code</th>
                          <th className="text-center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {xeroInvoice?.LineItems.map((item, index) => (
                          <tr key={index}>
                            <td
                              className="text-center"
                              style={{ wordWrap: "break-word" }}
                            >
                              {item.Description}
                            </td>
                            <td className="text-center">{item.Quantity}</td>
                            <td className="text-center">{item.UnitAmount}</td>
                            <td className="text-center">{item.AccountCode}</td>
                            <td className="text-center">
                              {(
                                (item.Quantity || 0) * (item.UnitAmount || 0)
                              ).toLocaleString(currentLocale.locale, {
                                style: "currency",
                                currency: currentLocale.value,
                                minimumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        ))}
                        <tr key={`totalLine`}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <Typography variant="button">Total</Typography>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {xeroInvoice?.LineItems?.reduce(
                              (a, b) =>
                                a + (b.Quantity || 0) * (b.UnitAmount || 0),
                              0
                            ).toLocaleString(currentLocale.locale, {
                              style: "currency",
                              currency: currentLocale.value,
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Button
              variant="contained"
              onClick={props.handleClose}
              className="default-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={xeroInvoice == null ? "" : "default-button"}
              disabled={xeroInvoice == null}
              onClick={sendInvoice}
            >
              Send
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default SendInvoiceDialog;

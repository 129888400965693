import React from 'react'
import "./totalCard.scss"

const TotalCard = ({label, value}) => {
  return (
    <div className='total-card-root'>
        <div className='total-card-label'>{label}</div>
        <div className='total-card-value'>{value}</div>
    </div>
  )
}

export default TotalCard
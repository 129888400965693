import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    CircularProgress,
    Autocomplete
} from "@mui/material";
import { connect } from "react-redux";
import axios from "axios";

const MarginExemptionEditPopup = (props) => {
    const {
        loading = false,
        open,
        handleClose,
        handleCloseAndRefresh,
        companies,
        products,
        setLoading
    } = props;

    const [client, setClient] = useState(null);
    const [product, setProduct] = useState(null);
    const [newMarkup, setNewMarkup] = useState(0);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const today = new Date();
        var month = today.getMonth();
        month = month === 13 ? 1 : (month+1);
        var zerofilledMonth = ('00' + month).slice(-2);
        var zerofilledDate = ('00' + today.getDate()).slice(-2);
        setEndDate(`${today.getFullYear()}-${zerofilledMonth}-${zerofilledDate}`);
    }, [])

    const handleSave = () => {
        setLoading(true);
        axios.post(`MarginExemption/NewExemption`, {
            productId: product.id,
            clientId: client.id,
            newLimit: newMarkup,
            endDate: endDate,
            status: status
        })
            .then(res => {
                handleCloseAndRefresh();
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={(e, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose()
                    }
                }}
                classes={{ paper: "confirmation-with-input-dialog" }}
            >
                <DialogTitle>Edit Exemption</DialogTitle>
                <DialogContent>
                    <div style={{ marginTop: 5 }}>
                        <Autocomplete
                            value={client}
                            options={companies}
                            onChange={(event, option) => {
                                setClient(option);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Client"}
                                    size="small"
                                    fullWidth={true}
                                    variant="filled"
                                />
                            )}
                            disableClearable
                        />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <Autocomplete
                            value={product}
                            options={products.filter(p => p.rateType === "Open Budget")}
                            onChange={(event, option) => {
                                setProduct(option);
                            }}
                            getOptionLabel={(option) => `${option.type} - ${option.name} (${option.rateType})`}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Product"}
                                    size="small"
                                    fullWidth={true}
                                    variant="filled"
                                />
                            )}
                            disableClearable
                        />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <Autocomplete
                            value={status}
                            options={["PENDING", "APPROVED", "CANCELLED"]}
                            onChange={(event, option) => {
                                setStatus(option);
                            }}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Status"}
                                    size="small"
                                    fullWidth={true}
                                    variant="filled"
                                />
                            )}
                            disableClearable
                        />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <TextField
                            id="name"
                            label={"New Markup %"}
                            type="number"
                            fullWidth
                            variant="filled"
                            value={newMarkup}
                            onChange={(e) => {
                                if (parseFloat(e.target.value) <= 100 || e.target.value === "") {
                                    setNewMarkup(e.target.value)
                                }
                            }}
                        />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        {endDate && (
                            <TextField
                                id="name"
                                label={"End Date"}
                                type="date"
                                fullWidth
                                variant="filled"
                                value={endDate}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    setEndDate(e.target.value)
                                }}
                            />
                        )}
                    </div>
                </DialogContent>
                {loading ? (
                    <DialogActions>
                        <CircularProgress />
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button disabled={loading} className="default-button" variant="contained" onClick={() => handleClose()}>Cancel</Button>
                        <Button disabled={loading} className="default-button" variant="contained" onClick={() => handleSave()}>Save</Button>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => ({
    selectedOrganisation: state.organisation.selectedOrganisation,
    companies: state.company.companies,
    products: state.product.products
});

export default connect(mapStateToProps, {

})(MarginExemptionEditPopup);

import {
  Autocomplete,
  IconButton,
  TextField,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
  Badge,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import MarginDisplay from "../Shared/MarginDisplay";
import TotalDisplay from "../Shared/TotalDisplay";
import {
  getCategoryTypeByName,
  removeLeadingZeros,
} from "../../Utils/JobHelpers";
import MarginExemptionRequestDialog from "./socialites/MarginExemption/MarginExemptionRequestDialog";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import Snack from "../Shared/Snack";
import { checkIfValidMiscJobLine } from "../../Utils/JobHelpers";
import {
  convertToPositive,
  currencySymbolFormat,
} from "../../Utils/GeneralUtils";

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging && "#efeff7",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const showHubAmount = (jobLine) => {
  switch (jobLine?.taskType) {
    case "E":
    case "ECT":
    case "ELF":
    case "E-INFLUENCER":
    case "E-INFL-MEDIA":
      return true;
    default:
      return false;
  }
}

const HubAmountComponent = ({
  jobLine
}) => {
  var value = jobLine.costPrice * jobLine.quantity;
  if (jobLine.rateType === "Open Budget") {
    if (jobLine.calculationMethod === "Markup") {
      value = jobLine.sellPrice;
    } else if (jobLine.calculationMethod === "Margin") {
      value = jobLine.sellPrice - jobLine.margin;
    }
  } else {
    value = jobLine.costPrice * jobLine.quantity;
  }

  if (showHubAmount(jobLine)) {
    return (
      <Typography style={{ width: 50 }}>
        <span className="caption-text">
          {`Hub: ${currencySymbolFormat(convertToPositive(value))}`}
        </span>
      </Typography>
    )
  } else {
    return <></>;
  }
}

const JobLinesTableRow = (props) => {
  const { jobLine, currency } = props;
  const [contextMenu, setContextMenu] = React.useState(null);
  const [showMarginExemptionDialog, setShowMarginExemptionDialog] =
    React.useState(false);
  const [showSaveAsDraftWarning, setShowSaveAsDraftWarning] =
    React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("info");
  const [snackText, setSnackText] = React.useState(false);

  console.log("jobLine.taskType")
  console.log(jobLine.taskType)

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
        // Other native context menus might behave different.
        // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null
    );
  };

  const handleMenuClick = async (value) => {
    await props.handleOnChangeJobLine(jobLine.guid, "isPassThrough", value);
    if (value === 0) {
      const product = props.products.find((p) => p.id === jobLine.productId);
      if (product) {
        props.handleOnChangeJobLine(
          jobLine.guid,
          "minSellPrice",
          product.minSellPrice
        );
        if (jobLine.sellPrice < product.minSellPrice) {
          props.handleOnChangeJobLine(
            jobLine.guid,
            "sellPrice",
            product.minSellPrice
          );
        }
      }
    } else {
      if (jobLine.rateType === "Open Budget") {
        props.handleOnChangeJobLine(jobLine.guid, "minSellPrice", 1);
      } else {
        props.handleOnChangeJobLine(
          jobLine.guid,
          "minSellPrice",
          jobLine.costPrice
        );
      }
    }
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setContextMenu(null);
  };

  const validateSellPrice = (jobLine) => {
    if (
      jobLine.rateType !== "Open Budget" &&
      jobLine.sellPrice < jobLine.minSellPrice
    ) {
      alert(
        `Please review the price. It should be more than $${jobLine.minSellPrice}!`
      );
      props.handleOnChangeJobLine(
        jobLine.guid,
        "sellPrice",
        Number(jobLine.minSellPrice)
      );
    } else if (
      jobLine.rateType === "Open Budget" &&
      jobLine.costPrice < jobLine.minSellPrice
    ) {
      alert(
        `Please review the cost. Multiplier cannot be less than ${jobLine.minSellPrice}!`
      );
      props.handleOnChangeJobLine(
        jobLine.guid,
        "costPrice",
        Number(jobLine.minSellPrice)
      );
    }
  };

  const getAvailableRateTypeOptions = (productName) => {
    let rateTypeOptions = [
      "Hour",
      "Day",
      "Half Day",
      "Open Budget",
      "Per Person",
    ];
    if (productName) {
      const ratesFromProducts = props.products
        ?.filter((p) => p.name?.trim() === productName?.trim())
        ?.map((p) => p?.rateType);
      if (
        ratesFromProducts &&
        ratesFromProducts.length > 0 &&
        ratesFromProducts[0]
      ) {
        rateTypeOptions = rateTypeOptions.filter((option) =>
          ratesFromProducts.includes(option)
        );
      }
    }
    return rateTypeOptions;
  };

  const combineProductsWithSameName = (allProducts) => {
    const allNames = allProducts.map((p) => p.name);
    return allProducts.filter(
      ({ name, type }, index) => type && !allNames.includes(name, index + 1)
    );
  };

  const saveDraftJob = async () => {
    await props.forceSaveDraftJob();
  };

  return (
    <div>
      <Snack
        open={openSnack}
        setOpen={setOpenSnack}
        severity={snackSeverity}
        text={snackText}
      />
      {showMarginExemptionDialog && (
        <MarginExemptionRequestDialog
          open={showMarginExemptionDialog}
          handleClose={() => setShowMarginExemptionDialog(false)}
          jobLine={jobLine}
          clientId={props.jobOrganisation}
          setSnackText={setSnackText}
          setSnackSeverity={setSnackSeverity}
          setOpenSnack={setOpenSnack}
          handleOnChangeJobLine={props.handleOnChangeJobLine}
        />
      )}

      {showSaveAsDraftWarning && (
        <ConfirmationDialog
          open={showSaveAsDraftWarning}
          handleClose={() => setShowSaveAsDraftWarning(false)}
          submitLabel={"Confirm"}
          title={"Please save as draft"}
          description={
            "Please save as draft before requesting a new minimum threshold"
          }
          handleSubmit={() => setShowSaveAsDraftWarning(false)}
        />
      )}
      {props.jobOrganisation !== 1 && (
        <div
          className="socialites-job-lines-table-row"
          onContextMenu={handleContextMenu}
        >
          <div className="jobline-cell-div-container-display"></div>

          <div className="jobline-cell-div-container">
            <Autocomplete
              id="products-options"
              disabled={props.isReadOnly}
              options={combineProductsWithSameName(
                props.products
                  .filter((p) => {
                    return (
                      getCategoryTypeByName(p.type, props.products) ===
                      props.category.type
                    );

                    // if (props.jobOrganisation === 1) {
                    //   return props.category.name.includes("Filming")
                    //     ? p.type === "Filming"
                    //     : p.type === props.category.type;
                    // } else {
                    //   return (
                    //     getCategoryTypeByName(p.type, props.products) ===
                    //     props.category.type
                    //   );
                    // }
                  })
                  // .sort((a, b) => -b.type.localeCompare(a.type))
                  .sort((a, b) => {
                    if (a.displayOrder < b.displayOrder) {
                      return -1;
                    }
                    if (a.displayOrder > b.displayOrder) {
                      return 1;
                    }
                    // a must be equal to b
                    return 0;
                  })
              )}
              value={jobLine.name}
              onChange={(event, newValue) => {
                props.handleChangeProduct(jobLine.guid, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Resource"
                  size="small"
                />
              )}
              // freeSolo
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.title;
                }
                // Regular option
                return (
                  option.name +
                  `${option?.rateType ? " (" + option.rateType + ")" : ""}`
                );
              }}
            />
          </div>

          <div className="jobline-cell-div-container">
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="h6" color="inherit">
                    Invalid item
                  </Typography>
                  <Typography variant="body2">
                    <i>
                      <b>{"Miscellaneous "}</b>
                    </i>
                    {"and "}
                    <i>
                      <b>{"Influencer - Expenses & Reimbursements "}</b>
                    </i>
                    {"should not be used for "}
                    <b>{"Production, "}</b>
                    <b>{"Media "}</b>
                    {"or "}
                    <b>{"Influencer. "}</b>{" "}
                    {"Please create a separate block to bill this item."}
                  </Typography>
                </React.Fragment>
              }
            >
              <Badge
                invisible={!checkIfValidMiscJobLine(jobLine)}
                color="error"
                badgeContent={"!"}
              />
            </Tooltip>
            <TextField
              key={"description" + jobLine.guid}
              disabled={props.isReadOnly}
              id="description"
              label="Description"
              variant="filled"
              fullWidth={true}
              multiline
              // rows={jobDesctiptionRowCount}
              value={jobLine.description || ""}
              size="small"
              onChange={(e) =>
                props.handleOnChangeJobLine(
                  jobLine.guid,
                  "description",
                  e.target.value
                )
              }
              error={checkIfValidMiscJobLine(jobLine)}
              // onError={() => {
              //   props.setShowMiscPopup()
              // }}
              className={
                checkIfValidMiscJobLine(jobLine) ? "highlight-border-red" : ""
              }
            />
          </div>

          <div className="jobline-cell-div-container">
            {jobLine && jobLine.rateType === "Open Budget" ? (
              <>
                <TextField
                  type="number"
                  disabled={props.isReadOnly}
                  id="cost"
                  label="Budget"
                  variant="filled"
                  error={!jobLine.sellPrice || jobLine.sellPrice === 0}
                  fullWidth={true}
                  value={
                    jobLine.sellPrice
                      ? removeLeadingZeros(jobLine.sellPrice)
                      : 0
                  }
                  size="small"
                  onBlur={(e) => {
                    validateSellPrice(jobLine);
                  }}
                  onChange={(e) =>
                    props.handleOnChangeJobLine(
                      jobLine.guid,
                      "sellPrice",
                      Number(e.target.value)
                    )
                  }
                />
                <HubAmountComponent jobLine={jobLine}/>
              </>
            ) : (
              <>
                <TextField
                  type="number"
                  disabled={
                    props.isReadOnly ||
                    (jobLine && jobLine.rateType === "Open Budget")
                  }
                  id="quantity"
                  label="Quantity"
                  variant="filled"
                  fullWidth={true}
                  //   rows={jobDesctiptionRowCount}
                  value={removeLeadingZeros(jobLine.quantity)}
                  size="small"
                  onChange={(e) =>
                    props.handleOnChangeJobLine(
                      jobLine.guid,
                      "quantity",
                      Number(e.target.value)
                    )
                  }
                />
                <HubAmountComponent jobLine={jobLine}/>
              </>
            )}
          </div>

          {!props.category?.type.includes("Media Spend") ? (
            <div className="jobline-cell-div-container">
              <TextField
                type="number"
                id="cost-price"
                label={
                  jobLine && jobLine.rateType === "Open Budget"
                    ? `Min ${jobLine.calculationMethod} %`
                    : "Min Price"
                }
                variant="filled"
                fullWidth={true}
                value={removeLeadingZeros(jobLine.minSellPrice)}
                disabled
                size="small"
              />
              {!props.isReadOnly &&
                jobLine &&
                jobLine.rateType === "Open Budget" && (
                  <Typography style={{ width: 50 }}>
                    <span
                      className="edit-markup-text"
                      onClick={(e) => {
                        if (!jobLine.jobId) {
                          //setShowSaveAsDraftWarning(true);
                          saveDraftJob();
                          setShowMarginExemptionDialog(true);
                        } else if (
                          jobLine &&
                          jobLine.rateType === "Open Budget"
                        ) {
                          setShowMarginExemptionDialog(true);
                        }
                      }}
                    >
                      {`Edit ${jobLine.calculationMethod} Here`}
                    </span>
                  </Typography>
                )}
            </div>
          ) : (
            <div className="jobline-cell-div-container"></div>
          )}

          {!props.category?.type.includes("Media Spend") ? (
            jobLine && jobLine.rateType === "Open Budget" ? (
              <div className="jobline-cell-div-container">
                <TextField
                  label={`${jobLine.calculationMethod} %`}
                  type="number"
                  disabled={
                    props.isReadOnly ||
                    props.category?.type.includes("Media Spend")
                  }
                  value={removeLeadingZeros(jobLine.costPrice)}
                  size="small"
                  variant="filled"
                  fullWidth={true}
                  onBlur={(e) => {
                    validateSellPrice(jobLine);
                  }}
                  onChange={(e) => {
                    props.handleOnChangeJobLine(
                      jobLine.guid,
                      "costPrice",
                      Number(e.target.value)
                    );
                  }}
                />
              </div>
            ) : (
              <div className="jobline-cell-div-container">
                <TextField
                  type="number"
                  disabled={props.isReadOnly}
                  id="cost"
                  label="Price"
                  variant="filled"
                  fullWidth={true}
                  value={
                    jobLine.sellPrice
                      ? removeLeadingZeros(jobLine.sellPrice)
                      : 0
                  }
                  error={!jobLine.sellPrice || jobLine.sellPrice === 0}
                  size="small"
                  onBlur={(e) => {
                    validateSellPrice(jobLine);
                  }}
                  onChange={(e) =>
                    props.handleOnChangeJobLine(
                      jobLine.guid,
                      "sellPrice",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
            )
          ) : (
            <div className="jobline-cell-div-container"></div>
          )}
          <div className={"jobline-cell-div-container-display"}>
            {/* style={{ textAlign: "end" }} */}
            <div style={{ height: 48 }}>
              <TotalDisplay jobLine={jobLine} />
            </div>
          </div>
          <div className="jobline-cell-div-container-display">
            {/* style={{ textAlign: "end" }} */}
            {/* <MarginDisplay total={jobLine.total} margin={jobLine.margin} /> */}
          </div>
          <div className="jobline-cell-div-container-icon">
            <IconButton
              edge="end"
              aria-label="delete"
              className="opacity-icon"
              style={{
                marginRight: 5,
                visibility: props.isReadOnly ? "hidden" : "visible",
              }}
              onClick={(e) => {
                props.handleDeleteJobLine(jobLine);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <Typography
              variant="caption"
              className="itemised"
              style={{
                display: jobLine.isPassThrough > 0 ? "block" : "none",
              }}
            >
              {jobLine.isPassThrough === 1 ? "itemised" : "pass through"}
            </Typography>
          </div>
          {jobLine.allowPassThrough === 1 && !props.isReadOnly && (
            <Menu
              open={contextMenu !== null}
              onClose={handleCloseMenu}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              {jobLine.isPassThrough ? (
                <MenuItem
                  onClick={(e) => {
                    handleMenuClick(0);
                  }}
                >
                  Remove Pass Through
                </MenuItem>
              ) : (
                <>
                  <MenuItem
                    onClick={(e) => {
                      handleMenuClick(2);
                    }}
                  >
                    {"Pass Through"}
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      handleMenuClick(1);
                    }}
                  >
                    {"Pass Through & Itemise"}
                  </MenuItem>
                </>
              )}
            </Menu>
          )}
          {jobLine.allowPassThrough === 0 && !props.isReadOnly && (
            <Menu
              open={contextMenu !== null}
              onClose={handleCloseMenu}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              <MenuItem onClick={handleCloseMenu}>
                Product doesn't allow pass through
              </MenuItem>
            </Menu>
          )}
        </div>
      )}

      {props.jobOrganisation === 1 && (
        <Draggable
          key={jobLine.guid}
          draggableId={jobLine.guid}
          index={props.index}
        >
          {(provided, snapshot) => (
            <div
              className="job-lines-table-row"
              key={jobLine.guid}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
              onContextMenu={handleContextMenu}
            >
              <div></div>
              <div>
                <Autocomplete
                  fullWidth
                  id="products-options"
                  disabled={props.isReadOnly}
                  options={combineProductsWithSameName(
                    props.products
                      .filter((p) => {
                        if (props.jobOrganisation === 1) {
                          return props.category.name.includes("Filming")
                            ? p.type === "Filming"
                            : p.type === props.category.name;
                        } else {
                          return (
                            getCategoryTypeByName(p.type, props.products) ===
                            props.category.type
                          );
                        }
                      })
                      .sort((a, b) => -b.type.localeCompare(a.type))
                  )}
                  value={jobLine.name}
                  onChange={(event, newValue) => {
                    props.handleChangeProduct(jobLine.guid, newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Resource"
                      size="small"
                    />
                  )}
                  // freeSolo
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.title;
                    }
                    // Regular option
                    return (
                      option.name +
                      `${option?.rateType ? " (" + option.rateType + ")" : ""}`
                    );
                  }}
                />
              </div>
              <div>
                <Tooltip
                  className="tooltip-css"
                  title="Rate Type"
                  placement="top"
                  arrow
                >
                  <Autocomplete
                    disableClearable
                    disabled={props.isReadOnly}
                    value={jobLine.rateType}
                    options={getAvailableRateTypeOptions(jobLine?.name)}
                    onChange={(event, newValue) => {
                      props.handleChangeProductRateType(
                        jobLine.guid,
                        jobLine?.name,
                        newValue,
                        props.category.name
                      );
                      // props.handleOnChangeJobLine(
                      //   jobLine.guid,
                      //   "rateType",
                      //   newValue
                      // );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Rate"
                        size="small"
                        fullWidth={true}
                        variant="filled"
                      />
                    )}
                  />
                </Tooltip>
              </div>

              <div>
                <TextField
                  key={"description" + jobLine.guid}
                  disabled={props.isReadOnly}
                  id="description"
                  label="Description"
                  variant="filled"
                  fullWidth={true}
                  multiline
                  // rows={jobDesctiptionRowCount}
                  value={jobLine.description || ""}
                  size="small"
                  onChange={(e) =>
                    props.handleOnChangeJobLine(
                      jobLine.guid,
                      "description",
                      e.target.value
                    )
                  }
                />
              </div>

              <div>
                {jobLine && jobLine.rateType === "Open Budget" ? (
                  <TextField
                    type="number"
                    disabled={props.isReadOnly}
                    id="cost"
                    label="Cost Price"
                    variant="filled"
                    error={!jobLine.sellPrice || jobLine.sellPrice === 0}
                    fullWidth={true}
                    value={
                      jobLine.sellPrice
                        ? removeLeadingZeros(jobLine.sellPrice)
                        : 0
                    }
                    size="small"
                    onBlur={(e) => {
                      validateSellPrice(jobLine);
                    }}
                    onChange={(e) =>
                      props.handleOnChangeJobLine(
                        jobLine.guid,
                        "sellPrice",
                        Number(e.target.value)
                      )
                    }
                  />
                ) : (
                  <TextField
                    type="number"
                    disabled={
                      props.isReadOnly ||
                      (jobLine && jobLine.rateType === "Open Budget")
                    }
                    id="quantity"
                    label="Quantity"
                    variant="filled"
                    fullWidth={true}
                    //   rows={jobDesctiptionRowCount}
                    value={removeLeadingZeros(jobLine.quantity)}
                    size="small"
                    onChange={(e) =>
                      props.handleOnChangeJobLine(
                        jobLine.guid,
                        "quantity",
                        Number(e.target.value)
                      )
                    }
                  />
                )}
              </div>

              <div>
                <TextField
                  type="number"
                  id="cost-price"
                  label={
                    jobLine && jobLine.rateType === "Open Budget"
                      ? "Min Multiplier"
                      : "Min Price"
                  }
                  variant="filled"
                  fullWidth={true}
                  value={removeLeadingZeros(jobLine.minSellPrice)}
                  disabled
                  size="small"
                />
              </div>

              {jobLine && jobLine.rateType === "Open Budget" ? (
                <div>
                  <TextField
                    label="Multiplier"
                    type="number"
                    disabled={props.isReadOnly}
                    value={removeLeadingZeros(jobLine.costPrice)}
                    size="small"
                    variant="filled"
                    fullWidth={true}
                    onBlur={(e) => {
                      validateSellPrice(jobLine);
                    }}
                    onChange={(e) => {
                      props.handleOnChangeJobLine(
                        jobLine.guid,
                        "costPrice",
                        Number(e.target.value)
                      );
                    }}
                  />
                </div>
              ) : (
                <div width="6%">
                  <TextField
                    type="number"
                    disabled={removeLeadingZeros(props.isReadOnly)}
                    id="cost"
                    label="Price"
                    variant="filled"
                    fullWidth={true}
                    value={
                      jobLine.sellPrice
                        ? removeLeadingZeros(jobLine.sellPrice)
                        : 0
                    }
                    error={!jobLine.sellPrice || jobLine.sellPrice === 0}
                    size="small"
                    onBlur={(e) => {
                      validateSellPrice(jobLine);
                    }}
                    onChange={(e) =>
                      props.handleOnChangeJobLine(
                        jobLine.guid,
                        "sellPrice",
                        Number(e.target.value)
                      )
                    }
                  />
                </div>
              )}

              <div>
                <TotalDisplay jobLine={jobLine} />
              </div>
              {/*  style={{ textAlign: "end" }} */}
              <div>
                <MarginDisplay
                  total={jobLine.total}
                  margin={jobLine.margin}
                  currency={currency || "NZD"}
                />
              </div>

              <div className="magnesium-action-button-container">
                <IconButton
                  edge="end"
                  className="opacity-icon"
                  aria-label="delete"
                  style={{
                    marginRight: 5,
                    visibility: props.isReadOnly ? "hidden" : "visible",
                  }}
                  onClick={(e) => {
                    props.handleDeleteJobLine(jobLine);
                  }}
                >
                  <DeleteIcon />
                </IconButton>

                <Typography
                  variant="overline"
                  gutterBottom
                  className="itemised"
                  style={{
                    visibility:
                      jobLine.isPassThrough > 0 ? "visible" : "hidden",
                  }}
                >
                  {jobLine.isPassThrough === 1 ? "itemised" : "pass through"}
                </Typography>
              </div>
              {jobLine.allowPassThrough === 1 && !props.isReadOnly && (
                <Menu
                  open={contextMenu !== null}
                  onClose={handleCloseMenu}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextMenu !== null
                      ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                      : undefined
                  }
                >
                  {jobLine.isPassThrough ? (
                    <MenuItem
                      onClick={(e) => {
                        handleMenuClick(0);
                      }}
                    >
                      Remove Pass Through
                    </MenuItem>
                  ) : (
                    <>
                      <MenuItem
                        onClick={(e) => {
                          handleMenuClick(2);
                        }}
                      >
                        {"Pass Through"}
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          handleMenuClick(1);
                        }}
                      >
                        {"Pass Through & Itemise"}
                      </MenuItem>
                    </>
                  )}
                </Menu>
              )}
              {jobLine.allowPassThrough === 0 && !props.isReadOnly && (
                <Menu
                  open={contextMenu !== null}
                  onClose={handleCloseMenu}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextMenu !== null
                      ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                      : undefined
                  }
                >
                  <MenuItem onClick={handleCloseMenu}>
                    Product doesn't allow pass through
                  </MenuItem>
                </Menu>
              )}
            </div>
          )}
        </Draggable>
      )}
    </div>
  );
};

export default React.memo(JobLinesTableRow, (props, nextProps) => {
  if (
    JSON.stringify(props.jobLine) === JSON.stringify(nextProps.jobLine) &&
    JSON.stringify(props.isReadOnly) === JSON.stringify(nextProps.isReadOnly)
  ) {
    // don't re-render/update
    return true;
  }
});
//export default JobLinesTableRow;

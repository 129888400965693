import React, { Fragment, useState } from "react";
import Typography from "@mui/material/Typography";
import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, height } from "@mui/system";
import JobLinesTable from "./JobLinesTable";
import { Droppable } from "react-beautiful-dnd";

const JobLinesAccordionItem = (props) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const displayLines = () => {
    setIsExpanded(!isExpanded)
  };

  const calculateTotal = () => {
    return props.jobLines
      ?.filter((j) => j.category === props.category.name)
      .reduce((a, b) => a + b.total, 0);
  };

  const calculateTotalMargin = () => {
    return props.jobLines
      ?.filter((j) => j.category === props.category.name)
      .reduce((a, b) => a + b.margin, 0);
  };

  return (
    <Droppable
      key={`${props.category.guid}`}
      droppableId={`${props.category.name}`}
    >
      {(provided, snapshot) => (
        <Fragment>
          <div
            key={props.category.guid}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ display: "flex"}}
          >
            <div className="text-left no-padding">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  displayLines();
                }}
              >
                {isExpanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </div>
            <div className="category-name">
              <Typography className="category-typography">
                {props.category.name}
              </Typography>
            </div>
            {/* <div className="category-total">
              <Typography className="category-typography">
                {calculateTotal()?.toLocaleString("en-NZ", {
                  style: "currency",
                  currency: "NZD",
                  minimumFractionDigits: 2,
                })}
              </Typography>
            </div>
            <div className="category-margin-total">
              <Typography className="category-typography">
              {calculateTotalMargin()?.toLocaleString("en-NZ", {
                  style: "currency",
                  currency: "NZD",
                  minimumFractionDigits: 2,
                })}
              </Typography>
            </div>
            <div width="auto">
              <Typography className="category-typography">
              
              </Typography>
            </div> */}
          </div>
          <TableRow>
            <TableCell style={{ padding: 0 }} colSpan={11}>
              <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
              >
                <JobLinesTable
                  provided={provided}
                  category={props.category}
                  products={props.products}
                  jobLines={props.jobLines}
                  handleChangeProduct={props.handleChangeProduct}
                  handleOnChangeJobLine={props.handleOnChangeJobLine}
                  handleAddNewJobLineButton={props.handleAddNewJobLineButton}
                  handleDeleteJobLine={props.handleDeleteJobLine}
                  getMargin={props.getMargin}
                  handleChangeProductRateType={props.handleChangeProductRateType}
                  isReadOnly={props.isReadOnly}
                  jobOrganisation={props.jobOrganisation}
                />
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      )}
    </Droppable>
  );
};
export default JobLinesAccordionItem;
// export default React.memo(JobLinesAccordionItem, (props, nextProps)=> {
//   if(JSON.stringify(props.jobLines) !== JSON.stringify(nextProps.jobLines)) {
//       // don't re-render/update
//       return true
//   }
// });

import Close from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PaymentCards from "./PaymentCards";
import { updateJobStore } from "../../reduxActions/job";
import axios from "axios";
import dayjs from "dayjs";

const AdvancedInvoiceDialog = (props) => {
  let { updateJobStore, organisationId } = props;
  const [invoiceOption, setInvoiceOption] = useState("END_OF_MONTH_BILLING");
  const [invoices, setInvoices] = useState([]);
  const [isInvalidPayment, setIsInvalidPayment] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [updatingInvoice, setUpdatingInvoice] = useState(false);

  useEffect(() => {
    if (props.paymentType) {
      setInvoiceOption(props.paymentType);
    }
  }, [props.paymentType]);

  useEffect(() => {
    if (props.job.invoice) {
      setInvoices(props.job.invoice);
    }
  }, [props.job.invoice]);

  useEffect(() => {
    if (invoices.length > 0) {
      validateInvoices();
    }
  }, [invoices]);

  const onChangeInvoiceOption = (e) => {
    setUpdatingInvoice(true);
    setInvoiceOption(e.target.value);
    axios
      .post(`Invoice/PreviewJobInvoice/${e.target.value}`, props.job)
      .then(({ data }) => {
        setInvoices(data);
      }).finally(() => {
        setUpdatingInvoice(false);
      });
  };

  const saveInvoiceUpdates = async () => {
    if (!isSaving) {
      setIsSaving(true);
      axios
        .put(
          `Job/UpdatePaymentDetails/${props.job.id}/${invoiceOption}`,
          invoices
        )
        .then(({ data }) => {
          updateJobStore("paymentType", invoiceOption);
          updateJobStore("invoice", data);
          props.handleClose();
        }).finally(() => setIsSaving(false));
    }
  };

  const handleUpdateInvoice = (invoices) => {
    setInvoices(invoices);
  };

  const validateInvoices = () => {
    setIsInvalidPayment(false);
    for (let i = 0; i < invoices.length; i++) {
      if (
        invoices[i].date &&
        invoices[i].dueDate &&
        dayjs(invoices[i].date).diff(dayjs(invoices[i].dueDate), "day") > 0
      ) {
        setIsInvalidPayment(true);
        break;
      }
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title-contact"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className="sales-dialog-title">
          Advanced Invoice
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction="row">
            <Grid item xs={3}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="invoice-set"
                row
                value={invoiceOption}
                onChange={onChangeInvoiceOption}
              >
                {!props.job.isRetainer && (
                  <>
                    <FormControlLabel
                      value="FULL_PRE_PAYMENT"
                      control={<Radio size="small" />}
                      label="Full Pre Payment"
                    />
                    <FormControlLabel
                        value="HALF_DEPOSIT"
                        control={<Radio size="small" />}
                        label="Half Deposit (50/50)"
                      />
                  </>
                )}
                <FormControlLabel
                  value="END_OF_MONTH_BILLING"
                  control={<Radio size="small" />}
                  label="End Of Month Billing"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={9}>
              <PaymentCards
                job={props.job}
                paymentType={invoiceOption}
                isReadOnly={false}
                invoices={invoices}
                handleUpdateInvoice={handleUpdateInvoice}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} className="job-button">
            Cancel
          </Button>
          <Button
            className={isInvalidPayment ? "" : "job-button"}
            onClick={saveInvoiceUpdates}
            disabled={isInvalidPayment || isSaving || updatingInvoice}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { updateJobStore })(
  AdvancedInvoiceDialog
);

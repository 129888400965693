import { Button, Container, Divider, Skeleton, Tab, Tabs } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import RevenueDashboard from "./Charts/RevenueChart/RevenueDashboard";
import SalesMarginDashboard from "./Charts/SalesMarginChart/SalesMarginDashboard";
import "./dashboard.scss";
import DashboardFilters from "./DashboardFilters";
import ClientsProfitabilityDashboard from "./Charts/ClientsProfitability/ClientsProfitabilityDashboard";
import TableauDashboard from "./TableauDashboard";

const initialFilter = {
  dateFrom: dayjs().add(-3, "months").startOf("month").format("YYYY-MM-DD"),
  dateTo: dayjs().add(3, "months").endOf("month").format("YYYY-MM-DD"),
  xeroAccount: 0,
  companyId: 0,
  userEmail: "",
  companySize: "",
  organisationId: 0,
  pullApprovedOnly: true,
  productType: null,
  teamColour: null,
  status: ["draft", "checked", "sent", "approved"]
};

const Dashboard = (props) => {
  const [filters, setFilters] = useState(initialFilter);
  const [activeTab, setActiveTab] = useState("SALES");

  const handleChangeFilter = (field, value) => {
    if (field === "dateFrom" || field === "dateTo") {
      setFilters({
        ...filters,
        [field]: value ? value : dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    } else {
      setFilters({ ...filters, [field]: value });
    }
  };

  const resetFilters = () => {
    setFilters(initialFilter);
  };
  const handleGraphTabChange = (_event, newValue) => {
    setActiveTab(newValue);
  };

  const charts = [
    {
      id: "SALES",
      label: "Sales Won",
      component:
        <SalesMarginDashboard
          filters={filters}
          setFilters={setFilters}
          handleChangeFilter={handleChangeFilter}
        />,
    },
    {
      id: "REVENUE",
      label: "Monthly Billings",
      component:
        <RevenueDashboard
          filters={filters}
          setFilters={setFilters}
          handleChangeFilter={handleChangeFilter}
        />,
    },
    {
      id: "PROFITABILITY",
      label: "Clients Profitability",
      component:
        <ClientsProfitabilityDashboard
          filters={filters}
          setFilters={setFilters}
          handleChangeFilter={handleChangeFilter}
        />
    }
  ]

  const getChartById = (id) => charts.find(chart => chart.id === id);


  return (
    <Container maxWidth="xl">
      <div className="dashboard-root">
        <DashboardFilters
          filters={filters}
          handleChangeFilter={handleChangeFilter}
        />
        <Divider />
        <div className="dashboard-reset-btn">
          <div>
            <Tabs
              value={activeTab}
              onChange={handleGraphTabChange}
              className="main-tabs"
            >
              {
                charts.map(chart => 
                  <Tab key={chart.id} value={chart.id} label={chart.label} />
                )
              }
            </Tabs>
          </div>
          <Button
            variant="outlined"
            onClick={resetFilters}
            className="default-button-outlined"
          >
            Reset Filters
          </Button>
        </div>
        <div className="dashboard-charts-container">
          {
            getChartById(activeTab)?.component
          }
        </div>
      </div>
    </Container>
    // <TableauDashboard />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Dashboard);

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Save as SaveIcon
} from "@mui/icons-material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import MarginExemptionEditPopup from "./MarginExemptionEditPopup";
import CustomTableHead from "../../../Shared/CustomTableHead";
import ConfirmationDialog from "../../../Shared/ConfirmationDialog";
import Snack from "../../../Shared/Snack";

const tableHeaders = [
    {
        field: "clientName",
        name: "Client",
        fieldType: "string",
    },
    {
        field: "productType",
        name: "Product Type",
        fieldType: "string",
    },
    {
        field: "default",
        name: "Default %",
        fieldType: "number",
    },
    {
        field: "newLimit",
        name: "Exemption %",
        fieldType: "number",
    },
    {
        field: "endDate",
        name: "End Date",
        fieldType: "string",
    },
    {
        field: "status",
        name: "Status",
        fieldType: "string",
    },
    {
        field: "",
        name: "",
        fieldType: "string",
    },
];

const MarginExemptionTableRow = (props) => {
    const {
        exemption,
        setLoadExemtions
    } = props;

    const [openSnack, setOpenSnack] = useState(false);
    const [snackSeverity, setSnackSeverity] = useState("info");
    const [snackText, setSnackText] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [selectedExemption, setSelectedExemption] = useState(null)

    const handleDelete = () => {
        axios.delete(`MarginExemption/DeleteExemption/${selectedExemption.id}`)
            .then(res => {
                setLoadExemtions(true);
            }).catch((e) => {
                setSnackText("Oops something went wrong. Could not delete exemption");
                setSnackSeverity("error");
                setOpenSnack(true);
            }).finally(() => {
                setShowDeleteWarning(false);
                setSelectedExemption(null);
            })
    }

    return (
        <React.Fragment>
            <Snack
                open={openSnack}
                setOpen={setOpenSnack}
                severity={snackSeverity}
                text={snackText}
            />
            {showPopup && (
                <MarginExemptionEditPopup
                    open={showPopup}
                    handleClose={() => setShowPopup(false)}
                    handleCloseAndRefresh={() => {
                        setShowPopup(false);
                        setLoadExemtions(true);
                    }}
                    exemption={exemption}
                />
            )}
            {showDeleteWarning && (
                <ConfirmationDialog
                    open={showDeleteWarning}
                    handleClose={() => {
                        setShowDeleteWarning(false);
                        setSelectedExemption(null)
                    }}
                    submitLabel={"Delete"}
                    title={"Delete Exemption?"}
                    description={
                        "Are you sure you want to delete this exemption? Once deleted, user will need to request for this exemption again"
                    }
                    handleSubmit={() => {
                        handleDelete();
                    }}
                />
            )}
            <TableRow>
                <TableCell className="text-center padding-4px">{exemption.clientName}</TableCell>
                <TableCell className="text-center padding-4px">{exemption.productType}</TableCell>
                <TableCell className="text-center padding-4px">{exemption.default}</TableCell>
                <TableCell className="text-center padding-4px">{exemption.newLimit}</TableCell>
                <TableCell className="text-center padding-4px">{exemption.endDate}</TableCell>
                <TableCell className="text-center padding-4px">{exemption.status}</TableCell>
                <TableCell className="text-center padding-4px">
                    <div style={{ display: "flex" }}>
                        <IconButton onClick={() => setShowPopup(true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => {
                            setShowDeleteWarning(true);
                            setSelectedExemption(exemption);
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

const MarginExemptionTable = (props) => {
    const [loadExemptions, setLoadExemtions] = useState(true);
    const [exemptions, setExemptions] = useState([]);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState({
        field: "created",
        name: "Created",
        fieldType: "date",
    });

    const handleOnRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    useEffect(() => {
        if (loadExemptions) {
            setLoadExemtions(false);
            axios.get(`MarginExemption/GetExemptions`)
                .then(res => {
                    setExemptions(res.data);
                }).catch(e => console.log(e))
        }
    }, [loadExemptions])

    return (
        <Table className="table table-striped table-hover text-nowrap mb-0">
            {/* <TableHead>
                <TableRow style={{ backgroundColor: "white" }}>
                    <TableCell>Client</TableCell>
                    <TableCell>Product Type</TableCell>
                    <TableCell>Default %</TableCell>
                    <TableCell>Exemption %</TableCell>
                    <TableCell>Ends</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead> */}
            <CustomTableHead
                headers={tableHeaders}
                orderBy={orderBy}
                order={order}
                onRequestSort={handleOnRequestSort}
            />
            <TableBody>
                {exemptions.map(e => {
                    return (<MarginExemptionTableRow exemption={e} setLoadExemtions={setLoadExemtions} />)
                })}
            </TableBody>
        </Table>
    )
}

export default MarginExemptionTable;
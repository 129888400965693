import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import ProcessingSpinner from "../Shared/ProcessingSpinner";
import ResultsTable from "./ResultsTable";

const ContractsAndTerms = ({ selectedOrganisation }) => {
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  useEffect(() => {
    if (contracts.length < 1 && selectedOrganisation) {
      fetchData();
    }
  }, [selectedOrganisation]);

  const fetchData = async () => {
    if (selectedOrganisation) {
      setLoading(true);
      axios
        .get(
          "ContractsTemplate/GetAll?organisationId=" + selectedOrganisation.id
        )
        .then(({ data }) => setContracts(data))
        .finally(() => setLoading(false));
    }
  };

  return (
    <div>
      {loading ? (
        <ProcessingSpinner duration={60} unitTime={"seconds"} />
      ) : (
        <ResultsTable contracts={contracts} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {})(ContractsAndTerms);

import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import {
  Edit as EditIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import dayjs from "dayjs";
import DocumentViewer from "../Shared/DocumentViewer";
import { currencySymbolFormat } from "../../Utils/GeneralUtils";

const EditInvoiceDialog = (props) => {
  const [inputValue, setInputValue] = useState();
  useEffect(() => {
    if (props.value) {
      setInputValue(dayjs(props.value).format("YYYY-MM-DD"));
    }
  }, [props.value]);

  return (
    <Dialog open={props.open} onClose={props.handleClose} size="xs">
      <DialogTitle id="quote-preview-dialog-title">
        Edit {props.label}
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          label={props.label}
          type="date"
          id="change-date-input"
          value={inputValue}
          variant="filled"
          size="small"
          fullWidth={true}
          onChange={(e) => setInputValue(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <div style={{ textAlign: "right" }}>
          <Button
            className="job-button"
            variant="contained"
            onClick={(e) => {
              props.handleSaveEditInvoice(inputValue);
            }}
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const PaymentCardItem = ({
  title,
  minWidth,
  isReadOnly,
  invoice,
  handleUpdateInvoice,
  isTwoThirds,
  currency = "NZD",
  isBalance
}) => {
  const [openEditInvoiceDialog, setOpenEditInvoiceDialog] = useState(false);
  const [field, setField] = useState("Date");

  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);

  const handleOpenEditInvoice = (fieldLabel) => {
    setField(fieldLabel);
    setOpenEditInvoiceDialog(true);
  };
  const handleCloseEditInvoice = () => {
    setOpenEditInvoiceDialog(false);
  };
  const handleSaveEditInvoice = (inputValue) => {
    let invoiceClone = { ...invoice };
    if (field === "Date") {
      invoiceClone.date = inputValue;
    } else {
      invoiceClone.dueDate = inputValue;
    }
    handleUpdateInvoice(invoiceClone);
    handleCloseEditInvoice();
  };

  const formatDate = (date) => {
    if (date) {
      return dayjs(date).format("DD/MM/YYYY");
    }
    return date;
  };

  const validateIfInvoiceIsValid = () => {
    if ((isTwoThirds && invoice.date == null)) {
      return false;
    }
    return true;
  };

  const handleOpenPdf = (invoiceId, organisationId) => {
    setInvoiceUrl(`/Invoice/GetInvoiceAsPdf/${invoiceId}/${organisationId}`);
    setShowDocumentViewer(true);
  };

  return (
    <Fragment>
      {openEditInvoiceDialog && (
        <EditInvoiceDialog
          open={openEditInvoiceDialog}
          handleClose={handleCloseEditInvoice}
          handleSaveEditInvoice={handleSaveEditInvoice}
          label={field}
          value={field === "Date" ? invoice.date : invoice.dueDate}
        />
      )}
      {showDocumentViewer && (
        <DocumentViewer
          modalState={showDocumentViewer}
          handleModal={() => {
            setShowDocumentViewer(false);
          }}
          headerColor="#000"
          docType={"PDF"}
          urlFile={invoiceUrl}
        />
      )}
      <Card
        // sx={{ minWidth: minWidth ?? 230 }}
        style={{
          width: "-webkit-fill-available",
          margin: "0 7.5px",
          padding: 10,
          paddingBottom: 10,
          border:
            validateIfInvoiceIsValid() === true
              ? "1px solid rgba(0, 0, 0, 0.12)"
              : "1px solid rgb(211, 47, 47)",
        }}
        variant="outlined"
      >
        <CardContent style={{ padding: "unset", paddingBottom: "unset" }}>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {"Date: "}
              {invoice.date
                ? formatDate(invoice.date)
                : isTwoThirds && !isReadOnly
                ? "Required Date*"
                : isBalance 
                ? "Month After First Invoice"
                : "Acceptance Date"}
              {!isReadOnly && (
                <IconButton
                  size="small"
                  style={{ padding: "0 5px", marginBottom: 3 }}
                  onClick={(e) => {
                    handleOpenEditInvoice("Date");
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
            </Typography>
          </div>
          <div style={{ display: "flex", gap: 5 }}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {"Price:  "}
              {invoice.total?.toLocaleString("en-UK", {
                style: "currency",
                currency: currency || "NZD",
                minimumFractionDigits: 2,
              })}
            </Typography>
            {(currency !== "NZD" && invoice.total !== invoice.exchangedTotal) && (
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {" - "}
                {currencySymbolFormat(invoice.exchangedTotal, currency)}
              </Typography>
            )}
          </div>

          <div style={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {"Due Date:  "}
              {invoice.dueDate
                ? formatDate(invoice.dueDate)
                : isTwoThirds && !isReadOnly
                ? "Required Date*"
                : "Following Month"}
            </Typography>
            {!isReadOnly && (
              <IconButton
                size="small"
                style={{ padding: "0 5px", marginBottom: 3 }}
                onClick={(e) => {
                  handleOpenEditInvoice("Due Date");
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            )}
          </div>
          {invoice.invoiceNumber && (
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              <div
                onClick={(e) => {
                  handleOpenPdf(invoice.newXeroInvoiceId || invoice.xeroInvoiceId, invoice.newXeroInvoiceId ? -1 : invoice.organisationId);
                }}
              >
                {invoice.invoiceNumber}
                {invoice.xeroInvoiceId && (
                  <PictureAsPdfIcon
                    fontSize="small"
                    style={{ cursor: "pointer", marginLeft: 5 }}
                  />
                )}
              </div>
            </Typography>
          )}
        </CardContent>
        {!validateIfInvoiceIsValid() && (
          <Typography variant="caption" style={{ color: "red" }}>
            Please enter required dates.
          </Typography>
        )}
      </Card>
    </Fragment>
  );
};

export default PaymentCardItem;

import Close from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { calculateJobTotal } from "../../Utils/JobHelpers";

const ConfirmJobDialog = (props) => {
  const history = useHistory();
  const { job } = props;
  const [hasExplainedProject, setHasExplainedProject] = useState(false);
  const [hasSaidTotalCost, setHasSaidTotalCost] = useState(false);
  const [hasAskedHappyToAccept, setHasAskedHappyToAccept] = useState(false);
  const [sendingJob, setSendingJob] = useState(false);

  const sendJob = () => {
    setSendingJob(true);
    axios
      .put("Job/SendJob", job)
      .then(() => {
        setSendingJob(false);
        history.push("/home");
      })
      .catch((err) => {
        alert("Failed to send job. If problem persists please contact IT");
        setSendingJob(false);
      });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={!sendingJob && props.handleClose}
        size="sm"
      >
        <DialogTitle id="quote-preview-dialog-title">
          Call the contact and confirm the job
          <IconButton
            aria-label="close"
            onClick={!sendingJob && props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="after-divider">
                <Typography variant="subtitle1">{`Great! Now it's time to call ${job.contact?.firstName} ${job.contact?.lastName}. Their number is ${job.contact?.phoneNumber}`}</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Explain the project"
                control={
                  <Checkbox
                    checked={hasExplainedProject}
                    onChange={(e) => {
                      setHasExplainedProject(e.target.checked);
                      setHasSaidTotalCost(false);
                      setHasAskedHappyToAccept(false);
                    }}
                  />
                }
              />
            </Grid>
            {hasExplainedProject && (
              <Grid item xs={12}>
                <FormControlLabel
                  label={`Verbally say the total cost ${calculateJobTotal(
                    job.jobLines
                  ).toLocaleString("en-NZ", {
                    style: "currency",
                    currency: "NZD",
                    minimumFractionDigits: 2,
                  })} + GST`}
                  control={
                    <Checkbox
                      checked={hasSaidTotalCost}
                      onChange={(e) => {
                        setHasSaidTotalCost(e.target.checked);
                        setHasAskedHappyToAccept(false);
                      }}
                    />
                  }
                />
              </Grid>
            )}
            {hasSaidTotalCost && hasExplainedProject && (
              <Grid item xs={12}>
                <FormControlLabel
                  label='Ask "If I send this now are you happy to click accept now?"'
                  control={
                    <Checkbox
                      checked={hasAskedHappyToAccept}
                      onChange={(e) => {
                        setHasAskedHappyToAccept(e.target.checked);
                      }}
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {hasExplainedProject && hasSaidTotalCost && hasAskedHappyToAccept && (
            <div style={{ textAlign: "right" }}>
              <Button
                className="job-button"
                variant="contained"
                onClick={sendJob}
                disabled={sendingJob}
              >
                Send now
                {sendingJob && (
                  <span>
                    <CircularProgress className="icon-button" />
                  </span>
                )}
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  job: state.job.job,
});

export default connect(mapStateToProps, {})(ConfirmJobDialog);

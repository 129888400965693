import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  organisations: [],
  selectedOrganisation: {
    id: 2,
  },
};

const organisation = createReducer(initialState, {
  GET_ALL_ORGANISATIONS: (state, action) => {
    return {
      ...state,
      organisations: action.payload,
    };
  },
  SET_SELECTED_ORGANISATION: (state, action) => {
    return {
      ...state,
      selectedOrganisation: action.payload,
    };
  },
});

export default organisation;

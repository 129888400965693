import { Button, CircularProgress, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import ConfirmationDialogWithSelect from "../Shared/ConfirmationDialogWithSelect";
import InfoDialog from "../Shared/InfoDialog";
import { useAuth } from "../../context/auth/AuthContext";
const INTERNAL_SOCIALITES_COMPANY_ID = 391;
const JobActions = ({
  job,
  setOpenConfirmClearJobDialog,
  handleCheckAndSend,
  downloadPdf,
  hasJobNameError,
  isInvalidDate,
  saveDraftJob,
  saving,
  loadingPdf,
  isReadOnly,
  percent,
  showSavePoButton,
  handleSaveAcceptedQuotePo,
  selectedCompany,
}) => {
  const { user } = useAuth();
  const [openProductionInternalWarning, setOpenProductionInternalWarning] =
    useState(false);
  const [openNotTestConfirmation, setOpenNotTestConfirmation] = useState(false);
  const [
    openRetainerDownloadConfirmation,
    setOpenRetainerDownloadConfirmation,
  ] = useState(false);

  const showWarningPopup = () => {
    return (
      (user.email === "cameron@magnesium.nz" || user.email === "dev@magnesium.nz") &&
      !selectedCompany?.name.toLowerCase().includes("test") &&
      !selectedCompany?.name.toLowerCase().includes("internal")
    );
  };

  const showInternalProductionWarningPopup = () => {
    return (
      (user.email === "carlene@magnesium.nz") &&
      selectedCompany?.id === INTERNAL_SOCIALITES_COMPANY_ID && 
      !job.crossOrganisationProjectId
    );
  };

  return (
    <div id="job-footer-buttons">
      <div>
        {openProductionInternalWarning && (
          <InfoDialog
            open={openProductionInternalWarning}
            handleClose={() => {
              setOpenProductionInternalWarning(false);
            }}
            title={"Please change the company to proceed."}
            context={`For internal quotes, please use the company "TS INTERNAL".`}
          />
        )}
        {openNotTestConfirmation && (
          <ConfirmationDialog
            open={openNotTestConfirmation}
            handleClose={() => {
              setOpenNotTestConfirmation(false);
            }}
            submitLabel={"Confirm"}
            title={"Please confirm to proceed"}
            description={`This is NOT a test company. Are you sure?`}
            handleSubmit={() => {
              handleCheckAndSend();
              setOpenNotTestConfirmation(false);
            }}
          />
        )}
        {openRetainerDownloadConfirmation && (
          <ConfirmationDialogWithSelect
            open={openRetainerDownloadConfirmation}
            handleClose={() => setOpenRetainerDownloadConfirmation(false)}
            defaultValue={job.retainerType}
            menuItems={[
              <MenuItem value="MONTHLY">Monthly</MenuItem>,
              <MenuItem
                value="QUARTERLY"
                disabled={job.retainerDuration % 3 !== 0}
              >
                Quarterly
              </MenuItem>,
              <MenuItem
                value="YEARLY"
                disabled={job.retainerDuration % 12 !== 0}
              >
                Yearly
              </MenuItem>,
              <MenuItem value="TOTAL">Total</MenuItem>,
            ]}
            handleSubmit={(selectValue) => downloadPdf(selectValue)}
            description={"Please select the retainer view type to download"}
            title={"Download Retainer"}
            submitLabel={"Download"}
            loading={loadingPdf}
            duration={30}
          />
        )}
        {job.status !== "sent" && job.status !== "approved" && !isReadOnly && (
          <Button
            className="job-button"
            onClick={(e) => {
              setOpenConfirmClearJobDialog(true);
            }}
            variant="contained"
          >
            Clear
          </Button>
        )}
        {showSavePoButton && (
          <Button
            className={"job-button"}
            variant="contained"
            onClick={() => {
              handleSaveAcceptedQuotePo();
            }}
          >
            Save PO Number
            {saving && !loadingPdf && (
              <span>
                <CircularProgress className="icon-button" />
              </span>
            )}
          </Button>
        )}
        {!isReadOnly && (
          <Button
            className={
              percent == null ||
              percent < 0.5 ||
              hasJobNameError ||
              isInvalidDate
                ? ""
                : "job-button"
            }
            variant="contained"
            disabled={
              percent == null ||
              percent < 0.5 ||
              saving ||
              hasJobNameError ||
              isInvalidDate
            }
            onClick={() => {
              saveDraftJob();
            }}
          >
            {job && job.id ? "Save Draft" : "Save as Draft"}
            {saving && !loadingPdf && (
              <span>
                <CircularProgress className="icon-button" />
              </span>
            )}
          </Button>
        )}
        {percent && percent > 0.4 ? (
          <Button
            className={hasJobNameError || isInvalidDate ? "" : "job-button"}
            onClick={(e) => {
              if(showInternalProductionWarningPopup()) {
                setOpenProductionInternalWarning(true);
              } else if (showWarningPopup()) {
                setOpenNotTestConfirmation(true);
              } else {
                handleCheckAndSend();
              }
            }}
            variant="contained"
            disabled={saving || hasJobNameError || isInvalidDate}
          >
            {isReadOnly ? "View Quote" : "Check and Send"}
            {saving && !loadingPdf && (
              <span>
                <CircularProgress className="icon-button" />
              </span>
            )}
          </Button>
        ) : (
          <div></div>
        )}

        {job && job.id && job.contactId && job.contactId !== 0 && (
          <Button
            className={hasJobNameError ? "" : "job-button"}
            disabled={hasJobNameError}
            onClick={(e) => {
              if (job.isRetainer) {
                setOpenRetainerDownloadConfirmation(true);
              } else {
                downloadPdf();
              }
            }}
            variant="contained"
          >
            Download as Pdf
            {loadingPdf && (
              <span>
                <CircularProgress className="icon-button" />
              </span>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  job: state.job.job,
});

export default connect(mapStateToProps, {})(JobActions);

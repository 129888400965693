import React from "react";
import { ResponsiveBar } from "@nivo/bar";

import "./salesMarginChart.scss";
import dayjs from "dayjs";

const SalesMarginByMonthChart = ({ salesMarginData, filters, setFilters }) => {
  
  const handleChartClick = (data, event) => {
    if (data?.indexValue) {
      setFilters({
        ...filters,
        dateFrom: dayjs(
          data?.indexValue.replace(" Margin", "").replace(" Total", "")
        )
          .startOf("month")
          .format("YYYY-MM-DD"),
        dateTo: dayjs(
          data?.indexValue.replace(" Margin", "").replace(" Total", "")
        )
          .endOf("month")
          .format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <div className="sales-margin-chart-root">
      <div className="sales-margin-bar-chart-root">
        <ResponsiveBar
          data={salesMarginData.chart}
          keys={["draft", "sent", "approved",]}
          indexBy="monthYear"
          groupMode="stacked"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.2}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={({
            id,
            data
          }) => String(data[`${id}Color`])} 
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          animate={true}
          enableLabel={true}
          labelSkipHeight={10}
          label={(d) =>
            d.value === 0 ? (
              ""
            ) : (
              d.value?.toLocaleString("en-NZ", {
                style: "currency",
                currency: "NZD",
                minimumFractionDigits: 2,
              })
            )
          }
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: "middle",
          }}
          onMouseEnter={(_data, event) => {
            event.target.style.opacity = "0.7";
            event.target.style.strokeWidth = "1px";
          }}
          onMouseLeave={(_data, event) => {
            event.target.style.opacity = "1";
            event.target.style.strokeWidth = "0";
          }}
          onClick={handleChartClick}
          valueFormat={(value) =>
            `$${Number(value).toLocaleString("en-NZ", {
              minimumFractionDigits: 2,
            })}`
          }
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default SalesMarginByMonthChart;

import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Autocomplete,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@mui/material";
import axios from "axios";
import SpellCheckTextField from "../../Shared/SpellCheckTextField";
import Close from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Snack from "../../Shared/Snack";
import CachedIcon from "@mui/icons-material/Cached";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
// import utc from "dayjs/plugin/utc";

const Ipad = () => {
  const [boxes, setBoxes] = useState();
  const [devices, setDevices] = useState();
  const [logs, setLogs] = useState();
  const [levels, setLevels] = useState();
  const [render, setRender] = useState(false);
  const [selectedIpad, setSelectedIpad] = useState();
  const [link, setLink] = useState();
  const [contactName, setContactName] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [emailBcc, setEmailBcc] = useState();
  const [showPushNotificationDialog, setShowPushNotificationDialog] =
    useState();
  const [videosList, setVideosList] = useState();
  const [selectedVideo, setSelectedVideo] = useState();
  const [emailTemplates, setEmailTemplates] = useState();
  const [sendingPushNotification, setSendingPushNotification] = useState();
  const [openSnack, setopenSnack] = useState(false);
  const [snackSeverity, setsnackSeverity] = useState("info");
  const [snackText, setsnackText] = useState(false);

  useEffect(() => {
    if (dayjs) {
      dayjs.extend(dayjsPluginUTC);
    }
    const boxes = axios.get("IPad/GetBoxes");
    const devices = axios.get("IPad/GetDevices");
    const logs = axios.get("IPad/GetLogs?skip=0");
    const levels = axios.get("IPad/GetLevels");
    const _videosList = axios.get("IPad/GetVideosList");
    const _emailTemplates = axios.get("IPad/GetEmailTemplatesList");
    axios
      .all([boxes, devices, logs, levels, _videosList, _emailTemplates])
      .then(
        axios.spread(
          (boxes, devices, logs, levels, _videosList, _emailTemplates) => {
            console.log(boxes.data);
            console.log(devices.data);
            console.log(levels.data);
            console.log(logs.data);
            setLevels(levels.data);
            setBoxes(boxes.data);
            setDevices(devices.data);
            setLogs(logs.data);
            setVideosList(_videosList.data);
            setEmailTemplates(_emailTemplates.data);
          }
        )
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (boxes && devices && logs && levels && videosList && emailTemplates) {
      setRender(true);
    }
  }, [boxes, devices, logs, levels, videosList, emailTemplates]);

  const refreshLogs = () => {
    axios
      .get("IPad/GetLogs?skip=0")
      .then(({ data }) => {
        setLogs(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendPushNotification = () => {
    setSendingPushNotification(true);
    axios
      .post("IPad/SendPushNotification", {
        playerId: [selectedIpad.playerId],
        videoLocation: link,
        name: contactName,
        email: contactEmail,
        bcc: emailBcc,
        fromEmail: emailTemplates.emailAddress,
        emailTemplateId: emailTemplates.emailTemplateId,
      })
      .then(({ data }) => {
        console.log(data);
        setSendingPushNotification(false);
        setopenSnack(true);
        setsnackSeverity("success");
        setsnackText("Push Notification processed successfully");
        setShowPushNotificationDialog(false);
      })
      .catch((err) => {
        console.log(err);
        setSendingPushNotification(false);
        setopenSnack(true);
        setsnackSeverity("error");
        setsnackText("Failed to Push Notification");
      });
  };

  const loadMoreLogs = () => {
    axios
      .get(`IPad/GetLogs?skip=${logs.length}`)
      .then(({ data }) => {
        setLogs(logs.concat(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (render) {
    return (
      <div>
        <Snack
          open={openSnack}
          setOpen={setopenSnack}
          severity={snackSeverity}
          text={snackText}
        />
        <Dialog
          open={showPushNotificationDialog}
          onClose={() => setShowPushNotificationDialog(false)}
          classes={{ paper: "push-notification-dialog" }}
        >
          <DialogTitle id="quote-preview-dialog-title">
            Push Notifications
            <IconButton
              aria-label="close"
              onClick={() => setShowPushNotificationDialog(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <div className="ipad-divs">
              <div className="push-notifications">
                <Autocomplete
                  disabled={sendingPushNotification}
                  value={emailTemplates?.user}
                  options={emailTemplates}
                  onChange={(event, newValue) => {
                    setEmailTemplates(newValue);
                  }}
                  getOptionLabel={(email) => email.user}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sender"
                      size="small"
                      fullWidth={true}
                      variant="filled"
                    />
                  )}
                />
              </div>
              <div className="push-notifications">
                <Autocomplete
                  disabled={sendingPushNotification}
                  value={selectedIpad?.ipadName}
                  options={devices}
                  onChange={(event, newValue) => {
                    setSelectedIpad(newValue);
                  }}
                  getOptionLabel={(device) => device.ipadName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="iPad"
                      size="small"
                      fullWidth={true}
                      variant="filled"
                    />
                  )}
                />
              </div>
              <div className="push-notifications">
                <Autocomplete
                  disabled={sendingPushNotification}
                  value={selectedVideo}
                  options={videosList}
                  onChange={(event, newValue) => {
                    setSelectedVideo(newValue);
                    setLink(
                      `https://storage.googleapis.com/ipadvideos/${newValue.replace(
                        /\s+/g,
                        "%20"
                      )}`
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Video"
                      size="small"
                      fullWidth={true}
                      variant="filled"
                    />
                  )}
                />
              </div>
              <div className="push-notifications">
                <SpellCheckTextField
                  fullWidth
                  id="outlined-contact-name"
                  label="Contact Name"
                  value={contactName}
                  handleOnChange={(newValue) => setContactName(newValue)}
                  disabled={sendingPushNotification}
                />
              </div>
              <div className="push-notifications">
                <SpellCheckTextField
                  fullWidth={true}
                  id="outlined-contact-email"
                  label="Contact Email"
                  value={contactEmail}
                  handleOnChange={(newValue) => setContactEmail(newValue)}
                  disabled={sendingPushNotification}
                />
              </div>
              <div className="push-notifications">
                <SpellCheckTextField
                  fullWidth={true}
                  id="outlined-bcc"
                  label="Email Bcc"
                  value={emailBcc}
                  handleOnChange={(newValue) => setEmailBcc(newValue)}
                  disabled={sendingPushNotification}
                />
              </div>
              <div className="push-notifications-button">
                <Button
                  variant="outlined"
                  disabled={sendingPushNotification}
                  onClick={sendPushNotification}
                >
                  Send
                  {sendingPushNotification && (
                    <span>
                      <CircularProgress className="icon-button" />
                    </span>
                  )}
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <div id="ipad-container">
          <div className="half-screen-width">
            <Typography variant="h6">Status</Typography>
            <TableContainer className="ipad-tables">
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Box</TableCell>
                    <TableCell>iPad</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {boxes.map((b) => (
                    <TableRow key={b.id}>
                      <TableCell component="th" scope="row">
                        {`Name: ${b.boxName} | Battery: ${
                          Number(b.boxBattery)
                        }%`}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {`Name: ${b.ipad.ipadName} | Battery: ${
                          Number(b.ipadBattery) * 100
                        }%`}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          variant="outlined"
                          onClick={() => setShowPushNotificationDialog(true)}
                        >
                          Send Push Notification
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Button>Add New Box</Button> */}
          </div>
          <div className="ipad-divs">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Typography variant="h6">Logs</Typography>
              </div>
              <div>
                <IconButton color="primary" aria-label="refreshLogs" onClick={refreshLogs}>
                  <CachedIcon />
                </IconButton>
              </div>
            </div>
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Time Stamp</TableCell>
                    <TableCell>Box</TableCell>
                    <TableCell>Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.map((l) => (
                    <TableRow key={l.id}>
                      <TableCell component="th" scope="row">
                        {dayjs.utc(l.timeStamp).local().format("DD/MM/YYYY hh:mm:ss A")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {l.box.boxName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {l.message}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{textAlign: "center", marginTop: 10}}>
              <Button variant="outlined" onClick={loadMoreLogs}>Load More</Button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Ipad;

import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Autocomplete,
    Checkbox,
    CircularProgress,
    IconButton
} from '@mui/material';
import {
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    CheckBox as CheckBoxIcon,
    Close as CloseIcon
} from "@mui/icons-material";
import axios from 'axios';


const NotesDialog = ({
    open,
    handleClose,
    selectedPerson,
    handleSave,
}) => {
    const [note, setNote] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const getOnboardingNote = () => {
        setLoading(true);
        axios.get(`Onboarding/GetOnboardingPersonNote/${selectedPerson.id}`)
            .then(res => {
                if (res.data) {
                    setNote(res.data.note)
                } else {
                    setNote("")
                }
            }).catch(e => {

            }).finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        getOnboardingNote();
    }, []);

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth classes={{ paper: "confirmation-with-input-dialog" }}>
            <DialogTitle>
                Onboarding notes for {selectedPerson.name}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {loading ? <CircularProgress /> : (
                <div>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            id="onboarding-notes"
                            label={"Notes"}
                            type="text"
                            fullWidth
                            variant="filled"
                            multiline
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            handleSave(note)
                            setSaving(true);
                        }} className="default-button" disabled={saving} variant='contained'>
                            Save
                        </Button>
                    </DialogActions>
                </div>
            )
            }
        </Dialog>
    );
}

export default NotesDialog;
import React, { useState, useEffect } from "react";
import "./salesMarginDashboard.scss";
import dayjs from "dayjs";
import SalesMarginDataTable from "./SalesMarginDataTable";
import SalesMarginByMonthChart from "./SalesMarginByMonthChart";
import SalesMarginByPartitionChart from "./SalesMarginByPartitionChart";
import TotalCard from "../../TotalCard";
import axios from "axios";
import { Skeleton, Tab, Tabs } from "@mui/material";

const SalesMarginDashboard = ({
  setFilters,
  filters,
}) => {

  const [loading, setLoading] = useState(true);

  const [selectedChartTab, setSelectedChartTab] = useState("totalsByMonth")

  const handleSelectChartTab = (_e, newChartValue) => {
    console.log("setting selected chart tab to", newChartValue);
    setSelectedChartTab(newChartValue);
  }

  const [salesMarginData, setSalesMarginData] = useState({
    chart: [],
    data: [],
  });

  
  useEffect(() => {
    let mounted = true;
    setLoading(true);
    var status = filters.status && filters.status.length > 0 ? filters.status.join(",") : ""
    axios
    .post(`Dashboard/GetSalesMarginChartData?`, {
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
      organisationId: filters.organisationId || 0,
      companyId: filters.companyId || 0,
        userEmail: filters.userEmail || "",
        companySize: filters.companySize || "",
        xeroAccount: filters.xeroAccount || 0,
        pullApprovedOnly: filters.pullApprovedOnly, 
        productType: filters.productType || null,
        teamColour: filters.teamColour || null,
        status: status
      })
      .then(({ data }) => {
        if (mounted) setSalesMarginData(data);
      })
      .finally(() => {
        if (mounted) setLoading(false)
      });
    console.log(salesMarginData);
    return () => {
      mounted = false;
    }
  }, [filters]);

  const calculateTotals = (field) => {

    if (salesMarginData.data && salesMarginData.data.length > 0) {
      return [...salesMarginData.data]
        .reduce((a, b) => a + (b[field] || 0), 0);
    } else {
      return 0;
    }
  };

  return loading ? (
    <>
      <Skeleton height={500} />
    </>
  ) : (
    <div className="sales-margin-dashboard-root">
      <div className="sales-margin-dashboard-totals">
        <TotalCard
          label={"Total Margin"}
          value={calculateTotals("marginTotal").toLocaleString("en-NZ", {
            style: "currency",
            currency: "NZD",
            minimumFractionDigits: 2,
          })}
        />
        <TotalCard
          label={"Total Sales"}
          value={calculateTotals("total").toLocaleString("en-NZ", {
            style: "currency",
            currency: "NZD",
            minimumFractionDigits: 2,
          })}
        />
      </div>
      <div>
        <div>
          <Tabs
            value={selectedChartTab}
            onChange={handleSelectChartTab}
            className="chart-selection-tabs"
          >
            <Tab value="totalsByMonth" label="Totals By Month" />
            <Tab value="totalsByTeam" label="Totals By Team" />
            <Tab value="totalsByClient" label="Totals By Client" />
          </Tabs>
        </div>
        {
          ({
            totalsByMonth:
              <SalesMarginByMonthChart
                filters={filters}
                setFilters={setFilters}
                salesMarginData={salesMarginData}
                key="totalsByMonth"
                />,
                totalsByClient:
                <SalesMarginByPartitionChart
                  filters={filters}
                  setFilters={setFilters}
                  salesMarginData={salesMarginData}
                  fieldToPartitionBy="companyName"
                  key="totalsByClient"
                />,
              totalsByTeam:
                <SalesMarginByPartitionChart
                  filters={filters}
                  setFilters={setFilters}
                  salesMarginData={salesMarginData}
                  fieldToPartitionBy="teamColour"
                  key="totalsByTeamColour"
                />
          })[selectedChartTab]
        }
        <SalesMarginDataTable
          salesMarginData={salesMarginData}
        />
      </div>
      
    </div>
  );
};

export default SalesMarginDashboard;

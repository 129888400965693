import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { connect } from "react-redux";
import {
  getAllProducts,
  getAllProductCategories,
} from "../../reduxActions/product";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const TotalDisplay = ({
  jobLine,
  products,
  getAllProducts,
  getAllProductCategories,
  selectedOrganisation,
  currency,
}) => {
  const [budget, setBudget] = useState(0);
  useEffect(() => {
    if (products.length < 1 && selectedOrganisation) {
      getAllProducts(selectedOrganisation?.id);
      getAllProductCategories();
    }
  }, [products]);

  useEffect(() => {
    if (jobLine && jobLine.productId && products.length > 0) {
      const product = products.find((p) => p.id === jobLine.productId);
      if (
        product &&
        (product.taskType === "E" ||
          product.taskType === "ELF" ||
          product.taskType === "ECT")
      ) {
        let budgetToUpdate = jobLine.quantity * jobLine.costPrice;
        if (jobLine.rateType === "Open Budget") {
          budgetToUpdate =
            jobLine.calculationMethod === "Margin"
              ? jobLine.sellPrice - jobLine.margin
              : jobLine.sellPrice;
        }
        setBudget(Number(budgetToUpdate));
      }
    }
  }, [jobLine, products]);
  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
      <HtmlTooltip
        title={
          <React.Fragment>
            <div>
              <b>{"Budget to spend: "}</b>{" "}
              {budget?.toLocaleString("en-UK", {
                style: "currency",
                currency: currency || "NZD",
                minimumFractionDigits: 2,
              })}
            </div>
          </React.Fragment>
        }
      >
        <span>
          {jobLine.total?.toLocaleString("en-UK", {
            style: "currency",
            currency: currency || "NZD",
            minimumFractionDigits: 2,
          })}
        </span>
      </HtmlTooltip>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currency: state.job.job.currency,
  products: state.product.products,
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {
  getAllProducts,
  getAllProductCategories,
})(TotalDisplay);

import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  expenseCodes: [],
};

const data = createReducer(initialState, {
  GET_EXPENSE_CODES: (state, action) => {
    const { payload } = action;
    state.expenseCodes = payload;
  },
})

export default data;
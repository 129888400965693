
import React, { useEffect, useState } from "react";
import { ResponsiveSunburst } from '@nivo/sunburst'
import "./salesMarginChart.scss";

function partitionByField(array, field) {
	let partitionedData = {};

	console.log("partitioning by", field);
	
	array.forEach((item) => {
		if (!partitionedData[item[field] ?? "None"]) {
			partitionedData[item[field] ?? "None"] = [item];
		} else {
			partitionedData[item[field] ?? "None"].push(item);
		}

	});

	console.log("And got", partitionedData);

	return partitionedData;
}

function calculatePartitionTotals(partitions, partitionByField) {
	let partitionTotals = {};

	console.log("calculating sum...")
	
	for (const [key, value] of Object.entries(partitions)) {
		partitionTotals[key] = {
			[partitionByField]: value[0][partitionByField] ?? "None",
			revenueTotal: value.reduce((acc, item) => {
				return acc + item.total;
			}, 0),
			marginTotal: value.reduce((acc, item) => {
				return acc + item.marginTotal;
			}, 0),
		};
	};
	console.log("done: ", partitionTotals);

	return partitionTotals;
}

const SalesMarginByPartitionChart = ({
	filters,
	setFilters,
	salesMarginData,
	fieldToPartitionBy
}) => {

	const [charData, setChartData] = useState({});

	useEffect(() => {
		const partitionTotals = calculatePartitionTotals(
			partitionByField(salesMarginData.data, fieldToPartitionBy),
			fieldToPartitionBy
		);

		console.log(
			Object
			.values(partitionTotals)
			.sort(
				(a,b) => (a.revenueTotal < b.revenueTotal ? 1 : -1)
			)
		);
		
		setChartData({
			id: "root",
			value: 0,
			children:
				Object
					.values(partitionTotals)
					.sort((a,b) => (a.revenueTotal < b.revenueTotal ? 1 : -1))
					.map((item) => ({
						id: (item[fieldToPartitionBy] ?? "None") + " revenue",
						value: item.revenueTotal - item.marginTotal,
						children: [{
							id: (item[fieldToPartitionBy] ?? "None") + " margin",
							value: item.marginTotal,
						}]
					}))
		})

	}, [salesMarginData])

	return (
		<div className="sales-margin-chart-root">
      <div className="sales-margin-bar-chart-root">
				<ResponsiveSunburst
					data={charData}
					margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
					cornerRadius={2}
					borderColor={{ theme: 'background' }}
					colors={{ scheme: 'nivo' }}
					childColor={{
							from: 'color',
							modifiers: [
									[
											'brighter',
											0.1
									]
							]
					}}
					enableArcLabels={true}
					arcLabelsSkipAngle={10}
					valueFormat={value => {
						return value.toLocaleString("en-NZ", {
							style: "currency",
							currency: "NZD",
							minimumFractionDigits: 2,
						})
					}}
					arcLabelsTextColor={{
							from: 'color',
							modifiers: [
									[
											'darker',
											1.4
									]
							]
					}}
				/>
			</div>
		</div>
	)
}

export default SalesMarginByPartitionChart;
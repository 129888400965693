import dayjs from "dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getComparator } from "../../../../../Utils/TableUtils";
import CustomTableHead from "../../../../Shared/CustomTableHead";
import dayjsPluginUTC from "dayjs-plugin-utc";
import { connect } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DocumentViewer from "../../../../Shared/DocumentViewer";
import { useHistory } from "react-router-dom";
import "./revenueDataTable.scss";

const invoiceTableHeaders = [
  {
    field: "date",
    name: "Date",
    fieldType: "date",
  },
  {
    field: "companyName",
    name: "Client",
    fieldType: "string",
  },
  {
    field: "jobName",
    name: "Name",
    fieldType: "string",
  },
  {
    field: "jobStatus",
    name: "Job Status",
    fieldType: "string",
  },
  {
    field: "isSent",
    name: "Has Been Sent",
    fieldType: "boolean",
  },
  {
    field: "invoiceNumber",
    name: "Invoice Number",
    fieldType: "string",
  },
  {
    field: "total",
    name: "Total",
    fieldType: "numeric",
  },
  {
    field: "margin",
    name: "Margin",
    fieldType: "numeric",
  }
];
const RevenueDataTable = ({ selectedRevenueChart, revenueData }) => {
  const [orderBy, setOrderBy] = useState({
    field: "date",
    name: "Date",
    fieldType: "date",
  });
  const history = useHistory();
  const [order, setOrder] = useState("desc");
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);

  useEffect(() => {
    if (dayjs) {
      dayjs.extend(dayjsPluginUTC);
    }
  }, []);

  const handleOpenPdf = (invoiceId, organisationId) => {
    setInvoiceUrl(`/Invoice/GetInvoiceAsPdf/${invoiceId}/${organisationId}`);
    setShowDocumentViewer(true);
  };

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  
  console.log(revenueData);
  return (
    <div>
      {showDocumentViewer && (
        <DocumentViewer
          modalState={showDocumentViewer}
          handleModal={() => {
            setShowDocumentViewer(false);
          }}
          headerColor="#000"
          docType={"PDF"}
          urlFile={invoiceUrl}
        />
      )}
      <div className="table-responsive" style={{ maxHeight: "78vh" }}>
        <table className="table table-striped table-hover mb-0">
          <CustomTableHead
            headers={invoiceTableHeaders}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleOnRequestSort}
          />
          <tbody>
            {[...revenueData.data]
              .filter((j) =>
                selectedRevenueChart
                  ? dayjs.utc(j.date).local().format("MMM YYYY") ===
                    selectedRevenueChart.indexValue
                  : j
              )
              .sort(getComparator(order, orderBy))
              .map((j) => (
                <tr key={j.id} className={"padding-4px"}>
                  <td className="text-center padding-4px">
                    {j.date
                      ? dayjs.utc(j.date).local().format("DD/MM/YYYY")
                      : " - "}
                  </td>
                  <td className="text-center padding-4px">{j.companyName}</td>
                  <td
                    className="text-center padding-4px"
                    style={{ maxWidth: 200 }}
                  >
                    <span className="hyperlink" onClick={() => {window.open(`/job/${j.jobId}`, "_blank")}}>{`[${j.jobId}] ${j.jobName}`}</span>
                  </td>
                  <td className="text-center padding-4px">{j.jobStatus.toUpperCase()}</td>
                  <td className="text-center padding-4px">
                    {j.isSent ? "Sent" : "Not Sent"}
                  </td>
                  <td className="text-center padding-4px">
                    
                    <div className="no-padding">
                      <div
                        className="revenue-dashboard-table-link"
                        onClick={(e) => {
                          if (j.xeroInvoiceId)
                            handleOpenPdf(j.xeroInvoiceId, j.organisationId);
                        }}
                      >
                        {j.invoiceNumber}
                        {j.xeroInvoiceId && (
                          <PictureAsPdfIcon
                            fontSize="small"
                            style={{ cursor: "pointer", marginLeft: 5 }}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="text-center padding-4px">
                    {j.total.toLocaleString("en-NZ", {
                      style: "currency",
                      currency: "NZD",
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-center padding-4px">
                    {j.marginTotal.toLocaleString("en-NZ", {
                      style: "currency",
                      currency: "NZD",
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(RevenueDataTable);

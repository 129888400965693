import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Collapse,
    Avatar,
    IconButton,
    Typography
} from '@mui/material';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const InfluencerCard = (props) => {
    const {
        influencer
    } = props;
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar alt={`${influencer.basicInstagram.instagramName}`} src={`${influencer.basicInstagram.avatar}`}>

                    </Avatar>
                }
                title={`${influencer.basicInstagram.instagramName}`}
                subheader={`${influencer.basicInstagram.instagramId}`}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {influencer.basicInstagram.country}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {`${influencer.basicInstagram.description}`}
                </Typography>
            </CardContent>
            <CardContent>
                <Typography variant="subtitle1">
                    Statistics
                </Typography>
                <table className="table table-hover text-nowrap mb-0 table-sm">
                    <thead className="thead-magnesium">
                        <tr>
                            <th>Posts</th>
                            <th>Followers</th>
                            <th>Engagment Rate</th>
                            <th>Average Likes</th>
                            <th>Average Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{`${influencer.basicInstagram.posts}`}</td>
                            <td>{`${influencer.basicInstagram.followers}`}</td>
                            <td>{`${influencer.basicInstagram.engageRate}`}</td>
                            <td>{`${influencer.basicInstagram.avgLikes}`}</td>
                            <td>{`${influencer.basicInstagram.avgComments}`}</td>
                        </tr>
                    </tbody>
                </table>
            </CardContent>
            <CardContent>
                <Typography variant="subtitle1">
                    Growth Rate
                </Typography>
                <table className="table table-hover text-nowrap mb-0 table-sm">
                    <thead className="thead-magnesium">
                        <tr>
                            <th>Followers</th>
                            <th>Likes</th>
                            <th>Comments</th>
                            <th>Average Likes</th>
                            <th>Average Comments</th>
                            <th>Engagment Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{`${influencer.basicInstagram.gRateFollowers}`}</td>
                            <td>{`${influencer.basicInstagram.gRateLikes}`}</td>
                            <td>{`${influencer.basicInstagram.gRateComments}`}</td>
                            <td>{`${influencer.basicInstagram.gRateAvgLikes}`}</td>
                            <td>{`${influencer.basicInstagram.gRateAvgComments}`}</td>
                            <td>{`${influencer.basicInstagram.gRateEngageRate}`}</td>
                        </tr>
                    </tbody>
                </table>
            </CardContent>
        </Card>
    );
}

export default InfluencerCard;
import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    CircularProgress,
    IconButton
} from "@mui/material";
import { isEmpty } from "../../../../Utils/GeneralUtils";
import axios from "axios";
import { connect } from "react-redux";
import { getExemptions } from "../../../../reduxActions/job";
import {
    Close as CloseIcon
} from "@mui/icons-material";
import { useAuth } from "../../../../context/auth/AuthContext";

const MarginExemptionRequestDialog = (props) => {
    const {
        jobLine,
        loading = false,
        setSnackText,
        setSnackSeverity,
        setOpenSnack,
        getExemptions
    } = props;

    const { user } = useAuth();
    const [reason, setReason] = useState("");
    const [newLimit, setNewLimit] = useState("");
    const [saving, setSaving] = useState(false);

    const handleCloseDialog = () => {
        props.handleClose()
    }

    const handleSendRequest = () => {
        setSaving(true);
        axios.post(`MarginExemption/SendExemptionRequest/${jobLine.jobId}`, {
            jobId: jobLine.jobId,
            productId: jobLine.productId,
            reason: reason,
            newLimit: newLimit,
            user: user.email
        }).then(res => {
            setSnackText("Request has been sent!");
            setSnackSeverity("info");
            setOpenSnack(true);
            props.handleOnChangeJobLine(
                jobLine.guid,
                "minSellPrice",
                Number(newLimit)
            );
            getExemptions();
            props.handleClose();
        }).catch(e => {
            setSnackText("Oops something went wrong. Could not delete exemption");
            setSnackSeverity("error");
            setOpenSnack(true);
            console.log(e)
        }).finally(() => setSaving(false))
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={(e, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseDialog()
                    }
                }}
                classes={{ paper: "confirmation-with-input-dialog" }}
            >
                <DialogTitle id="alert-dialog-title">Lower markup below the minimum?</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => handleCloseDialog()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are trying to lower this markup below the minimum. You will need to request approval for this.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Reason"
                        type="text"
                        fullWidth
                        variant="filled"
                        multiline
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="name"
                        label={`New Limit %`}
                        fullWidth
                        variant="filled"
                        value={newLimit}
                        type="number"
                        onChange={(e) => {
                            if (parseFloat(e.target.value) <= 100 || e.target.value === "") {
                                setNewLimit(e.target.value)
                            }
                        }}
                    />
                </DialogContent>
                {loading || saving ? (
                    <DialogActions>
                        <div style={{ margin: 10 }}>
                            <CircularProgress />
                        </div>
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button disabled={loading} className="default-button" variant="contained" onClick={() => handleCloseDialog()}>{`Keep current (${jobLine.minSellPrice}%)`}</Button>
                        <Button
                            disabled={isEmpty(reason) || loading || isEmpty(newLimit)}
                            className={isEmpty(reason) || isEmpty(newLimit) ? "" : "default-button"}
                            variant="contained"
                            onClick={() => {
                                if (!isEmpty(reason) || !loading || !isEmpty(newLimit)) {
                                    handleSendRequest()
                                }
                            }}
                            autoFocus>
                            Send Request
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
};

//export default MarginExemptionRequestDialog;

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {
    getExemptions
})(MarginExemptionRequestDialog);
import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import NewJobButton from "./NewJobButton";
import { useHistory } from "react-router-dom";
import CustomButtonGroup from "../Shared/CustomButtonGroup";
import AdditionalJobDialog from "./AdditionalJobDialog";

const SalesTabs = (props) => {

  const handleChange = (event, newValue) => {
    props.setActiveTab(newValue);
  };

  

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <Tabs
          value={props.activeTab}
          onChange={handleChange}
          className="main-tabs"
        >
          <Tab value="JOBS" label="Jobs" />
          <Tab value="INVOICES" label="Invoices" />
        </Tabs>
        </div>
      
    </div>
  );
};

export default SalesTabs;

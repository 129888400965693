import axios from "axios";
import { createAction } from "@reduxjs/toolkit";

export const searchInvoices = (invoiceFilter) => async (dispatch) => {
 const {data} = await axios.post("Invoice/SearchInvoices", invoiceFilter);
 if(invoiceFilter.awaitingPoNumber){
  dispatch(searchInvoicesSuccess(data.filter(i => i.requiredPO && !i.poNumber)));
  }else{
    dispatch(searchInvoicesSuccess(data));
  }
};
const searchInvoicesSuccess = createAction("SEARCH_INVOICES_SUCCESS");

export const updateInvoiceStore = (invoice) => async (dispatch) => {
  dispatch(updateInvoiceStoreSuccess(invoice));
};
const updateInvoiceStoreSuccess = createAction("UPDATE_INVOICE_STORE");

export const removeInvoiceFromStore = (invoice) => async (dispatch) => {
  dispatch(removeInvoiceFromStoreSuccess(invoice));
};
const removeInvoiceFromStoreSuccess = createAction("REMOVE_INVOICE_FROM_STORE");

export const updateInvoiceFilter = (field, value) => async (dispatch) => {
  dispatch(updateInvoiceFilterSuccess({ field, value }));
};
export const updateInvoiceFilterSuccess = createAction("UPDATE_INVOICE_FILTER");

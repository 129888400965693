import { Autocomplete, TextField, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";

const ProductTypeAccountCodeTable = ({
  categories,
  expenseCodes,
  setCategories,
}) => {
  return (
    <div
      className="product-categories-table-root"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "bold",
          padding: 10
        }}
      >
        <div style={{flex: "0.6"}}>Type</div>
        <div className="fullwidth-flex">Revenue Code</div>
        <div className="fullwidth-flex">Retainer Revenue Code</div>
        <div className="fullwidth-flex">Cost & Expenses Code</div>
      </div>
      {categories.map((category, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 10,
            alignItems: "center",
            padding: 10,
            backgroundColor: index % 2 === 0 ? "#eeeeee" : "transparent"
          }}
        >
          <div style={{flex: "0.6"}}>
            <Typography variant="body2">{category.name}</Typography>
          </div>
          <div className="fullwidth-flex">
            <Autocomplete
              options={expenseCodes.filter(
                (e) => e.accountCode && e.organisationFk === -1
              )}
              getOptionLabel={(code) =>
                code.accountCode
                  ? `${code.accountCode} - ${code.accountName}`
                  : ""
              }
              value={
                expenseCodes.find(
                  (ec) =>
                    ec.accountCode == category.revenueAccountCode &&
                    ec.organisationFk === -1
                ) ?? ""
              }
              onChange={(e, newValue) => {
                setCategories(
                  categories.map((c) =>
                    c.id === category.id
                      ? {
                          ...category,
                          revenueAccountCode: newValue?.accountCode,
                        }
                      : c
                  )
                );
              }}
              disableClearable
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
          <div className="fullwidth-flex">
            <Autocomplete
              options={expenseCodes.filter(
                (e) => e.accountCode && e.organisationFk === -1
              )}
              getOptionLabel={(code) =>
                code.accountCode
                  ? `${code.accountCode} - ${code.accountName}`
                  : ""
              }
              value={
                expenseCodes.find(
                  (ec) =>
                    ec.accountCode == category.retainerRevenueAccountCode &&
                    ec.organisationFk === -1
                ) ?? ""
              }
              onChange={(e, newValue) => {
                setCategories(
                  categories.map((c) =>
                    c.id === category.id
                      ? {
                          ...category,
                          retainerRevenueAccountCode: newValue?.accountCode,
                        }
                      : c
                  )
                );
              }}
              disableClearable
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
          <div className="fullwidth-flex">
            <Autocomplete
              options={expenseCodes.filter(
                (e) => e.accountCode && e.organisationFk === -1
              )}
              getOptionLabel={(code) =>
                code.accountCode
                  ? `${code.accountCode} - ${code.accountName}`
                  : ""
              }
              value={
                expenseCodes.find(
                  (ec) =>
                    ec.accountCode == category.costAccountCode &&
                    ec.organisationFk === -1
                ) ?? ""
              }
              onChange={(e, newValue) => {
                setCategories(
                  categories.map((c) =>
                    c.id === category.id
                      ? {
                          ...category,
                          costAccountCode: newValue?.accountCode,
                        }
                      : c
                  )
                );
              }}
              disableClearable
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  expenseCodes: state.data.expenseCodes,
  productCategories: state.product.productCategories,
});

export default connect(mapStateToProps, {})(ProductTypeAccountCodeTable);

import { Box, Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import PercentIcon from "@mui/icons-material/Percent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { currencySymbolFormat } from "../../Utils/GeneralUtils";
import { getCurrencies } from "../../Utils/ExchangeRateUtils";

const SplitInvoiceWizardStep1 = ({
  maxTotal,
  setWizardTotal,
  wizardTotal,
  setIsStep1Completed,
  setChosenType,
  setChosenValue, 
  currency = "NZD"
}) => {
  const [splitBy, setSplitBy] = useState(null);
  const [percentage, setPercentage] = useState(50);
  const [fixedAmount, setFixedAmount] = useState(0);

  useEffect(() => {
    if (maxTotal) {
      setFixedAmount(maxTotal / 2);
    }
  }, []);

  const handleChangeFixedAmount = (e) => {
    let total = e.target.valueAsNumber ? e.target.valueAsNumber : 0;
    if (total > maxTotal) {
      total = maxTotal;
    }
    setPercentage((total / maxTotal) * 100);
    setFixedAmount(total);
  };

  const handleChangePercentage = (e) => {
    let value = e.target.valueAsNumber ? e.target.valueAsNumber : 0;
    if (value > 100) {
      value = 100;
    }
    const percent = value / 100;
    setFixedAmount(maxTotal * percent);
    setPercentage(value);
  };
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h3 className="invoice-step-wizard">
          <span className="question-animation">Would you like </span>
          <span className="question-animation">to split the invoices </span>
          <span className="question-animation">by percentage </span>
          <span className="question-animation">or a fixed amount?</span>
        </h3>
        <div className="flex justify-content-center question-animation">
          <div style={{ paddingRight: 15 }}>
            <Button
              variant="contained"
              className="default-button"
              onClick={(e) => {
                setSplitBy("PERCENTAGE");
              }}
            >
              Percentage
            </Button>
          </div>

          <div style={{ paddingRight: 15 }}>
            <Button
              variant="contained"
              className="default-button"
              onClick={(e) => {
                setSplitBy("FIXED_AMOUNT");
              }}
            >
              Fixed Amount
            </Button>
          </div>
        </div>
        {splitBy && (
          <div
            className="invoice-splitby-step1-animation"
            style={{ textAlign: "center", marginTop: 15, padding: 5 }}
          >
            <h3>{`Total Remaining: ${currencySymbolFormat(maxTotal, currency)}`}</h3>
            {splitBy === "PERCENTAGE" && (
              <>
                <Box
                  style={{ padding: 10, display: "flex", justifyContent: "center" }}
                >
                  <TextField
                    label="Percentage"
                    type="number"
                    value={percentage}
                    size="small"
                    min={0}
                    max={100}
                    onChange={handleChangePercentage}
                  />
                  <PercentIcon />
                </Box>
                <h3>{`Invoice Total: ${currencySymbolFormat(fixedAmount, currency)}`}</h3>
              </>
            )}
            {splitBy === "FIXED_AMOUNT" && (
              <Box
                style={{ padding: 10, display: "flex", justifyContent: "center"}}
              >
                <h3 style={{ marginRight: 5 }}>{getCurrencies().find(c => c.value === currency)?.symbol}</h3>
                <TextField
                  label="Amount"
                  type="number"
                  value={fixedAmount}
                  size="small"
                  onChange={handleChangeFixedAmount}
                />
              </Box>
            )}

            <div>
              <Button
                variant="contained"
                className="default-button"
                onClick={(e) => {
                  setWizardTotal(fixedAmount);
                  setChosenType(splitBy);
                  if(splitBy === "PERCENTAGE"){
                    setChosenValue(percentage)
                  } else if(splitBy === "FIXED_AMOUNT"){
                    setChosenValue(fixedAmount)
                  }
                  setIsStep1Completed(true);
                }}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SplitInvoiceWizardStep1;

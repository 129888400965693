import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Autocomplete,
    Avatar,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
} from "@mui/material";
import { updateCompanyFilter } from "../../../reduxActions/company";


const CompanyFilters = (props) => {
    const {
        companies,
        companyFilter,
        updateCompanyFilter,
        selectedOrganisation
    } = props;

    const [keyword, setKeyword] = useState(companyFilter.keyword !== undefined ? companyFilter.keyword : "");

    return (
        <div style={{ width: "100%" }}>
            {/* <TextField
                id="keyword"
                label="Company Search (press Enter to search)"
                variant="filled"
                fullWidth={true}
                value={keyword}
                size="small"
                onChange={(e) => {
                    setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === 13 || e.key === "Enter") {
                        //updateCompanyFilter("keyword", keyword?.trim());
                        updateCompanyFilter({
                            ...companyFilter,
                            keyword: keyword?.trim(),
                            organisationId: selectedOrganisation.id
                        });

                    }
                }}
            /> */}
            <Autocomplete
                fullWidth
                options={companies}
                getOptionLabel={(option) => option.name}
                value={companies.find((c) => c.id === companyFilter.companyId)}
                onChange={(e, newValue) => {
                    let id = 0;
                    if (newValue) {
                        id = newValue.id;
                    }
                    updateCompanyFilter("companyId", id);
                }}
                selectOnFocus
                clearOnBlur
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Company Name"
                        size="small"
                        fullWidth={true}
                        variant="filled"
                    />
                )}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedOrganisation: state.organisation.selectedOrganisation,
    companies: state.company.companies,
    companyFilter: state.company.companyFilter
});

export default connect(mapStateToProps, {
    updateCompanyFilter
})(CompanyFilters);
import React, { useEffect, useState } from "react";
import CustomTableHead from "../../Shared/CustomTableHead";
import LoadingTableSkeleton from "../../Shared/LoadingTableSkeleton";
import { useUserContext } from "../../../context/user/UserContext";
import JobStatusDisplay from "../../Shared/JobStatusDisplay";
import {
  calculateJobTotal,
  calculateTotalMargin,
} from "../../../Utils/JobHelpers";
import JobContactDisplay from "../../Shared/JobContactDisplay";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import dayjsPluginUTC from "dayjs-plugin-utc";
import SalesMenu from "../../Sales/SalesMenu";
import axios from "axios";
import { getComparator } from "../../../Utils/TableUtils";
import { Button, Container, Divider, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const jobTableHeaders = [
  {
    field: "user",
    name: "User",
    fieldType: "string",
  },
  {
    field: "retainerEndDate",
    name: "End Date",
    fieldType: "date",
  },
  {
    field: "companyName",
    name: "Company",
    fieldType: "string",
  },
  {
    field: "jobName",
    name: "Job Name",
    fieldType: "string",
  },
  {
    field: "poNumber",
    name: "PO",
    fieldType: "string",
  },
  {
    field: "additional",
    name: "",
    fieldType: "boolean",
  },
  {
    field: "contactFirstName",
    name: "Contact",
    fieldType: "string",
  },
  {
    field: "total",
    name: "Total",
    fieldType: "numeric",
  },
  {
    field: "marginTotal",
    name: "Margin",
    fieldType: "numeric",
  },
  {
    field: "status",
    name: "Status",
    fieldType: "string",
  },
  {
    field: "",
    name: "",
    fieldType: "string",
  },
];

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const RetainerJobsEndingSoon = ({ selectedOrganisation }) => {
  const history = useHistory();
  const { allUsers, email: user } = useUserContext();
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState({
    field: "retainerEndDate",
    name: "End Date",
    fieldType: "date",
  });

  useEffect(() => {
    if (dayjs) {
      dayjs.extend(dayjsPluginUTC);
    }
  }, []);

  useEffect(() => {
    searchJobs();
  }, [selectedOrganisation]);

  const searchJobs = async () => {
    if (selectedOrganisation?.id > 1) {
      setIsLoading(true);
      axios
        .get(`/job/GetRetainersEndingSoon`)
        .then(({ data }) => {
          setJobs(data);
        })
        .catch((err) => alert("Unable to search retainers"))
        .finally(() => setIsLoading(false));
    }
  };

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getUser = (job) => {
    var user = allUsers?.find((u) => u.email === job.user);
    if (user) {
      var initial = user.lastName.split("")[0];
      return `${user.firstName} ${initial}.`;
    } else {
      return "";
    }
  };

  return (
    <Container maxWidth="xl">
      <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: "15px" }}>
        <div
          style={{ display: "grid", gridTemplateColumns: "1fr", gap: "5px" }}
        >
          <Typography variant="h4" align="left">
            Retainer Ending Soon
          </Typography>
          <Divider />
        </div>
        <div style={{maxWidth: 150}}>
          <Button
          className="default-button-outlined"
            fullWidth
            startIcon={<ArrowBackIcon />}
            onClick={() => history.push({ pathname: "/home" })}
          >
            Go Back
          </Button>
        </div>

        <div className="table-responsive" style={{ maxHeight: "65vh" }}>
          <table className="table table-striped table-hover mb-0">
            <CustomTableHead
              headers={jobTableHeaders}
              orderBy={orderBy}
              order={order}
              onRequestSort={handleOnRequestSort}
            />
            <tbody>
              {isLoading || !allUsers ? (
                <LoadingTableSkeleton
                  loadingMessage="Loading retainers..."
                  colspan="13"
                />
              ) : (
                [...jobs].sort(getComparator(order, orderBy)).map((j) => (
                  <tr
                    key={j.id}
                    onClick={(e) => {
                      history.push(`/job/${j.id}`);
                    }}
                    className={
                      j.status === "approved"
                        ? "approved-job-row padding-4px"
                        : "padding-4px"
                    }
                  >
                    <td className="text-center padding-4px">
                      {/* {allUsers?.find((u) => u.email === j.user)?.firstName} */}
                      {getUser(j)}
                    </td>
                    <td className="text-center padding-4px">
                      {j.retainerEndDate
                        ? dayjs(j.retainerEndDate).format("DD/MM/YYYY")
                        : " - "}
                    </td>
                    <td className="text-center padding-4px">{j.companyName}</td>
                    <td className="text-center padding-4px">
                      {`[${j.id}] `}
                      {j.projectCode && `${j.projectCode} - `}
                      {j.jobName}
                    </td>
                    <td className="text-center padding-4px">{j.poNumber}</td>
                    <td className="text-center padding-4px">
                      {j.additional && (
                        <div className="additional-badge-wrapper-home">
                          <div className="badge additional-black-badge h-auto">
                            Additional
                          </div>
                        </div>
                      )}
                      {j.isRetainer && (
                        <div className="additional-badge-wrapper-home">
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <div>
                                  <b>{"First Invoice Month: "}</b>{" "}
                                  {j && j.completionDate
                                    ? dayjs
                                        .utc(j.completionDate)
                                        .local()
                                        .format("DD/MM/YYYY")
                                    : ""}
                                </div>
                                <div>
                                  <b>{"Number of Invoices: "}</b>{" "}
                                  {j && j.retainerDuration
                                    ? j.retainerDuration
                                    : ""}
                                </div>
                                {!!j.retainerManualEndDate && (
                                  <div>
                                    <b>{"Termination Date: "}</b>{" "}
                                    {dayjs(j.retainerManualEndDate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </div>
                                )}
                              </React.Fragment>
                            }
                          >
                            <div className="badge additional-green-badge h-auto">
                              Retainer{" "}
                              {!!j.retainerManualEndDate && "(terminated)"}
                            </div>
                          </HtmlTooltip>
                        </div>
                      )}
                    </td>
                    <td className="text-center padding-4px">
                      <JobContactDisplay job={j} />
                    </td>
                    <td className="text-center padding-4px">
                      {calculateJobTotal(j.jobLines).toLocaleString("en-NZ", {
                        style: "currency",
                        currency: "NZD",
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td className="text-center padding-4px">
                      {calculateTotalMargin(j.jobLines).toLocaleString(
                        "en-NZ",
                        {
                          style: "currency",
                          currency: "NZD",
                          minimumFractionDigits: 2,
                        }
                      )}
                    </td>
                    <td className="text-center padding-4px">
                      <JobStatusDisplay job={j} />
                    </td>
                    <td
                      className="text-center padding-4px"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    ></td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {})(RetainerJobsEndingSoon);
